import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';
export const BranchBalance = () => {
  const typeBalance = [
    { label: 'Saldo em Estoque', value: 0 },
    { label: 'Saldo Vendido', value: 1 },
    { label: 'Saldo Devolvido', value: 2 },
  ];
  const [loading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde relatório sendo gerado...');
    try {
      const { data } = await providersService.branchBalanceReport({ values });
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadXlsx(`Relatório_Saldos_Filiais${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Saldos Filiais</PageTitle>

      <Formik initialValues={{ type_balance: 0 }} onSubmit={handleExport}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  variant='outlined'
                  component={Select}
                  name='type_balance'
                  label='Tipo de Saldo'
                  options={typeBalance}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button type='submit' fullWidth loading={loading}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
