import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const LotpendingConfirmationReport = () => {
  const [loading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async () => {
    setIsLoading(true);
    toast.info('Aguarde relatório sendo gerado...');
    try {
      const { data } = await providersService.lotpendingConfirmationReport();
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadXlsx(`Relatório_Lotes_pendentes_de_Confirmacao${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Lotes Pendentes Confirmação</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Button onClick={handleExport} fullWidth loading={loading}>
            Extrair Relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
