import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import HomeIcon from '@material-ui/icons/Home';

import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BlockIcon from '@mui/icons-material/Block';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import StoreIcon from '@mui/icons-material/Store';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const menu = [
  {
    id: 'home',
    path: '/home',
    label: 'Ínicio',
    icon: <HomeIcon />,
    roles: 1,
    items: [
      {
        path: '/home',
        label: 'Página inicial',
        icon: <HomeIcon />,
        roles: 1,
      },
    ],
  },
  {
    id: 'favoritos',
    path: '/favoritos',
    label: 'Favoritos',
    icon: <StarIcon />,
    roles: 1,
    items: [],
  },
  {
    id: 'register',
    label: 'Cadastros',
    //icon: <AttachMoneyIcon />,
    roles: 22,
    items: [
      {
        path: '/register-client',
        label: 'Cadastro de Cliente',
        icon: <ShoppingCartIcon />,
        roles: 800,
      },
      {
        path: '/register-operations',
        label: 'Cadastro de Operacoes',
        icon: <ShoppingCartIcon />,
        roles: 801,
      },
      {
        path: '/register-carrier',
        label: 'Cadastro de Transportadora',
        icon: <SupervisorAccountIcon />,
        roles: 802,
      },
      {
        path: '/register-product',
        label: 'Cadastro de Produtos',
        icon: <ShoppingCartIcon />,
        roles: 803,
      },
      {
        path: '/register-type-product',
        label: 'Cadastro Tipo de Produtos',
        icon: <ShoppingCartIcon />,
        roles: 804,
      },
      {
        path: '/register-mark',
        label: 'Cadastro de Marcas',
        icon: <ShoppingCartIcon />,
        roles: 805,
      },
      {
        path: '/register-model',
        label: 'Cadastro de Modelos',
        icon: <ShoppingCartIcon />,
        roles: 806,
      },
      {
        path: '/register-parts',
        label: 'Cadastro de Peças',
        icon: <SupervisorAccountIcon />,
        roles: 807,
      },
      {
        path: '/register-parts-category',
        label: 'Cadastro de Categoria de Peças',
        icon: <ShoppingCartIcon />,
        roles: 808,
      },
      {
        path: '/register-sub-parts-category',
        label: 'Cadastro Sub Categoria de Peças',
        icon: <ShoppingCartIcon />,
        roles: 809,
      },
      {
        path: '/register-repair-code',
        label: 'Cadastro Código Reparo',
        icon: <ShoppingCartIcon />,
        roles: 810,
      },
      {
        path: '/register-Fornecedores',
        label: 'Cadastro de Fornecedores',
        icon: <ShoppingCartIcon />,
        roles: 811,
      },
    ],
  },
  {
    id: 'cashier',
    label: 'Caixa',
    icon: <AttachMoneyIcon />,
    roles: 3,
    items: [
      {
        path: '/market-price',
        label: 'Consultar preço de mercado',
        icon: <ShoppingCartIcon />,
        roles: 103,
      },
    ],
  },
  {
    id: 'shopping',
    label: 'Compras',
    icon: <ShoppingCartIcon />,
    roles: 19,
    items: [
      {
        path: '/view-base',
        label: 'Visualizar Base',
        icon: <ContentPasteGoIcon />,
        roles: 500,
      },
      {
        path: '/base-approve',
        label: 'Aprovação base',
        icon: <ContentPasteGoIcon />,
        roles: 501,
      },
    ],
  },
  {
    id: 'access-control',
    label: 'Controle de Acesso',
    icon: <SupervisorAccountIcon />,
    roles: 14,
    items: [
      {
        path: '/create-role',
        label: 'Novo Grupo de Usuário',
        icon: <PersonAddIcon />,
        roles: 100,
      },
      {
        path: '/list-profiles',
        label: 'Perfis',
        icon: <PersonAddIcon />,
        roles: 102,
      },
      {
        path: '/permissions',
        label: 'Permissionamento',
        icon: <PersonAddIcon />,
        roles: 101,
      },
    ],
  },
  {
    id: 'stock',
    label: 'Estoque',
    icon: <WarehouseIcon />,
    roles: 4,
    items: [
      {
        path: '/alternate',
        label: 'Alternate',
        icon: <PersonAddIcon />,
        roles: 200,
      },
      {
        path: '/nf-entry-parts',
        label: 'Nota de Entrada de Peças',
        icon: <PersonAddIcon />,
        roles: 201,
      },
    ],
  },
  {
    id: 'stock-sales',
    label: 'Estoque - Vendas',
    icon: <StoreIcon />,
    roles: 18,
    items: [
      {
        path: '/block-service-ordem',
        label: 'Bloqueio de Os',
        icon: <BlockIcon />,
        roles: 300,
      },
    ],
  },
  {
    id: 'in-home',
    label: 'In Home',
    icon: <HomeRepairServiceIcon />,
    roles: 21,
    items: [
      {
        path: '/services',
        label: 'Atendimentos',
        icon: <ContactPhoneIcon />,
        roles: 700,
      },
    ],
  },
  {
    id: 'invoices',
    label: 'Notas Fiscais',
    icon: <ReceiptLongIcon size='18' />,
    roles: 17,
    items: [
      {
        path: '/mirror-invoice',
        label: 'Espelhar nota de entrada',
        icon: <ScreenShareIcon />,
        roles: 400,
      },
    ],
  },
  {
    id: 'Receipt',
    label: 'Recebimento',
    icon: <LocalShippingIcon />,
    roles: 12,
    items: [
      {
        path: '/register-nf-doca',
        label: 'Cadastrar NF Doca',
        icon: <ReceiptIcon />,
        roles: 250,
      },
      {
        path: '/generate-minute',
        label: 'Gerar Minuta',
        icon: <ReceiptIcon />,
        roles: 251,
      },
      {
        path: '/consulte',
        label: 'Consulta',
        icon: <ReceiptIcon />,
        roles: 252,
      },
      {
        path: '/collect-serial-pdv',
        label: 'Coletar serial PDV',
        icon: <AssignmentTurnedInIcon />,
        roles: 253,
      },
    ],
  },
  {
    id: 'reports',
    label: 'Relatórios',
    icon: <AssessmentIcon />,
    roles: 15,
    items: [
      {
        path: '/receipt',
        label: 'Relatório de recebimento',
        icon: <SummarizeIcon />,
        roles: 104,
      },
      {
        path: '/aging',
        label: 'Relatório de aging',
        icon: <SummarizeIcon />,
        roles: 105,
      },
      {
        path: '/finalized-scrap',
        label: 'Relatório de aging finalizado e scrap',
        icon: <SummarizeIcon />,
        roles: 106,
      },
      {
        path: '/stock-position',
        label: 'Relatório de posição do estoque',
        icon: <SummarizeIcon />,
        roles: 107,
      },
      {
        path: '/production-sector',
        label: 'Relatório de Produção por Setor',
        icon: <SummarizeIcon />,
        roles: 108,
      },
      {
        path: '/open-service-order',
        label: 'Relatório Abertura de OS',
        icon: <SummarizeIcon />,
        roles: 109,
      },
      {
        path: '/serialized-stock',
        label: 'Relatório de Estoque Serializado',
        icon: <SummarizeIcon />,
        roles: 110,
      },
      {
        path: '/request-report',
        label: 'Relatório de Requisições',
        icon: <SummarizeIcon />,
        roles: 111,
      },
      {
        path: '/register-product-report',
        label: 'Relatório de Cadastro de Produtos',
        icon: <SummarizeIcon />,
        roles: 112,
      },
      {
        path: '/models-product-report',
        label: 'Relatório Modelos X Produtos',
        icon: <SummarizeIcon />,
        roles: 113,
      },
      {
        path: '/defect-component-report',
        label: 'Relatório de Vínculo Defeito X Componente',
        icon: <SummarizeIcon />,
        roles: 114,
      },
      {
        path: '/produtos-atributos-report',
        label: 'Relatório de Produtos X Produtos',
        icon: <SummarizeIcon />,
        roles: 115,
      },
      {
        path: '/production-registers-report',
        label: 'Relatório de Produção de Cadastros',
        icon: <SummarizeIcon />,
        roles: 116,
      },
      {
        path: '/component-register-report',
        label: 'Relatório de Cadastro de Componentes',
        icon: <SummarizeIcon />,
        roles: 117,
      },
      {
        path: '/products-no-sku-report',
        label: 'Relatório Produtos sem SKU',
        icon: <SummarizeIcon />,
        roles: 118,
      },
      {
        path: '/pn-modelo',
        label: 'Relatório PN X Modelo',
        icon: <SummarizeIcon />,
        roles: 119,
      },
      {
        path: '/warranty-validation',
        label: 'Relatório de Validação de Garantia',
        icon: <SummarizeIcon />,
        roles: 120,
      },
      {
        path: '/detailed-Parts-Consumption',
        label: 'Relatório de Consumo de Peças ',
        icon: <SummarizeIcon />,
        roles: 121,
      },
      {
        path: '/nf-entry-report',
        label: 'Relatório Entrada de NFs Peças',
        icon: <SummarizeIcon />,
        roles: 122,
      },
      {
        path: '/stock-position-ecommerce',
        label: 'Relatório Posição Estoque Ecommerce',
        icon: <SummarizeIcon />,
        roles: 123,
      },
      {
        path: '/lote-nf-receipt',
        label: 'Relatório Lote X NF Recebimento',
        icon: <SummarizeIcon />,
        roles: 124,
      },
      {
        path: '/os-cmp-report',
        label: 'Relatório OSs em CMP/HF',
        icon: <SummarizeIcon />,
        roles: 125,
      },
      {
        path: '/serialized-product',
        label: 'Relatório Estoque Serializado - Estoque Venda',
        icon: <SummarizeIcon />,
        roles: 126,
      },
      {
        path: '/total-average-calculation',
        label: 'Relatório Calculo Médio Total',
        icon: <SummarizeIcon />,
        roles: 127,
      },
      {
        path: '/sac-management',
        label: 'SAC - Gestão',
        icon: <SummarizeIcon />,
        roles: 128,
      },
      {
        path: '/warranty-report',
        label: 'Relatório de Garantia',
        icon: <SummarizeIcon />,
        roles: 129,
      },
      {
        path: '/open-lot',
        label: 'Abertura de Lote',
        icon: <SummarizeIcon />,
        roles: 130,
      },
      {
        path: '/lot-management-report',
        label: 'Gerenciamento de Lote (Nfs)',
        icon: <SummarizeIcon />,
        roles: 131,
      },
      {
        path: '/future-bounce',
        label: 'Bounce Futuro',
        icon: <SummarizeIcon />,
        roles: 132,
      },
      {
        path: '/retroactive-bounce',
        label: 'Bounce Retroativo',
        icon: <SummarizeIcon />,
        roles: 133,
      },
      {
        path: '/parts-models',
        label: 'Peças X Modelos (BOR)',
        icon: <SummarizeIcon />,
        roles: 134,
      },
      {
        path: '/completed-orders',
        label: 'Pedidos Finalizados',
        icon: <SummarizeIcon />,
        roles: 135,
      },
      {
        path: '/branch-balance',
        label: 'Saldo Filiais',
        icon: <SummarizeIcon />,
        roles: 136,
      },
      {
        path: '/non-compliance',
        label: 'Não Conformidade',
        icon: <SummarizeIcon />,
        roles: 137,
      },
      {
        path: '/production-by-technician',
        label: 'Produção por técnico',
        icon: <SummarizeIcon />,
        roles: 138,
      },
      {
        path: '/express-report',
        label: 'Relatório Expresso',
        icon: <SummarizeIcon />,
        roles: 139,
      },
      {
        path: '/scrap-report',
        label: 'Scrap',
        icon: <SummarizeIcon />,
        roles: 140,
      },
      {
        path: '/control-sac-Management',
        label: 'Controle SAC-Gestão',
        icon: <SummarizeIcon />,
        roles: 141,
      },
      {
        path: '/lot-pending-confirmation',
        label: 'Lotes Pendentes Confirmação',
        icon: <SummarizeIcon />,
        roles: 142,
      },
      {
        path: '/closed-os',
        label: 'OS`s Encerradas',
        icon: <SummarizeIcon />,
        roles: 143,
      },
      {
        path: '/register-complaints',
        label: 'Cadastro de Reclamações',
        icon: <SummarizeIcon />,
        roles: 144,
      },
      {
        path: '/billing-by-os',
        label: 'Faturamento por OS',
        icon: <SummarizeIcon />,
        roles: 145,
      },
      {
        path: '/photo-lote',
        label: 'Foto Lote',
        icon: <SummarizeIcon />,
        roles: 146,
      },
      {
        path: '/management-filter',
        label: 'Gerenciamento de Filtros - AGING',
        icon: <SummarizeIcon />,
        roles: 147,
      },
      {
        path: '/emails-control',
        label: 'Controle de E-mails SAC - Gestão',
        icon: <SummarizeIcon />,
        roles: 148,
      },
      {
        path: '/sale-by-period',
        label: 'Venda por Período',
        icon: <SummarizeIcon />,
        roles: 149,
      },
    ],
  },
  {
    id: 'conference',
    label: 'Conferência',
    icon: <AssessmentIcon />,
    roles: 20,
    items: [
      {
        path: '/lote-management',
        label: 'Gerenciamento de Lote',
        icon: <SummarizeIcon />,
        roles: 600,
      },
    ],
  },
];
