import { api } from 'services/api';

export class TinyProviderService {
  async createMirrorInvoice(data) {
    return await api.post(`/provider-tiny/mirror-invoice`, {
      headers: {
        'Content-Type': 'application/xml',
      },
      data,
    });
  }
}

export const tinyProviderService = new TinyProviderService();
