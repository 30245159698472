import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogRegisterCodRepair = ({ openDialogRegister, handleCloseDialogRegister }) => {
  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogRegister}>
        <Dialog.Title onClose={handleCloseDialogRegister}>Cadastro de Código Reparo</Dialog.Title>
        <Dialog.Content>
          <Formik>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='sigla'
                      variant='outlined'
                      fullWidth
                      label='Sigla'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={8}>
                    <Field
                      size='small'
                      name='description'
                      variant='outlined'
                      fullWidth
                      label='Descrição'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={6}>
                    <Field
                      size='small'
                      name='type_product'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Tipo de Produto'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={6}>
                    <Field
                      size='small'
                      name='brand'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Marca'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Button fullWidth type='submit'>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
