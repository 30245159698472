import React, { useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { ConsultBlockServiceOrdem } from './ConsultBlockServiceOrdem';
import { RegistreBlockServiceOrdem } from './RegistreBlockServiceOrdem';

export const BlockServiceOrdem = () => {
  const [radioValue, setRadioValue] = useState('registre');

  return (
    <>
      <PageTitle>Bloqueio de OS</PageTitle>

      <Formik initialValues={{}}>
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      onChange={(event) => setRadioValue(event.target.value)}
                      defaultValue='registre'
                      row
                      aria-label='operation'
                      name='operation'
                    >
                      <FormControlLabel
                        value='registre'
                        control={<Radio color='primary' />}
                        label='CADASTRO'
                      />
                      <FormControlLabel
                        value='consult'
                        control={<Radio color='primary' />}
                        label='CONSULTA'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
            {radioValue === 'registre' ? (
              <RegistreBlockServiceOrdem />
            ) : (
              <ConsultBlockServiceOrdem />
            )}
          </>
        )}
      </Formik>
    </>
  );
};
