import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

const columns = [{ label: 'SubCategoria' }, { label: 'Alterar' }, { label: 'Ativar/Desativar' }];

export const RegisterSubPartsCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <PageTitle>Cadastro SubCategoria de Peças</PageTitle>

      <Formik initialValues={{ input_name: '' }}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_name'
                variant='outlined'
                fullWidth
                label='SubCategoria'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button type='submit' fullWidth loading={isLoading}>
                Gravar
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              <TableRow>
                <TableCell>SubCategoria</TableCell>
                <TableCell>
                  <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Button color='secondary' fullWidth>
                        Desativar
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>SubCategoria</TableCell>
                <TableCell>
                  <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Button color='primary' fullWidth>
                        Ativar
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </Table>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
