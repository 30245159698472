import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const PartsXModelsReport = () => {
  const [loading, setIsLoading] = useState(false);
  const [partnumbers, setPartnumbers] = useState([{ label: '', value: '' }]);
  const now = new Date();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await providersService.findPartnumbers();

        const firstValue = [{ label: 'TODOS', value: '0' }, ...data];
        setPartnumbers(firstValue);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const handleExport = async (values) => {
    toast.info('Aguarde relatório sendo gerado...');
    setIsLoading(true);
    try {
      const { data } = await providersService.partsXModelsReport({ values });
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadXlsx(`Relatório_BOR${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Peças X Modelos (BOR)</PageTitle>
      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
          partnumber: '0',
        }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  fullWidth
                  name='partnumber'
                  variant='outlined'
                  label='Partnumbers'
                  options={partnumbers}
                  component={Select}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button type='submit' fullWidth loading={loading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
