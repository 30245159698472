import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

export const DialogConfirmDelete = ({
  openModalConfirmDelete,
  handleCloseModalConfirmDelete,
  nfCabec,
}) => {
  if (!openModalConfirmDelete) return null;
  return (
    <>
      <Dialog open={openModalConfirmDelete}>
        <Dialog.Title onClose={handleCloseModalConfirmDelete}>Confirmar exclusão</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }} align='center'>
            Realmente deseja excuir a NF: {nfCabec.NotaFiscal ?? ''} ?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}></Grid>
            <Grid item xs={12} lg={3}>
              <Button onClick={() => handleCloseModalConfirmDelete()} color='secondary'>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button onClick={[]}>Confirmar</Button>
            </Grid>
            <Grid item xs={12} lg={3}></Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
