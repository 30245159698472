import TextField from '@material-ui/core/TextField';

import { Autocomplete, createFilterOptions } from '@mui/material';
import { Field } from 'formik';
import { fieldToTextField } from 'formik-mui';

import { useStyles } from './styles';

const FormikAutocomplete = ({ textFieldProps, value, disabled, ...props }) => {
  const classes = useStyles();

  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name, label } = field;

  const OPTIONS_LIMIT = 5;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  return (
    <Autocomplete
      {...props}
      {...field}
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando'
      disablePortal
      filterOptions={filterOptions}
      value={value}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(_, newValue) => setFieldValue(name, newValue)}
      onBlur={() => setTouched({ [name]: true })}
      disabled={disabled}
      // getOptionSelected={(item, current) => item?.value === current?.value}
      renderInput={(props) => (
        <TextField
          {...props}
          {...textFieldProps}
          helperText={helperText}
          error={error}
          label={label}
          className={classes.customInput}
        />
      )}
    />
  );
};

const CustomAutocomplete = ({ name, label, filteredValues, value, disabled }) => (
  <>
    <Field
      name={name}
      component={FormikAutocomplete}
      label={label}
      options={filteredValues}
      value={value || ''}
      disabled={disabled}
      textFieldProps={{
        fullWidth: true,
        variant: 'outlined',
      }}
    />
  </>
);

export { CustomAutocomplete };
