import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Button, DatePicker, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { receiptService } from '../../../../services/receipt';

const columns = [
  { label: 'Num Item' },
  { label: 'Operacao' },
  { label: 'CNPJ' },
  { label: 'Fornecedor' },
  { label: 'Chave' },
  { label: 'Num NF' },
  { label: 'User Movimentação' },
  { label: 'Status' },
  { label: '' },
];

export const consulteDoca = () => {
  const [dataTable, setTable] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleGetTable = async (values) => {
    try {
      setIsLoading(true);
      const { data } = await receiptService.getTableStatus({
        input: values.input_data,
        output: values.output_data,
      });
      setTable(data);
      setIsLoading(false);
    } catch {
      toast.error('Erro ao consultar tabela');
      setIsLoading(false);
    }
  };

  const filteredData = dataTable.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()),
    ),
  );

  const handleResetTable = async (values, setFieldValue) => {
    setTable([]);
    setFilterText('');
  };

  const handleDownloadPdf = async (idCadastro) => {
    try {
      setIsLoading(true);
      const { data } = await receiptService.downloadPdfLoteMinuta({ idCadastro: idCadastro });
      const file = new Blob([data], { type: 'application/pdf' });
      saveAs(file, 'minuta.pdf');
      toast.success('PDF baixado com sucesso');
      setIsLoading(false);
    } catch {
      toast.error('Erro ao baixar PDF');
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Consulta Doca</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
        }}
        onReset={handleResetTable}
        onSubmit={handleGetTable}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button isLoading={isLoading} type='submit' fullWidth>
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button type='reset' color='secondary' fullWidth>
                  Limpar
                </Button>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  size='small'
                  fullWidth
                  name='filtro'
                  variant='outlined'
                  label='Pesquisar'
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  disabled={dataTable.length === 0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid item xs={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {filteredData &&
                    filteredData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.NumItem}</TableCell>
                        <TableCell>{item.Operacao}</TableCell>
                        <TableCell>{item.CNPJ}</TableCell>
                        <TableCell>{item.Fornecedor}</TableCell>
                        <TableCell>{item.Chave}</TableCell>
                        <TableCell>{item.NumNF}</TableCell>
                        <TableCell>{item.QuemMovimentou}</TableCell>
                        <TableCell>{item.Status}</TableCell>
                        <TableCell>
                          {item.Status === 'Gerada minuta com NF' && (
                            <Button
                              isLoading={isLoading}
                              onClick={() => handleDownloadPdf(item.idCadastro)}
                            >
                              <GetAppIcon />
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
