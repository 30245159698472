import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const AgingReport = () => {
  const typeService = [
    { label: 'Resumido', value: 0 },
    { label: 'Detalhado', value: 1 },
  ];

  const operation = [
    { label: 'TODOS', value: 0 },
    { label: 'RFB SAMSUNG', value: 1 },
  ];

  const typeDate = [
    { label: 'TODAS', value: 0 },
    { label: 'PERSONALIZADA', value: 1 },
  ];

  const dateCustom = [
    { label: 'Data Log', value: 0 },
    { label: 'Data Lote', value: 1 },
    { label: 'Data Abertura', value: 2 },
  ];

  const now = formatDate(new Date());

  const [loading, setLoading] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: customFilters } = await providersService.findCustomFilters();
      const { data: stages } = await providersService.findStagesAging();

      const allStages = [{ label: 'TODOS', value: 0 }, ...stages];
      setCustomFilters(customFilters);
      setStages(allStages);
    };
    fetchData();
  }, []);

  const handleSearchStagesByFilter = async (value, setFieldValue) => {
    try {
      const { data } = await providersService.findSelectedStagesAging({
        idFilter: value,
      });
      setSelectedStages(data);
      if (data[0].etapas === 'todos') {
        setFieldValue('stage', [0]);
      } else {
        setFieldValue('stage', data[0]?.etapas?.split(',').map(Number) || []);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao buscar etapas selecionadas');
    }
  };

  const handleDownloadReport = async (values) => {
    setLoading(true);
    toast.warning('Gerando o relatório de aging...');
    try {
      const { data } = await providersService.getAgingReport({ values });

      if (data.length === 0) {
        toast.error('Nenhum dado encontrado para esses filtros');
        return;
      }
      await downloadcsv(`Relatório_Aging_Personalizado${now}`, data);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro na geração do relatório de aging');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Aging</PageTitle>
      <Formik
        initialValues={{
          type_report: 0,
          operation: 0,
          type_date: 0,
          custom_date: 0,
          input_data: new Date(),
          output_data: new Date(),
          lote: '',
          custom_filter: '',
          stage: selectedStages[0]?.etapas?.split(',')?.map(Number) || [],
        }}
        onSubmit={handleDownloadReport}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo de Relatório'
                  variant='outlined'
                  options={typeService}
                  component={Select}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='operation'
                  label='Operação'
                  variant='outlined'
                  options={operation}
                  component={Select}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_date'
                  label='Data'
                  variant='outlined'
                  options={typeDate}
                  component={Select}
                  required
                />
              </Grid>
              {values.type_date === 1 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='custom_date'
                      label='Tipo de Data'
                      variant='outlined'
                      options={dateCustom}
                      component={Select}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='custom_filter'
                  label='Filtro Personalizado'
                  variant='outlined'
                  options={customFilters}
                  component={Select}
                  onChange={(e) => handleSearchStagesByFilter(e, setFieldValue)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='lote'
                  label='Filtrar por Lote'
                  variant='outlined'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='stage'
                  label='Etapas'
                  variant='outlined'
                  options={stages}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button type='submit' fullWidth loading={loading}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
