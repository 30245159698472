import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogRegisterParts = ({ openDialogRegister, handleCloseDialogRegister }) => {
  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogRegister}>
        <Dialog.Title onClose={handleCloseDialogRegister}>Cadastro de Peças</Dialog.Title>
        <Dialog.Content>
          <Formik>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='partnumber'
                      variant='outlined'
                      fullWidth
                      label='Partnumber'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='partnumber_description'
                      variant='outlined'
                      fullWidth
                      label='Descrição Partnumber'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={4}>
                    <Field
                      size='small'
                      name='ncm'
                      variant='outlined'
                      fullWidth
                      label='NCM'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={6}>
                    <Field
                      size='small'
                      name='valor_ref'
                      variant='outlined'
                      fullWidth
                      label='Valor Ref'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={6}>
                    <Field
                      size='small'
                      name='origin_local'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Local Origem'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Field
                      size='small'
                      name='model'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Modelo'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Field
                      size='small'
                      name='category'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Categoria'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Field
                      size='small'
                      name='sub_category'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Sub Categoria'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Field
                      size='small'
                      name='cod_repair'
                      variant='outlined'
                      fullWidth
                      option={[]}
                      label='Código Reparo'
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Button fullWidth type='submit'>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
