import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Dialog maxWidth='lg' open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Cadastro</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_name: '',
              input_description: '',
              input_type_product: '',
              input_marca: '',
              input_model: '',
              input_color: '',
            }}
          >
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_name'
                    variant='outlined'
                    fullWidth
                    label='Produto de Origem'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_description'
                    variant='outlined'
                    fullWidth
                    label='Descrição Produto'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_type_product'
                    variant='outlined'
                    fullWidth
                    label='Tipo Produto'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_marca'
                    variant='outlined'
                    fullWidth
                    label='Marca'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_model'
                    variant='outlined'
                    fullWidth
                    label='Modelo'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    loading={isLoading}
                    name='input_color'
                    variant='outlined'
                    fullWidth
                    label='Cor'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <Button fullWidth loading={isLoading}>
                    Gravar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
