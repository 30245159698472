import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { providersService } from 'services';

export const DialogDeleteComplaint = ({ openDialogDelete, handleCloseDialogDelete, dataLinha }) => {
  if (!openDialogDelete) {
    return null;
  }
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteComplaint = async () => {
    setIsLoading(true);
    try {
      const { data } = await providersService.deleteComplaint({ dataLinha });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        handleCloseDialogDelete();
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao excluir reclamação');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogDelete}>
        <Dialog.Title onClose={handleCloseDialogDelete}>Remover Reclamação</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Deseja realmente excluir a reclamação `{dataLinha.descricao}` ?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button
                fullWidth
                onClick={handleCloseDialogDelete}
                color='secondary'
                loading={isLoading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button fullWidth onClick={() => handleDeleteComplaint()} loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
