import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const CompletedOrders = () => {
  const typeDate = [
    { label: 'Data de Criação', value: 0 },
    { label: 'Data de Faturamento', value: 1 },
  ];

  const [loading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde relatório sendo gerado...');
    try {
      const { data } = await providersService.completedOrdersReport({ values });
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadcsv(`Relatório_Pedidos_Finalizados${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Pedidos Finalizados</PageTitle>

      <Formik
        initialValues={{ type_data: 0, input_data: new Date(), output_data: new Date() }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  fullWidth
                  name='type_data'
                  variant='outlined'
                  label='Tipo de Data'
                  options={typeDate}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button type='submit' fullWidth loading={loading}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
