import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';
import * as yup from 'yup';

import { Stage } from '../../../../helpers/getStagesAging';
import { Marca } from '../../../../helpers/tipoMarca';
import { providersService } from '../../../../services';

const validationSchema = yup.object().shape({
  type_report: yup.number().required(),
  type_marca: yup.number().when('type_report', {
    is: 1,
    then: yup.number().required().typeError('É necessário selecionar o tipo de produto.'),
    otherwise: yup.number().nullable(),
  }),
  type_stage: yup.number().when('type_report', {
    is: 1,
    then: yup.number().required().typeError('É necessário selecionar o tipo de produto.'),
    otherwise: yup.number().nullable(),
  }),
});

export const ProductsNoSkuReport = () => {
  const TypeReport = [
    { label: 'Resumido', value: 0 },
    { label: 'Detalhado', value: 1 },
  ];
  const [selectTypeReport, setselectTypeReport] = useState(0);
  const [typeMarca, setTypeMarca] = useState([{ label: '', value: '' }]);
  const [selectTypeMarca, setselectTypeMarca] = useState([0]);
  const [typeStage, setTypeStage] = useState([{ label: '', value: '' }]);
  const [selectTypeStage, setselectTypeStage] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);

  const getBrand = useCallback(async () => {
    const data = await Marca();
    const firstValueMarca = [{ label: 'TODOS', value: 0 }, ...data];
    setTypeMarca(firstValueMarca);

    const dataStage = await Stage();
    const firstValueStage = [{ label: 'TODOS', value: 0 }, ...dataStage];

    setTypeStage(firstValueStage);
  }, []);

  useEffect(() => {
    getBrand();
  }, [getBrand]);

  const handleProductsNoSkuReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);

    const now = new Date().toLocaleDateString();

    try {
      let typeMarcaValue = selectTypeMarca;
      let typeStageValue = selectTypeStage;

      if (selectTypeReport === 0) {
        typeMarcaValue = 0;
        typeStageValue = 0;
      }

      const result = await providersService.getProductsNoSkuReport(
        selectTypeReport,
        typeMarcaValue,
        typeStageValue,
      );
      if (result && result.data && result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          productsNoSkuReport: worksheet,
        },
        SheetNames: ['productsNoSkuReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_ProdutosSemSku${now}.xlsx`);
      //   await downloadcsv(`RelatorioEstoque${now}`, result);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório Produtos em linha sem SKU</PageTitle>
      <Formik
        initialValues={{
          type_report: selectTypeReport,
          type_marca: selectTypeMarca,
          type_stage: selectTypeStage,
        }}
        validationSchema={validationSchema}
        onSubmit={handleProductsNoSkuReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo Relatório'
                  variant='outlined'
                  options={TypeReport}
                  component={Select}
                />
              </Grid>
              {props.values.type_report === 1 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='type_marca'
                      label='Marcas'
                      variant='outlined'
                      multiple
                      options={typeMarca.map((option) => ({
                        ...option,
                        disabled:
                          props.values.type_report === 1 &&
                          props.values.type_marca.includes(0) &&
                          option.value !== 0,
                      }))}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='type_stage'
                      label='Etapas'
                      variant='outlined'
                      multiple
                      options={typeStage.map((option) => ({
                        ...option,
                        disabled:
                          props.values.type_report === 1 &&
                          props.values.type_stage.includes(0) &&
                          option.value !== 0,
                      }))}
                      component={Select}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} lg={4}>
                <Button fullWidth loading={isLoading} type='submit'>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>

            {useEffect(() => {
              setselectTypeReport(props.values.type_report);
              setselectTypeMarca(props.values.type_marca);
              setselectTypeStage(props.values.type_stage);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
