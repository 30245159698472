import React from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { useStyles } from './styles';
export const Results = ({ productSearchData }) => {
  const classes = useStyles();
  return (
    <Formik
      initialValues={{
        new_average: productSearchData?.average?.newPrice ?? 0,
        new_min:
          productSearchData?.minPrice?.newMinPrice == 100000
            ? 0
            : productSearchData?.minPrice?.newMinPrice,
        new_max: productSearchData?.maxPrice?.newMaxPrice,
        used_average: productSearchData?.average?.usedPrice ?? 0,
        used_min:
          productSearchData?.minPrice?.usedMinPrice == 100000
            ? 0
            : productSearchData?.minPrice?.usedMinPrice,
        used_max: productSearchData?.maxPrice?.usedMaxPrice,
        refurbished_average: productSearchData?.average?.refurbishedPrice ?? 0,
        refurbished_min:
          productSearchData?.minPrice?.refurbishedMinPrice == 100000
            ? 0
            : productSearchData?.minPrice?.refurbishedMinPrice,
        refurbished_max: productSearchData?.maxPrice?.refurbishedMaxPrice,
      }}
    >
      {(props) => (
        <>
          <Form>
            <Paper title='Valores' elevation={5} variant='elevation'>
              <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='new_min'
                    label='Valor mínimo de um produto novo'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='new_average'
                    label='Valor médio de um produto novo'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='new_max'
                    label='Valor máximo de um produto novo'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='used_min'
                    label='Valor mínimo de um produto usado'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='used_average'
                    label='Valor médio de um produto usado'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='used_max'
                    label='Valor máximo de um produto usado'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='refurbished_min'
                    label='Valor mínimo de um produto refurbished'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='refurbished_average'
                    label='Valor médio de um produto refurbished'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <Field
                    variant='outlined'
                    name='refurbished_max'
                    label='Valor máximo de um produto refurbished'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </Paper>
          </Form>
        </>
      )}
    </Formik>
  );
};
