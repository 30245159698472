import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

export const DialogConfirm = ({ handleCloseDialogConfirm, openDialogConfirm }) => {
  return (
    <>
      <Dialog open={openDialogConfirm}>
        <Dialog.Title onClose={handleCloseDialogConfirm}>Confirmação</Dialog.Title>
        <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
          Você deseja realmente excluir o cliente?
        </Typography>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Button fullWidth color='secondary' onClick={handleCloseDialogConfirm}>
                Não
              </Button>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button fullWidth color='primary'>
                Sim
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
