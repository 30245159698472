import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';

import { providersService } from '../../../../services';

export const ComponentsRegisterReport = () => {
  const now = new Date().toLocaleDateString('pt-BR');
  const [loading, setLoading] = useState(false);

  const handleComponentsRegisterReport = async () => {
    setLoading(true);
    toast.info('Aguarde, relatório sendo gerado.');
    try {
      const { data } = await providersService.getComponentsRegisterReport();

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));

      const workbook = (XLSX.WorkBook = {
        Sheets: {
          ComponentsRegisterReport: worksheet,
        },
        SheetNames: ['ComponentsRegisterReport'],
      });
      XLSX.writeFile(workbook, `RelatorioRecebimento_${now}.xlsx`);

      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório de Cadastro de Componentes</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Button
            type='submit'
            fullWidth
            onClick={handleComponentsRegisterReport}
            loading={loading}
          >
            Exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
