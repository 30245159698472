import { api } from 'services/api';

export class StockService {
  async getDataAlternate() {
    return api.get('/xpcell-multimarcas-stock/stock/alternarte');
  }

  async addAlternateData(data) {
    return api.post('/xpcell-multimarcas-stock/stock/add-alternarte', data);
  }
}

export const stockService = new StockService();
