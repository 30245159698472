import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { PageTitle } from 'pages/Layout/PageTitle';

import { Operacao } from '../../../../helpers/getOperation';
import { StageCmp } from '../../../../helpers/getStageCmp';
import { providersService } from '../../../../services';

export const OsCmpReport = () => {
  const typeReport = [
    { label: 'OS`s em HF', value: 0 },
    { label: 'OS`s em CMP', value: 1 },
  ];
  const [selectTypeReport, setSelectTypeReport] = useState(0);
  const typePeriod = [
    { label: 'Atual', value: 0 },
    { label: 'Histórico', value: 1 },
  ];
  const [selectTypePeriod, setSelectTypePeriod] = useState(0);
  const typeData = [
    { label: 'Data de entrada', value: 0 },
    { label: 'Data de saída', value: 1 },
  ];
  const [selectTypeData, setSelectTypeData] = useState(0);
  const [typeOpr, setTypeOpr] = useState([{ label: '', value: '' }]);
  const [selectTypeOpr, setSelectTypeOpr] = useState('');
  const [typeStage, setTypeStage] = useState([{ label: '', value: '' }]);
  const [selectTypeStage, setSelectTypeStage] = useState('');
  const today = new Date();
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const dataOpr = await Operacao();
    const dataStage = await StageCmp();

    const firstValue = [{ label: 'TODOS', value: '0' }, ...dataOpr];

    setTypeOpr(firstValue);
    setTypeStage(dataStage);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleOsCmpReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      let typePeriodValue = selectTypePeriod;
      let typeDataValue = selectTypeData;

      if (selectTypeReport === 0) {
        typePeriodValue = 0;
        typeDataValue = 0;
      }
      if (selectTypePeriod === 0) {
        typeDataValue;
      }

      const { data } = await providersService.getOsCmpReport(
        selectTypeReport,
        selectTypeOpr,
        selectTypeStage,
        typePeriodValue,
        typeDataValue,
        formattedInputData,
        formattedOutputData,
      );

      if (data.length === 0) {
        toast.error('Não há dados para esse relatório');
        setIsLoading(false);
        return;
      }

      await downloadXlsx(`Relatorio_de_OS_em_CMP/HF${now}`, data);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório OSs em CMP/HF</PageTitle>

      <Formik
        initialValues={{
          type_report: selectTypeReport,
          type_opr: selectTypeOpr,
          type_stage: selectTypeStage,
          type_period: selectTypePeriod,
          type_data: selectTypeData,
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleOsCmpReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo relatório'
                  variant='outlined'
                  options={typeReport}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_opr'
                  label='Operação'
                  variant='outlined'
                  options={typeOpr}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='type_stage'
                  label='Etapa'
                  variant='outlined'
                  options={typeStage}
                  component={Select}
                />
              </Grid>
              {props.values.type_report === 1 && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='type_period'
                      label='Periódo'
                      variant='outlined'
                      options={typePeriod}
                      component={Select}
                    />
                  </Grid>
                  {props.values.type_period === 1 && (
                    <>
                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          name='type_data'
                          label='Tipo data'
                          variant='outlined'
                          options={typeData}
                          component={Select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Field
                          size='small'
                          name='input_data'
                          variant='inline'
                          fullWidth
                          inputVariant='outlined'
                          label='Data inicial'
                          component={DatePicker}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <Field
                          size='small'
                          name='output_data'
                          variant='inline'
                          fullWidth
                          inputVariant='outlined'
                          label='Data final'
                          component={DatePicker}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item xs={12} lg={3}>
                <Button type='submit' fullWidth loading={isLoading}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>

            {useEffect(() => {
              setSelectTypeReport(props.values.type_report);
              setSelectTypeOpr(props.values.type_opr);
              setSelectTypeStage(props.values.type_stage);
              setSelectTypePeriod(props.values.type_period);
              setSelectTypeData(props.values.type_data);
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
