import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const LotManagementReport = () => {
  const typesReports = [
    { label: 'TODOS', value: 0 },
    { label: 'Posição Atual', value: 1 },
  ];

  const typeDate = [
    { label: 'Data Lote', value: 0 },
    { label: 'Data NF', value: 1 },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async (values) => {
    toast.info('Aguarde relatório sendo gerado');
    setIsLoading(true);

    try {
      const { data } = await providersService.lotManagementReport({ values });
      console.log(data);
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadcsv(`Relatório_Geranciamento_Lote${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao gerar relatório');
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Gereciamento de Lotes (Nfs)</PageTitle>

      <Formik
        initialValues={{
          type_report: 0,
          type_date: 0,
          input_date: new Date(),
          output_date: new Date(),
        }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo de Relatório'
                  variant='outlined'
                  component={Select}
                  options={typesReports}
                />
              </Grid>
              {props.values.type_report === 0 && (
                <>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='type_date'
                      label='Tipo de Data'
                      variant='outlined'
                      component={Select}
                      options={typeDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='input_date'
                      label='Data de inicio'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Field
                      size='small'
                      name='output_data'
                      label='Data Final'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
