import { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { FormatDate, Table, Text } from 'components';
import { Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';

import { ListProfilesDialog } from './ListProfilesDialog';

const columns = [
  { label: 'Nome' },
  { label: 'E-mail' },
  { label: 'Data do cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const ListProfiles = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [profile, setProfile] = useState({});

  const [search, setSearch] = useState('');

  const [usersData, , loadingUsersData, usersRefetch] = useQuery(
    accountService.listUsersPerfil,
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setProfile(item);
  };

  const formattedUsers = (usersData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredUsers = formattedUsers.filter((users) => {
    const upperName = users.name?.toUpperCase();
    const lowerName = users.name?.toLowerCase();

    const upperEmail = users.email?.toUpperCase();
    const lowerEmail = users.email?.toLowerCase();

    return (
      upperName?.includes(search) ||
      lowerName?.includes(search) ||
      upperEmail?.includes(search) ||
      lowerEmail?.includes(search)
    );
  });

  useEffect(() => {}, [formattedUsers, filteredUsers]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const newFilteredUsers = filteredUsers.filter((item) => item.role_id != 6);

  return (
    <>
      <PageTitle>Perfis</PageTitle>
      <Formik>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    loading={loadingUsersData}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Sem usuários cadastrados.'
                    // onActionsClick={handleOpenInformation}
                    data={filteredUsers}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {newFilteredUsers?.map((user) => (
                      <TableRow key={user?.id}>
                        <TableCell>{user?.name}</TableCell>
                        <TableCell>{user?.email}</TableCell>
                        <TableCell>
                          {' '}
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>{user?.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            style={{ color: '#512DA8' }}
                            onClick={(e) => handleOpenInformation(e, user)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <ListProfilesDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  profile={profile}
                  reloadProfiles={usersRefetch}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
