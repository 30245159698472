import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { formatDate } from 'helpers/formateDate';
import { Operation } from 'helpers/operation';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

const typeSelect = [
  { label: 'Detalhado', value: 'detalhado' },
  { label: 'Resumido', value: 'resumido' },
];

export const OpenServiceOrderReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [operationValue, setOperationValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const operationSelect = Operation();
  const [typeValue, setTypeValue] = useState('detalhado');

  const handleDownloadReport = async (buttonName, values, { setSubmitting }) => {
    const temp = { ...values, who: buttonName };
    setSubmitting(true);
    setIsLoading(true);
    toast.info('Gerando o relatório de abertura de os...');
    try {
      const { data } = await providersService.getOpenServiceOrderReport(temp);
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          Geral: worksheet,
        },
        SheetNames: ['Geral'],
      });
      const filneName = `Relatorioabertura_os_${values?.range_data_type}_${now}.xlsx`;
      XLSX.writeFile(workbook, filneName);

      toast.success('Relatório deprodução por setor gerado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data?.message || 'Erro na geração do relatório de abertura de OS',
      );
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Abertura de OS</PageTitle>

      <Formik
        initialValues={{
          year: year,
          input_data: inputData,
          output_data: outputData,
          range_data_type: typeValue,
          range_data_operation: operationValue,
        }}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data_type'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data_stage'
                  label='Operação'
                  variant='outlined'
                  options={operationSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='year'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Ano'
                  component={DatePicker}
                  views={['year']}
                  format='yyyy'
                  openTo='year'
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  disabled={isLoading}
                  type='submit'
                  fullWidth
                  loading={props.isSubmitting}
                  onClick={(e) => handleDownloadReport('data', props.values, props)}
                >
                  exportar relatório por data
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  disabled={isLoading}
                  type='submit'
                  fullWidth
                  loading={props.isSubmitting}
                  onClick={(e) => handleDownloadReport('ano', props.values, props)}
                >
                  exportar relatório por ano
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setYear(props.values.year);
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setTypeValue(props.values.range_data_type);
              setOperationValue(props.values.range_data_operation);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
