import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { registerService } from '../../../../../services/register';

const validationSchema = Yup.object().shape({
  input_new_model: Yup.string().required('Modelo é obrigatório '),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateBrand = async (values) => {
    toast.info('Atualizando marca');
    setIsLoading(true);
    try {
      const { data } = await registerService.updateModel({
        description: values.input_new_model,
        id: line.id,
      });
      toast.success('Modelo atualizada com sucesso');
      handleCloseDialogEdit();
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao atualizar modelo');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ input_new_model: '' }}
            onSubmit={handleUpdateBrand}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_new_model'
                      variant='outlined'
                      fullWidth
                      label='Modelo'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Gravar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
