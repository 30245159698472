import { api } from 'services/api';

export class ReceiptService {
  async getSupplier(cnpj) {
    return api.get(`/xpcell-multimarcas-receipt/receipt/getSuppliers/${cnpj}`);
  }

  async getDataTable() {
    return api.get('/xpcell-multimarcas-receipt/receipt/getDataTable');
  }

  async getTableStatus(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/getTableStatus', data);
  }

  async validateKeyNf(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/validateKeyNf', data);
  }

  async registerNf(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/registerNf', data);
  }

  async sendNf(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/sendNf', data);
  }

  async removeNf(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/removeNf', data);
  }

  async generateNf() {
    return api.get('/xpcell-multimarcas-receipt/receipt/generateNf');
  }

  async getDataTableMinuta(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/getDataTableMinuta', data);
  }

  async generatePDF(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/generatePDF', data, {
      responseType: 'blob',
    });
  }

  async UpdateSend(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/UpdateSend', data);
  }

  async downloadPdfLoteMinuta(data) {
    return api.post('/xpcell-multimarcas-receipt/receipt/downloadPdfLoteMinuta', data, {
      responseType: 'blob',
    });
  }

  async listPdvReturnSeriais(isCollect, skip, take) {
    const response = await api.post('/receipt/list/pdv-return-seriais', { isCollect, skip, take });
    return response.data;
  }

  async collectSerialPdv(serial) {
    const response = await api.post('/receipt/create/service-order-by-pdv', { serial });
    return response.data;
  }
}

export const receiptService = new ReceiptService();
