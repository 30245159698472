import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';

import { Screen } from '../../../../helpers/getSrceens';
import { providersService } from '../../../../services';

export const ProductionRegisterReport = () => {
  const selectType = [
    { label: 'Data de cadastro', value: 0 },
    { label: 'Data de modificação', value: 1 },
    { label: 'TELAS', value: 2 },
  ];
  const [typeValue, setTypeValue] = useState(0);
  const [TypeScreen, setTypeScreen] = useState([{ label: '', value: '' }]);
  const [selectTypeScreen, setSelectTypeScreen] = useState('');
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const getScreen = useCallback(async () => {
    const data = await Screen();

    setTypeScreen(data);
  }, []);

  useEffect(() => {
    getScreen();
  }, [getScreen]);

  const handleProductionRegisterReport = async () => {
    console.log(typeValue, selectTypeScreen, inputData, outputData);
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    const now = new Date().toLocaleDateString('pt-BR');
    console.log('teste');

    if (!selectTypeScreen && typeValue === 2) {
      toast.error('Faltou selecionar tela');
      return;
    }

    try {
      const typeScreenValue = selectTypeScreen === 0 ? '0' : selectTypeScreen;
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getProductionRegisterReport(
        typeValue,
        typeScreenValue,
        formattedInputData,
        formattedOutputData,
      );

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          ProductionRegisterReport: worksheet,
        },
        SheetNames: ['ProductionRegisterReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Produção_de_Cadastros${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Produção de Cadastros</PageTitle>

      <Formik
        initialValues={{
          type_report: typeValue,
          input_data: inputData,
          output_data: outputData,
          type_screen: selectTypeScreen,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={selectType}
                  component={Select}
                />
              </Grid>

              {(props.values.type_report === 0 || props.values.type_report === 1) && (
                <>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              {props.values.type_report === 2 && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='type_screen'
                    label='Telas'
                    variant='outlined'
                    options={TypeScreen}
                    component={Select}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  loading={isLoading}
                  onClick={handleProductionRegisterReport}
                  type='submit'
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeScreen(props.values.type_screen);
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setTypeValue(props.values.type_report);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
