import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { DialogConference } from './Dialogs/DIalogConferece';

const columns = [
  { label: 'Fornecedor' },
  { label: 'Nota' },
  { label: 'Serie' },
  { label: 'Data Recebimento' },
  { label: 'Alterar' },
  { label: 'Conferência' },
  { label: 'Excluir' },
];

export const ConsultNfEntryPart = () => {
  const [openDialogConference, setOpenDialogConference] = useState(false);

  const handleOpenDialogConference = () => {
    setOpenDialogConference(true);
  };
  const handleCloseDialogConference = () => {
    setOpenDialogConference(false);
  };
  return (
    <>
      <DialogConference
        openDialogConference={openDialogConference}
        handleCloseDialogConference={handleCloseDialogConference}
      />
      <Formik>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  size='small'
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  <TableRow>
                    <TableCell>REFURBISHED</TableCell>
                    <TableCell>123</TableCell>
                    <TableCell>25</TableCell>
                    <TableCell>27/08/2024</TableCell>
                    <TableCell>
                      <Button>
                        <SyncAltIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button onClick={handleOpenDialogConference}>
                        <PlaylistAddCheckIcon />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button color='secondary'>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
