import { Table } from 'components';

const columns = [
  { label: 'Coluna 1', align: 'center' },
  { label: 'Coluna 2', align: 'center' },
  { label: 'Coluna 3', align: 'center' },
  { label: 'Coluna 4', align: 'center' },
  { label: 'Coluna 5', align: 'center' },
];

export function ServiceTable() {
  return (
    <>
      <h2>Lista de atendimentos</h2>
      <Table
        disableNumeration
        headers={columns}
        data={[]}
        emptyMessage='Nenhum atendimento foi encontrado'
      ></Table>
    </>
  );
}
