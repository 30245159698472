import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Radio from '@mui/material/Radio';
import { Button, Dialog, Table } from 'components';
import { readCsv } from 'helpers/readcsv';
import { shoppingService } from 'services';

import { DialogJustify } from './DialogJustify';

const colunas = [
  { label: 'Aprovar' },
  { label: 'Reprovar' },
  { label: 'Analise Pedido' },
  { label: 'Atendimento' },
  { label: 'Cliente' },
  { label: 'Cor' },
  { label: 'Data' },
  { label: 'Descrição Modelo' },
  { label: 'Etapa' },
  { label: 'Estoque' },
  { label: 'LocalOS' },
  { label: 'Lucro' },
  { label: 'Num Pedido' },
  { label: 'OBS' },
  { label: 'OBS2' },
  { label: 'PN Alternativo' },
  { label: 'Previsão Entrega' },
  { label: 'Processo' },
  { label: 'Qtd OS' },
  { label: 'Rastreio' },
  { label: 'SKU' },
  { label: 'Status' },
  { label: 'Sub Etapa' },
  { label: 'SerialNumber' },
  { label: 'StatusLucroPrej' },
  { label: 'Status Peca' },
  { label: 'Status Valores' },
  { label: 'Venda Furb' },
  { label: 'VlrMercado' },
  { label: 'VlrTotal' },
  { label: 'VlrUnit' },
  { label: 'VlrVarejo' },
  { label: 'Aging Data Envio' },
  { label: 'catPeca' },
  { label: 'categoria' },
  { label: 'Custo Medio Entrada' },
  { label: 'Data Lote2' },
  { label: 'Desc Peça' },
  { label: 'Data AbreOS' },
  { label: 'Data EnvioCMP' },
  { label: 'Data Registro' },
  { label: 'Num Lote' },
  { label: 'id_cabec' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Motivos' },
  { label: 'Num Tec' },
  { label: 'Num OS' },
  { label: 'Operação' },
  { label: 'Pedido Realizado' },
  { label: 'PN CMP' },
  { label: 'PN OS' },
  { label: 'Qtde' },
  { label: 'Referência' },
  { label: 'Referência Valor' },
  { label: 'Serial IN' },
  { label: 'Subcat Peca' },
  { label: 'Tag Servico' },
  { label: 'Técnico CMP' },
  { label: 'Tipo Produto' },
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const DialogApprove = ({
  openDialogApprove,
  handleCloseDialogApprove,
  idRegistro,
  user,
}) => {
  console.log(idRegistro);
  const [dataTable, setDataTable] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [justifyCsv, setJustifyCsv] = useState(false);
  const [dataCsv, setDataCsv] = useState([]);
  const [text, setText] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedRadio, setSelectedRadio] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  console.log('selectedRadio', selectedRadio);

  const getDados = useCallback(async () => {
    const { data } = await shoppingService.getBaseForApprove({
      idRegistro: idRegistro,
    });

    setDataTable(data);
  }, [idRegistro]);

  useEffect(() => {
    if (openDialogApprove) {
      getDados();
    }
  }, [openDialogApprove, getDados]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleApprovaBase = async () => {
    setIsLoading(true);
    try {
      const { data } = await shoppingService.upadateApproveBase({
        idCadastro: idRegistro,
        user: user.name,
      });
      if (data.status === 200) {
        toast.success('Todos itens da Base aprovados com sucesso!');
        handleCloseDialogApprove();
        setIsLoading(false);
      } else {
        toast.error('Erro ao aprovar registro!');
        setIsLoading(false);
      }
      getDados();
    } catch {
      toast.error('Erro ao aprovar base');
      setIsLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const validationChangeApprove = (idCadastro, itemIdCadastro, approve, status) => {
    if (idCadastro === itemIdCadastro) {
      if (approve === null) {
        return status === 'approve' ? true : false;
      } else {
        return !approve;
      }
    } else {
      return approve;
    }
  };

  const handleRadioChange = async (item, status) => {
    const temp = dataTable.map((element) => ({
      ...element,
      approve: validationChangeApprove(
        element.idCadastro,
        item.idCadastro,
        element.approve,
        status,
      ),
    }));
    setDataTable(temp);
  };

  const handleCheckedAllApprove = () => {
    setText('');
    const temp = dataTable.map((element) => ({
      ...element,
      approve: true,
    }));
    setDataTable(temp);
  };
  const handleCheckedAllReprove = () => {
    const temp = dataTable.map((element) => ({
      ...element,
      approve: false,
    }));
    setDataTable(temp);
    handleOpenDialog();
  };

  const handleConfirmApprovement = async () => {
    toast.info('Aguarde... Processando aprovação');
    setIsLoading(true);
    try {
      const { data } = await shoppingService.approveParcialBase({
        dataTable: dataTable,
        user: user.name,
        text: text,
      });
      if (data.status === 200) {
        toast.success(data.msg);
        handleCloseDialogApprove();
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro na aprovação da base');
      setIsLoading(false);
    }
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const hanldeReadDataCsv = async (e) => {
    setIsLoading(true);
    toast.info('importando dados ...');

    try {
      console.log('oi');
      const data_csv = await readCsv(e);
      console.log('data_csv', data_csv);

      if (data_csv.length === 0) {
        toast.error('Arquivo vazio! Por Favor importe um arquivo que contenha dados');
        setIsLoading(false);
        return;
      }

      const sizeCsv = data_csv.length;
      const sizeImported = dataTable.length;

      const cabecCsv = parseInt(data_csv[0].id_cabec);
      const cabecTable = dataTable[0].id_cabec;

      if (sizeCsv !== sizeImported) {
        toast.error(
          `Quantidade de itens sendo avaliados ${sizeCsv} não corresponde a quantidade de itens importados inicialmente ${sizeImported}`,
        );
        setIsLoading(false);
        return;
      }

      if (cabecCsv !== cabecTable) {
        toast.error(
          'Não é possível aprovar dados de outra base. Verifique se está na base correta',
        );
        setIsLoading(false);
        return;
      }

      if (data_csv.some((item) => item.Aprovacao === 'Nao Revisado')) {
        toast.error('Alguns itens não foram avalidos, por favor aprove-os ou reprove-os');
        setIsLoading(false);
        return;
      }

      if (
        data_csv.every((item) => item.Justificativa === '' || null) &&
        data_csv.some((item) => item.Aprovacao === 'reprovado')
      ) {
        toast.error(
          'Base contém itens reprovados mas não há nenhuma justificativa. Por favor adicione uma justificativa',
        );
        setIsLoading(false);
        return;
      }

      const { data } = await shoppingService.approveWithCsv({
        data_csv: data_csv,
        name: user.name,
      });

      if (data.status === 200) {
        toast.success(data.msg);
        handleCloseDialogApprove();
      } else {
        toast.error('Erro ao avaliar base');
      }

      setIsLoading(false);
      toast.success('Dados importados com sucesso');
      getDados();
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Erro ao ler CSV');
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={hanldeReadDataCsv}
        accept='text/csv'
        hidden
      />
      <DialogJustify
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        idRegistro={idRegistro}
        text={text}
        setText={setText}
        user={user}
      />
      <Dialog fullScreen open={openDialogApprove}>
        <Dialog.Title onClose={() => handleCloseDialogApprove()}>Aprovação Base</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button loading={isLoading} fullWidth onClick={() => handleCheckedAllApprove()}>
                Aprovar Todos
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button
                loading={isLoading}
                onClick={() => handleCheckedAllReprove()}
                color='secondary'
                fullWidth
              >
                Reprovar Todos
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button
                disabled={dataTable.some((item) => item.approve === null)}
                loading={isLoading}
                onClick={() => handleConfirmApprovement()}
                fullWidth
              >
                Confirmar
              </Button>
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Button loading={isLoading} fullWidth onClick={() => handleImportClick()}>
                Aprovar com Csv
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Table name='Table' headers={colunas} disableNumeration>
                {dataTable &&
                  dataTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Radio
                            size='small'
                            checked={item?.approve === null ? false : item?.approve}
                            onChange={() => handleRadioChange(item, 'approve')}
                            value='aprovar'
                            name={`radioGroup${item.idCadastro}`}
                          />
                        </TableCell>
                        <TableCell>
                          <Radio
                            size='small'
                            checked={item?.approve === null ? false : !item?.approve}
                            onChange={() => handleRadioChange(item, 'reprove')}
                            value='reprovar'
                            name={`radioGroup${item.idCadastro}`}
                          />
                        </TableCell>
                        <TableCell>{item.AnalisePedido}</TableCell>
                        <TableCell>{item.Atendimento}</TableCell>
                        <TableCell>{item.Cliente}</TableCell>
                        <TableCell>{item.Cor}</TableCell>
                        <TableCell>{formatDate(item.DATA)}</TableCell>
                        <TableCell>{item.DescModelo}</TableCell>
                        <TableCell>{item.ETAPA}</TableCell>
                        <TableCell>{item.Estoque}</TableCell>
                        <TableCell>{item.Local_OS}</TableCell>
                        <TableCell>{item.Lucro}</TableCell>
                        <TableCell>{item.NUMERO_DO_PEDIDO}</TableCell>
                        <TableCell>{item.OBSERVACAO}</TableCell>
                        <TableCell>{item.OBSERVACAO_2}</TableCell>
                        <TableCell>{item.PN_Alternativo}</TableCell>
                        <TableCell>{item.PREVISAO_DE_ENTREGA}</TableCell>
                        <TableCell>{item.Processo}</TableCell>
                        <TableCell>{item.QtdOS}</TableCell>
                        <TableCell>{item.Rastreio}</TableCell>
                        <TableCell>{item.SKU}</TableCell>
                        <TableCell>{item.STATUS}</TableCell>
                        <TableCell>{item.SUB_ETAPA}</TableCell>
                        <TableCell>{item.SerialNumber}</TableCell>
                        <TableCell>{item.StatusLucroPrej}</TableCell>
                        <TableCell>{item.StatusPeca}</TableCell>
                        <TableCell>{item.StatusValores}</TableCell>
                        <TableCell>{item.Venda_Furb}</TableCell>
                        <TableCell>{item.VlrMercado}</TableCell>
                        <TableCell>{item.VlrTotal}</TableCell>
                        <TableCell>{item.VlrUnit}</TableCell>
                        <TableCell>{item.VlrVarejo}</TableCell>
                        <TableCell>{item.agingDtEnvio}</TableCell>
                        <TableCell>{item.catPeca}</TableCell>
                        <TableCell>{item.categoria}</TableCell>
                        <TableCell>{item.custoMedioEntrada}</TableCell>
                        <TableCell>{formatDate(item.data_lote2)}</TableCell>
                        <TableCell>{item.descPeca}</TableCell>
                        <TableCell>{formatDate(item.dtAbreOS)}</TableCell>
                        <TableCell>{formatDate(item.dtEnvioCMP)}</TableCell>
                        <TableCell>{formatDate(item.dt_registre)}</TableCell>
                        <TableCell>{item.idLote}</TableCell>
                        <TableCell>{item.id_cabec}</TableCell>
                        <TableCell>{item.marca}</TableCell>
                        <TableCell>{item.modelo}</TableCell>
                        <TableCell>{item.motivos}</TableCell>
                        <TableCell>{item.numTec}</TableCell>
                        <TableCell>{item.num_OS}</TableCell>
                        <TableCell>{item.operacao}</TableCell>
                        <TableCell>{item.pedido_realizado}</TableCell>
                        <TableCell>{item.pnCMP}</TableCell>
                        <TableCell>{item.pnOS}</TableCell>
                        <TableCell>{item.qtde}</TableCell>
                        <TableCell>{item.reference}</TableCell>
                        <TableCell>{item.referencia_valor}</TableCell>
                        <TableCell>{item.serialIN}</TableCell>
                        <TableCell>{item.subcatPeca}</TableCell>
                        <TableCell>{item.tagServico}</TableCell>
                        <TableCell>{item.tecnicoCMP}</TableCell>
                        <TableCell>{item.tipoProduto}</TableCell>
                      </TableRow>
                    ))}
              </Table>
              <TablePagination
                component='div'
                count={dataTable.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
