import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';
import * as yup from 'yup';

import { AlterRoleDialog } from './AlterRoleDialog';
import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  alias: yup.string().required(),
  description: yup.string().required(),
});

const columns = [
  { label: 'Sigla' },
  { label: 'Descrição' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const CreateRole = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [roles, setRoles] = useState({});
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [roleData, , loadingRoleData, refetchRole] = useQuery(
    () => accountService.listUserRoles(),
    [],
  );

  useEffect(() => {}, []);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setRoles(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    console.log('values: ', values);
    try {
      setSubmitting(true);
      await accountService.createRole(values);

      toast.success('Grupo de usuário criado com sucesso.');
      refetchRole();
    } catch (error) {
      toast.error(error.response.data?.message || 'Erro ao cadastrar grupo de usuário.');
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  const roleDataNew = roleData && roleData.filter((item) => item.id !== 6);
  // console.log(roleData.some((item) => item.alias === 'ti'));

  return (
    <>
      <PageTitle>Cadastrar novo grupo de usuário</PageTitle>
      <Formik
        initialValues={{
          alias: '',
          description: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='alias'
                    label='Sigla de Identificação'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='description'
                    label='Descrição'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingRoleData}
                    emptyMessage='Nenhum registro encontrado.'
                    data={roleData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {roleDataNew?.map((role) => (
                      <TableRow key={role?.id}>
                        <TableCell>{role?.alias}</TableCell>
                        <TableCell>{role?.description}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, role)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <AlterRoleDialog
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        role={roles}
        reloadRoles={refetchRole}
      />
    </>
  );
};
