import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { DialogConfirmDeleteItem } from './DialogConfirmDeleteItem';

const columnsItem = [
  { label: 'Item' },
  { label: 'Produto Origem' },
  { label: 'Produto Interno' },
  { label: 'Descrição' },
  { label: 'Qtd' },
  { label: 'Valor Unitário' },
  { label: 'Total Item' },
  { label: 'Editar' },
  { label: 'Excluir' },
];

export const ViewItens = ({ handleBackToNf, handleChangeToRegisterItens }) => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  const itensTeste = [
    {
      idCad: 1,
      ProdOri: 'SM-SAMS784',
      ProdInt: '1445641',
      desc: 'TELEFONE CELULAR',
      numItem: '1',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
    {
      idCad: 7,
      ProdOri: 'SM-SAMS784',
      ProdInt: '5445641',
      desc: 'TELEFONE CELULAR',
      numItem: '2',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
    {
      idCad: 10,
      ProdOri: 'SM-SAMS784',
      ProdInt: '5445641',
      desc: 'TELEFONE CELULAR',
      numItem: '3',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
    {
      idCad: 6,
      ProdOri: 'SM-SAMS784',
      ProdInt: '0445641',
      desc: 'TELEVISÃO',
      numItem: '4',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
    {
      idCad: 10,
      ProdOri: 'DEL-DELS784',
      ProdInt: '5445641',
      desc: 'NOTEBOOK',
      numItem: '3',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
    {
      idCad: 6,
      ProdOri: 'SM-SAMS784',
      ProdInt: '0445641',
      desc: 'TELEFONE CELULAR',
      numItem: '4',
      qtd: 3,
      ativo: 1,
      valorUni: 999.99,
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      total: 99999.99,
    },
  ];

  const handleOpenModalConfirmDeleteItem = () => {
    setOpenModalConfirmDeleteItem(true);
  };
  const handleCloseModalConfirmDeleteItem = async () => {
    setOpenModalConfirmDeleteItem(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Função para filtrar os dados da tabela com base no valor de pesquisa
  const filteredData = itensTeste.filter((item) => {
    return (
      item.numItem.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ProdOri.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.ProdInt.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.desc.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.qtd.toString().includes(searchQuery) ||
      item.valorUni.toString().includes(searchQuery) ||
      item.total.toString().includes(searchQuery)
    );
  });

  return (
    <>
      <DialogConfirmDeleteItem
        openModalConfirmDeleteItem={openModalConfirmDeleteItem}
        handleCloseModalConfirmDeleteItem={handleCloseModalConfirmDeleteItem}
      />
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Formik initialValues={{ search: '' }}>
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Itens da Nota
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Field
                    size='small'
                    name='search'
                    label='Pesquisar'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Table name='Table' size='small' headers={columnsItem} disableNumeration>
                    {filteredData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.numItem}</TableCell>
                          <TableCell>{item.ProdOri}</TableCell>
                          <TableCell>{item.ProdInt}</TableCell>
                          <TableCell>{item.desc}</TableCell>
                          <TableCell>{item.qtd}</TableCell>
                          <TableCell>{item.valorUni}</TableCell>
                          <TableCell>{item.total}</TableCell>
                          <TableCell>
                            <IconButton onClick={handleChangeToRegisterItens} color='primary'>
                              <ModeEditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton color='secondary'>
                              <HighlightOffIcon onClick={handleOpenModalConfirmDeleteItem} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    count={itensTeste.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}></Grid>
                <Grid item xs={12} md={4} lg={4}>
                  <Button onClick={() => handleBackToNf()} fullWidth color='secondary'>
                    Voltar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
