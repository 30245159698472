import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { Button, CustomAutocomplete, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

const columns = [{ label: 'Email' }, { label: 'Excluir' }];

export const EmailsConstrolSac = () => {
  const typeControls = [
    { label: 'Controle Operações', value: 0 },
    { label: 'Controle Aguardando Expedição', value: 1 },
  ];

  const [filteredEmails, setFilteredEmails] = useState([]);
  const [operation, setOperation] = useState([]);
  const [tableEmails, setTableEmail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: operations } = await providersService.findOperationsEmail();
      const { data: emails } = await providersService.searchEmailsControls();

      setOperation(operations);
      setFilteredEmails(emails);
    };
    fetchData();
  }, []);

  const handleGetTable = async (values) => {
    try {
      const { data: emails } = await providersService.findTableEmails({ values });
      if (emails.status === 200) {
        console.log(emails.result[0].emails);
        const separatedEmails = emails.result[0].emails.split(/[;]+/);

        const filteredEmails = separatedEmails.filter((email) => email.trim() !== '');

        setTableEmail(filteredEmails);
      } else {
        toast.error(emails.msg);
        setTableEmail([]);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleDeleteEmail = (email) => {
    const newTableEmails = [...tableEmails];

    const filteredEmails = newTableEmails.filter((item) => item !== email);
    setTableEmail(filteredEmails);
  };

  const handleAddEmailOnTable = (search_email) => {
    if (search_email === null) {
      toast.error('Selecione um email a ser adicionado!');
      return;
    }
    if (search_email.EMAIL && search_email.EMAIL.trim() !== '') {
      setTableEmail((prevTableEmails) => [...prevTableEmails, search_email.EMAIL]);
    } else {
      toast.error('Por favor, insira um email válido.');
    }
  };

  const handleUpdateEmails = async (values) => {
    if (!values.operation) {
      toast.error('Selecione uma operação para atualizar!');
      return;
    }
    setIsLoading(true);
    toast.info('Atualizando emails');
    try {
      const { data } = await providersService.updateEmailsSac({
        tableEmails,
        idOperation: values.operation,
      });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao atualizar emails');
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Controle de E-mails SAC - Gestão</PageTitle>

      <Formik initialValues={{ type_control: '', operation: '', search_email: '' }}>
        {({ values, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_control'
                  label='Tipo de Controle'
                  variant='outlined'
                  options={typeControls}
                  component={Select}
                  onChange={(values) => handleGetTable({ type_control: values })}
                  required
                />
              </Grid>
              {values.type_control === 0 && (
                <Grid item xs={12} sm={4} md={4} lg={2}>
                  <Field
                    size='small'
                    name='operation'
                    label='Operação'
                    variant='outlined'
                    options={operation}
                    component={Select}
                    onChange={(values) => handleGetTable({ operation: values })}
                    required
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <CustomAutocomplete
                  name='search_email'
                  label='Pesquisar'
                  value={values.search_email}
                  filteredValues={filteredEmails}
                  textFieldProps={{
                    fullWidth: true,
                    variant: 'outlined',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button
                  onClick={() => handleAddEmailOnTable(values.search_email)}
                  fullWidth
                  loading={isLoading}
                >
                  Adicionar
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button onClick={() => handleUpdateEmails(values)} fullWidth loading={isLoading}>
                  Atualizar
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid item xs={12} sm={4} md={4} lg={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {tableEmails &&
                    tableEmails.map((email, index) => (
                      <TableRow key={index}>
                        <TableCell>{email}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteEmail(email)}>
                            <DeleteIcon style={{ color: '#F50057' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
