import { useRouteMatch } from 'react-router';

import { consulteDoca } from 'pages/Private/Receipt/ConsultaDoca';
import { GenerateMinuteDoca } from 'pages/Private/Receipt/GenerateMinuteDoca';
import { RegisterNfDoca } from 'pages/Private/Receipt/RegisterNfDoca';

import { CollectSerialFromPdv } from '../../pages/Private/Receipt/CollectSerialFromPdv';
import { Route } from '../Route';

export const ReceiptRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/register-nf-doca`} component={RegisterNfDoca} />
      <Route isPrivate path={`${url}/generate-minute`} component={GenerateMinuteDoca} />
      <Route isPrivate path={`${url}/consulte`} component={consulteDoca} />
      <Route isPrivate path={`${url}/collect-serial-pdv`} component={CollectSerialFromPdv} />
    </>
  );
};
