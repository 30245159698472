import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

export const ModelXProductReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExportModelXProductReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const result = await providersService.getModelXProductReport();

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          ModelXProduct: worksheet,
        },
        SheetNames: ['ModelXProduct'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_ModeloXProduto${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Modelos X Produtos</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Button
            fullWidth
            onClick={handleExportModelXProductReport}
            disabled={isLoading}
            loading={isLoading}
          >
            Exportar Relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
