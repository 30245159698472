import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import TablePagination from '@mui/material/TablePagination';
import { Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { OpenOs } from './DialogOpenOsLote';

const columns = [
  { label: 'Produto Origem' },
  { label: 'Tipo Produto' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Qtd NF' },
  { label: 'Qtd OS Abertas' },
  { label: 'Qtd Faltante' },
  { label: 'Nota Fiscal' },
  { label: 'Abrir OS' },
];

export const DialogOpenServiceOrder = ({
  openModalServiceOrder,
  handleCloseDialogOrderService,
}) => {
  if (!openModalServiceOrder) return null;

  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState('');

  const dataTeste = [
    {
      idCad: 1,
      ProdOri: 'SM-ASL151',
      tipoProd: 'TELEFONE',
      marca: 'SAMSUNG',
      Modelo: 'GALAXY 21',
      qtdNf: 11,
      ativo: 1,
      qtdOsOpen: 10,
      qtdFalt: 1,
      Nota: 123,
    },
    {
      idCad: 2,
      ProdOri: 'IP-XS200',
      tipoProd: 'TELEFONE',
      marca: 'APPLE',
      Modelo: 'IPHONE XS',
      qtdNf: 8,
      ativo: 1,
      qtdOsOpen: 5,
      qtdFalt: 3,
      Nota: 234,
    },
    {
      idCad: 3,
      ProdOri: 'SM-ASL152',
      tipoProd: 'TELEFONE',
      marca: 'SAMSUNG',
      Modelo: 'GALAXY 22',
      qtdNf: 15,
      ativo: 1,
      qtdOsOpen: 12,
      qtdFalt: 3,
      Nota: 345,
    },
    {
      idCad: 4,
      ProdOri: 'LP-Y7P400',
      tipoProd: 'NOTEBOOK',
      marca: 'LENOVO',
      Modelo: 'YOGA 7',
      qtdNf: 6,
      ativo: 1,
      qtdOsOpen: 4,
      qtdFalt: 2,
      Nota: 456,
    },
    {
      idCad: 5,
      ProdOri: 'HP-SPK750',
      tipoProd: 'IMPRESSORA',
      marca: 'HP',
      Modelo: 'DESKJET 750',
      qtdNf: 9,
      ativo: 1,
      qtdOsOpen: 7,
      qtdFalt: 2,
      Nota: 567,
    },
    {
      idCad: 6,
      ProdOri: 'SM-ASL153',
      tipoProd: 'TELEFONE',
      marca: 'SAMSUNG',
      Modelo: 'GALAXY 23',
      qtdNf: 13,
      ativo: 1,
      qtdOsOpen: 9,
      qtdFalt: 4,
      Nota: 678,
    },
    {
      idCad: 7,
      ProdOri: 'DE-INS500',
      tipoProd: 'NOTEBOOK',
      marca: 'DELL',
      Modelo: 'INSPIRON 5000',
      qtdNf: 10,
      ativo: 1,
      qtdOsOpen: 8,
      qtdFalt: 2,
      Nota: 789,
    },
    {
      idCad: 8,
      ProdOri: 'AC-AIP01',
      tipoProd: 'MONITOR',
      marca: 'ACER',
      Modelo: 'PREDATOR X34',
      qtdNf: 7,
      ativo: 1,
      qtdOsOpen: 3,
      qtdFalt: 4,
      Nota: 890,
    },
    {
      idCad: 9,
      ProdOri: 'LG-SCR950',
      tipoProd: 'TV',
      marca: 'LG',
      Modelo: 'OLED 55',
      qtdNf: 5,
      ativo: 1,
      qtdOsOpen: 2,
      qtdFalt: 3,
      Nota: 901,
    },
    {
      idCad: 10,
      ProdOri: 'SM-ASL154',
      tipoProd: 'TELEFONE',
      marca: 'SAMSUNG',
      Modelo: 'GALAXY 24',
      qtdNf: 14,
      ativo: 1,
      qtdOsOpen: 11,
      qtdFalt: 3,
      Nota: 1011,
    },
  ];

  const handleBack = () => {
    setTabIndex(0);
  };

  const handleChangeToOpenOs = () => {
    setTabIndex(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Função para filtrar os dados da tabela com base no valor de pesquisa
  const filteredData = dataTeste.filter((item) => {
    return (
      item.ProdOri.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.tipoProd.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.marca.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.Modelo.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.qtdNf.toString().includes(searchQuery) ||
      item.qtdOsOpen.toString().includes(searchQuery) ||
      item.qtdFalt.toString().includes(searchQuery) ||
      item.Nota.toString().includes(searchQuery)
    );
  });

  return (
    <>
      <Dialog maxWidth={'lg'} open={openModalServiceOrder}>
        <Dialog.Title onClose={handleCloseDialogOrderService}>Abertura de OS</Dialog.Title>
        <Dialog.Content>
          {tabIndex === 0 && (
            <>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Lote: 123456
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Fornecedor: CENTER CELL COM E SERVICOS SOROCABA LTDA
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Formik initialValues={{ search: '' }}>
                  {(props) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            size='small'
                            name='search'
                            label='Pesquisar'
                            component={Text}
                            variant='outlined'
                            type='text'
                            fullWidth
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Table name='Table' size='small' headers={columns} disableNumeration>
                            {filteredData
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.ProdOri}</TableCell>
                                  <TableCell>{item.tipoProd}</TableCell>
                                  <TableCell>{item.marca}</TableCell>
                                  <TableCell>{item.Modelo}</TableCell>
                                  <TableCell>{item.qtdNf}</TableCell>
                                  <TableCell>{item.qtdOsOpen}</TableCell>
                                  <TableCell>{item.qtdFalt}</TableCell>
                                  <TableCell>{item.Nota}</TableCell>
                                  <TableCell>
                                    <IconButton color='primary'>
                                      <AddCircleIcon onClick={() => handleChangeToOpenOs()} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component='div'
                            count={filteredData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <Box mt={2} component={Paper} boxShadow={3} p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Lote: 123456
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                      Fornecedor: CENTER CELL COM E SERVICOS SOROCABA LTDA
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <OpenOs handleBack={handleBack} />
            </>
          )}
        </Dialog.Content>
      </Dialog>
    </>
  );
};
