import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const ProductionByTechnicianReport = () => {
  const operations = [
    { label: 'TODOS', value: 0 },
    { label: 'E-COMMERCE', value: 7 },
    { label: 'NÃO SERIALIZADO', value: 20 },
    { label: 'RFB CENTER CELL', value: 10 },
    { label: 'RFB FURBTECH SOR', value: 21 },
    { label: 'RFB SAMSUNG', value: 9 },
    { label: 'RFB TECH CENTER', value: 19 },
  ];

  const typesProduction = [
    { label: 'Produção Efetiva', value: 0 },
    { label: 'Produção Real', value: 1 },
  ];

  const [loading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde relatório sendo gerado...');
    try {
      const { data } = await providersService.productionByTechnicianReport({ values });
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadcsv(`Relatório_Producao_por_tecnico${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Produção Por Técnico</PageTitle>

      <Formik
        initialValues={{
          operation: 0,
          type_production: 0,
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <Field
                  size='small'
                  variant='outlined'
                  component={Select}
                  name='type_production'
                  label='Tipo de Produção'
                  options={typesProduction}
                />
              </Grid>
              {props.values.type_production === 0 && (
                <>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Field
                      size='small'
                      variant='outlined'
                      component={Select}
                      name='operation'
                      label='Operação'
                      options={operations}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Inicio'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <Button type='submit' fullWidth loading={loading}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
