import { React, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { cashierService } from 'services/cashier';
import XLSX from 'xlsx';

import { CardTemplate } from './card';
import { Results } from './results';
import { useStyles } from './styles';
import { validationSchema } from './validation';

export const MarketPrice = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isCardRender, setIsCardRender] = useState(false);
  const [productSearchData, setProductSearchData] = useState({});
  const [newSearch, setNewSearch] = useState(true);
  const [showImportButton, setShowImportButton] = useState(false);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    const search = String(values.search_product).trim();

    try {
      setLoading(true);
      const { data } = await cashierService.getRealTimeProductSearch(search);
      setProductSearchData(data);
      setIsCardRender(true);
      setNewSearch(false);
      setLoading(false);

      toast.success('Pesquisa realizada com sucesso');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ocorreu um erro, tente novamente mais tarde');
      setLoading(false);
    }
  };

  const resetForm = () => {
    location.reload();
  };

  const handleDownloadLayout = async () => {
    setShowImportButton(true);
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet([{ S: 'Descricao do produto' }], {
      skipHeader: 1,
    });

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'LayoutParaPesquisarProdutos.xlsx',
    );
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const arrSearch = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              description: obj.split(';')[0],
            };

            return dados;
          }
        });

        setLoading(true);

        const { data } = await cashierService.getRealTimeProductSearchByFile(arrSearch);

        setLoading(false);

        const xlsxData = data.map((item) => {
          const { descricao, prices } = item;
          return { descricao, ...prices };
        });

        const now = new Date();
        const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(xlsxData));
        const workbook = (XLSX.WorkBook = {
          Sheets: {
            mySheet: worksheet,
          },
          SheetNames: ['mySheet'],
        });

        XLSX.writeFile(workbook, `ResultadoPesquisaPreco${now}.xlsx`);
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <PageTitle>Consultar preço</PageTitle>
      <Formik
        initialValues={{ search_product: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form>
              <Paper title='Informações do aparelho' elevation={5} variant='elevation'>
                <Grid container spacing={3} className={classes.paper}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Field
                      variant='outlined'
                      name='search_product'
                      label='Pesquisar produto'
                      size='small'
                      component={Text}
                      loading={loading}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {!showImportButton ? (
                      <Button
                        loading={loading}
                        color='primary'
                        variant='outlined'
                        fullWidth
                        onClick={handleDownloadLayout}
                        startIcon={<DownloadIcon />}
                      >
                        Baixar modelo para importação
                      </Button>
                    ) : (
                      <Button
                        loading={loading}
                        color='primary'
                        variant='outlined'
                        fullWidth
                        onClick={handleImportClick}
                        startIcon={<FileUploadIcon />}
                      >
                        Importar CSV
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              <Paper elevation={5} variant='elevation'>
                <Grid container spacing={3} className={classes.paper}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      loading={loading}
                      disabled={newSearch}
                      onClick={resetForm}
                      color='secondary'
                      variant='contained'
                      fullWidth
                    >
                      Nova pesquisa
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      disabled={!props.dirty || !props.isValid || !newSearch}
                      type='submit'
                      color='primary'
                      variant='contained'
                      fullWidth
                    >
                      Consultar preço
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Spacer size={15} />
              {isCardRender ? <PageTitle>Resultados da sua pesquisa:</PageTitle> : <></>}
            </Form>
          </>
        )}
      </Formik>
      {isCardRender ? (
        <>
          <Results productSearchData={productSearchData} />
          <Spacer size={20} />
          <Grid container spacing={3} alignItems='center'>
            {productSearchData?.searchProduct?.map((item) => (
              <Grid key={item.id} item xs={6} sm={4} md={4} lg={2}>
                <CardTemplate image={item.image} title={item.title} allInformation={item} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
