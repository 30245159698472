import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { providersService } from 'services';

export const DialogUpdateComplaint = ({ openDialogUpdate, handleCloseDialogUpdate, dataLinha }) => {
  if (!openDialogUpdate) {
    return null;
  }

  const [typeProduct, setTypeProduct] = useState([{ label: '', value: '' }]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    const { data: Products } = await providersService.findTypeProduct();
    setTypeProduct(Products);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateComplaint = async (values) => {
    try {
      const { data } = await providersService.updateComplaint({
        values: values,
        idCadastro: dataLinha.id_cadastro,
      });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        handleCloseDialogUpdate();
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao alterar reclamação');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth={'md'} open={openDialogUpdate}>
        <Dialog.Title onClose={handleCloseDialogUpdate}>Alterar Reclamação</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ complaint: dataLinha.descricao, type_product: [] }}
            onSubmit={handleUpdateComplaint}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={6} lg={6}>
                    <Field
                      size='small'
                      name='complaint'
                      fullWidth
                      label='Reclamação'
                      variant='outlined'
                      component={Text}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={6} lg={6}>
                    <Field
                      size='small'
                      variant='outlined'
                      component={Select}
                      name='type_product'
                      label='Tipo do Produto'
                      options={typeProduct}
                      multiple
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={8} lg={2}></Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4}>
                    <Button
                      onClick={handleCloseDialogUpdate}
                      fullWidth
                      color='secondary'
                      loading={isLoading}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} loading={isLoading}>
                    <Button type='submit' fullWidth>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
