import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

export const DialogConfirmDeleteItem = ({
  openModalConfirmDeleteItem,
  handleCloseModalConfirmDeleteItem,
}) => {
  return (
    <>
      <Dialog open={openModalConfirmDeleteItem}>
        <Dialog.Title onClose={handleCloseModalConfirmDeleteItem}>Confirmar exclusão</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }} align='center'>
            Realmente deseja excuir esse item?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}></Grid>
            <Grid item xs={12} lg={3}>
              <Button onClick={() => handleCloseModalConfirmDeleteItem()} color='secondary'>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button>Confirmar</Button>
            </Grid>
            <Grid item xs={12} lg={3}></Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
