import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { DialogRegisterParts } from './Dialog/DialogRegisterParts';

const columns = [
  { label: 'Produto' },
  { label: 'Tipo Produto' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Cor' },
  { label: 'Editar' },
  { label: 'Ativar/Desativar' },
];

export const RegisterParts = () => {
  const [openDialogRegister, setOpenDialogRegister] = useState(false);
  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
  };

  return (
    <>
      <DialogRegisterParts
        openDialogRegister={openDialogRegister}
        handleCloseDialogRegister={handleCloseDialogRegister}
      />
      <PageTitle>Cadastro de Peças</PageTitle>

      <Formik initialValues={{ input_name: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Button onClick={() => handleOpenDialogRegister()} fullWidth>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={6}></Grid>
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <Field
                  size='small'
                  name='search'
                  variant='outlined'
                  fullWidth
                  label='Pesquisar'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  <TableRow>
                    <TableCell>Peça 1</TableCell>
                    <TableCell>TIPO PRODUTO Peça 1</TableCell>
                    <TableCell>MARCA Peça 1</TableCell>
                    <TableCell>MODELO Peça 1</TableCell>
                    <TableCell>COR Peça 1</TableCell>
                    <TableCell>
                      <Button>Editar</Button>
                    </TableCell>
                    <TableCell>
                      <Button color='secondary'>Desativar</Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
