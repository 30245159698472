import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { PageTitle } from 'pages/Layout/PageTitle';

import { formatDate } from '../../../../helpers/formateDate';
import { providersService } from '../../../../services';

export const PhotoLoteReport = () => {
  const typesReport = [
    { label: 'Atendimento', value: 0 },
    { label: 'Marca', value: 1 },
    { label: 'Fornecedor', value: 2 },
    { label: 'NF', value: 3 },
    { label: 'Categoria', value: 4 },
    { label: 'Tipo de Produto', value: 5 },
    { label: 'Data Lote', value: 6 },
  ];

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [brands, setBrands] = useState([]);
  const [service, setService] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const now = new Date();

  useEffect(() => {
    const fetchData = async () => {
      const { data: service } = await providersService.findTypeService();
      const { data: category } = await providersService.findCategory();
      const { data: products } = await providersService.findTypeProduct();
      const { data: brands } = await providersService.getBrand();
      setService(service);
      setCategory(category);
      setProducts(products);
      setBrands(brands);
    };

    fetchData();
  }, []);

  const handleExport = async (values) => {
    console.log(values);
    toast.info('Aguarde relatório sendo gerado...');
    setIsloading(true);

    try {
      const { data } = await providersService.photoLoteReport({ values });
      const result = data.result;
      const date = await formatDate(now);
      if (data.status === 200) {
        toast.success(data.msg);
        await downloadXlsx(`Relatório_Foto_Lote${date}`, result);
        setIsloading(false);
      } else {
        toast.error(data.msg);
        setIsloading(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao gerar relatório');
      setIsloading(false);
    }
  };

  const renderAdditionalFields = (typeReport) => {
    switch (typeReport) {
      case 0:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='service'
              label='Atendimento'
              variant='outlined'
              options={service}
              component={Select}
              required
            />
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='brand'
              label='Marca'
              variant='outlined'
              options={brands}
              component={Select}
              required
            />
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='supplier'
              label='Fornecedor'
              variant='outlined'
              component={Text}
              required
            />
          </Grid>
        );
      case 3:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='nf'
              label='Nota Fiscal'
              variant='outlined'
              component={Text}
              required
            />
          </Grid>
        );
      case 4:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='category'
              label='Categoria'
              variant='outlined'
              options={category}
              component={Select}
              required
            />
          </Grid>
        );
      case 5:
        return (
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <Field
              size='small'
              name='type_product'
              label='Tipo de Produto'
              variant='outlined'
              options={products}
              component={Select}
              required
            />
          </Grid>
        );
      case 6:
        return (
          <>
            <Grid item xs={12} sm={4} md={4} lg={2}>
              <Field
                size='small'
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data inicial'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2}>
              <Field
                size='small'
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data final'
                component={DatePicker}
              />
            </Grid>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <PageTitle>Foto Lote</PageTitle>
      <Formik
        initialValues={{
          type_report: 0,
          service: '',
          brand: '',
          category: '',
          nf: '',
          supplier: '',
          type_product: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo de Relatório'
                  variant='outlined'
                  options={typesReport}
                  component={Select}
                  required
                />
              </Grid>
              {renderAdditionalFields(props.values.type_report)}
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Extrair Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
