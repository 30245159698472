import { useContext } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { NewServiceContext } from '../..';
import { validationSchema } from './validationSchema';

export function ClientForm() {
  const { setActiveStep } = useContext(NewServiceContext);

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  async function handleSubmit(values, { setSubmitting }) {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setActiveStep(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao cadastrar o cliente');
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageTitle>Dados pessoais do cliente</PageTitle>

      <Formik
        initialValues={{
          name: '',
          email: '',
          cgc: '',
          phone_number: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  label='CPF'
                  name='cgc'
                  size='small'
                  variant='outlined'
                  mask='document'
                  fullWidth
                  component={Text}
                  onInput={toTrim}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  label='Nome'
                  name='name'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  label='E-mail'
                  name='email'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  onInput={toTrim}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  label='Celular'
                  name='phone_number'
                  size='small'
                  variant='outlined'
                  mask='phone'
                  fullWidth
                  component={Text}
                  onInput={toTrim}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  type='submit'
                  endIcon={<ArrowForwardIcon />}
                  fullWidth
                  disabled={!props.isValid || !props.dirty || props.isSubmitting}
                  loading={props.isSubmitting}
                >
                  Próximo
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
