import { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { DatePicker, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { accountService, stockSaleService } from 'services';

import { UnlockDialog } from '../UnlockDialog';

const columns = [
  { label: 'idBloqueio' },
  { label: 'valorBloqueado' },
  { label: 'descTipoBloqueio' },
  { label: 'dtBloqueio' },
  { label: 'bloqueadoPor' },
  { label: 'obsBloqueio' },
  { label: 'ação' },
];

export const ConsultBlockServiceOrdem = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataButton, setDataButton] = useState({});
  const [getInfo, setInfo] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [roleData, , loadingRoleData, refetchRole] = useQuery(
    () => accountService.listUserRoles(),
    [],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlock = (dataRow) => {
    setOpen(true);
    setDataButton(dataRow);
  };

  const handleSubmite = async (event) => {
    setInfo(event);
    setIsLoading(true);
    toast.info('Gerando os dados aguarde ...');
    try {
      const { data } = await stockSaleService.block_order_service_consult(event);
      setDataTable(data);
      toast.success('Dados gerado com sucesso.');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ocorreu um erro ao processar a solicitação.');
    }
    setIsLoading(false);
  };

  return (
    <>
      <UnlockDialog
        open={open}
        handleClose={handleClose}
        dataButton={dataButton}
        handleLoadTable={handleSubmite}
        getInfo={getInfo}
      />
      <Formik
        initialValues={{
          textField: '',
          data_type_init: new Date(),
          data_type_end: new Date(),
        }}
        onSubmit={handleSubmite}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  name='textField'
                  component={Text}
                  label='Digite IMEI/SERIAL ou NF: '
                  fullWidth
                  variant='outlined'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  name='data_type_init'
                  label='Data inicial'
                  inputVariant='outlined'
                  component={DatePicker}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  name='data_type_end'
                  label='Data final'
                  inputVariant='outlined'
                  component={DatePicker}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Button
                  disabled={isLoading}
                  fullWidth
                  color='primary'
                  variant='contained'
                  type='submit'
                >
                  consultar
                </Button>
              </Grid>
            </Grid>
            {dataTable && dataTable.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingRoleData}
                    emptyMessage='Nenhum registro encontrado.'
                    data={roleData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {dataTable?.map((role) => (
                      <TableRow key={role?.idBloqueio}>
                        <TableCell>{role?.idBloqueio}</TableCell>
                        <TableCell>{role?.valorBloqueado}</TableCell>
                        <TableCell>{role?.descTipoBloqueio}</TableCell>
                        <TableCell>{role?.dtBloqueio}</TableCell>
                        <TableCell>{role?.bloqueadoPor}</TableCell>
                        <TableCell>{role?.obsBloqueio}</TableCell>
                        <TableCell align='center'>
                          <Button
                            disabled={isLoading}
                            fullWidth
                            color='secondary'
                            variant='contained'
                            onClick={() => handleUnlock(role)}
                          >
                            desbloquear
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
