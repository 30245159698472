import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ input_new_operation: '' }}>
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_new_operation'
                      variant='outlined'
                      fullWidth
                      label='Operação'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button fullWidth loading={isLoading}>
                      Gravar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
