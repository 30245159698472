import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import { Button, Select, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogDeleteFilter } from './Dialogs/DialogDeleteFilter';
import { DialogEditFilter } from './Dialogs/DialogEditFilter';

const columns = [
  { label: 'Data de Cadastro' },
  { label: 'Apelido' },
  { label: 'Criador' },
  { label: 'Editar' },
  { label: 'Excluir' },
];

export const ManagementFilters = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [operation, setOperation] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categoryProd, setCategoryProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [process, setProcess] = useState([]);
  const [service, setService] = useState([]);
  const [stages, setStages] = useState([]);
  const [table, setTable] = useState([]);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  //   const [openDialogView, setOpenDialogView] = useState(false);
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(false);

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const fetchData = async () => {
    const { data: operations } = await providersService.getOperacao();
    const { data: brands } = await providersService.getBrand();
    const { data: categotryProd } = await providersService.findCategotryProdByMagementFilters();
    const { data: products } = await providersService.findProductsByMagementFilters();
    const { data: process } = await providersService.findProcess();
    const { data: services } = await providersService.findTypeServiceByMagementFilters();
    const { data: stages } = await providersService.findStagesAging();
    const { data: table } = await providersService.findTableCustomFilters();

    const allOperations = [{ label: 'TODOS', value: 0 }, ...operations];
    const allCategories = [{ label: 'TODOS', value: 0 }, ...categotryProd];
    const allProducts = [{ label: 'TODOS', value: 0 }, ...products];
    const allServices = [{ label: 'TODOS', value: 0 }, ...services];
    const allProcess = [{ label: 'TODOS', value: 0 }, ...process];
    const allBrands = [{ label: 'TODOS', value: 0 }, ...brands];
    const allStages = [{ label: 'TODOS', value: 0 }, ...stages];

    setOperation(allOperations);
    setBrands(allBrands);
    setCategoryProducts(allCategories);
    setProducts(allProducts);
    setProcess(allProcess);
    setService(allServices);
    setStages(allStages);
    setTable(table);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRegisterNewCustomFilter = async (values) => {
    toast.info('Registrando novo filtro...');
    setLoading(true);
    try {
      const { data } = await providersService.registerNewCustomFilter({ values, user: user.id });
      if (data.status === 200) {
        toast.success(data.msg);
        setLoading(false);
        fetchData();
      } else {
        toast.error(data.msg);
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleReset = (resetForm) => {
    resetForm();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setItem(item);
  };
  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const handleOpenDialogDelete = (item) => {
    setOpenDialogDelete(true);
    setItem(item);
  };
  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    fetchData();
  };

  //   const handleOpenDialogView = (item) => {
  //     setOpenDialogView(true);
  //     setItem(item);
  //   };
  //   const handleCloseDialogView = () => {
  //     setOpenDialogView(false);
  //   };

  return (
    <>
      <DialogEditFilter
        openDialogEdit={openDialogEdit}
        handleCloseDialogEdit={handleCloseDialogEdit}
        item={item}
      />
      <DialogDeleteFilter
        openDialogDelete={openDialogDelete}
        handleCloseDialogDelete={handleCloseDialogDelete}
        item={item}
      />
      <PageTitle>Gerenciamento de Filtros</PageTitle>

      <Formik
        initialValues={{
          operation: [],
          product_category: [],
          brands: [],
          type_product: [],
          process: [],
          type_service: [],
          stages: [],
          nickname: '',
        }}
        onSubmit={handleRegisterNewCustomFilter}
      >
        {({ resetForm, ...props }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='operation'
                  label='Operação'
                  variant='outlined'
                  options={operation.map((option) => ({
                    ...option,
                    disabled: props.values.operation.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='product_category'
                  label='Categoria do Produto'
                  variant='outlined'
                  options={categoryProd.map((option) => ({
                    ...option,
                    disabled: props.values.product_category.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='brands'
                  label='Marca'
                  variant='outlined'
                  options={brands.map((option) => ({
                    ...option,
                    disabled: props.values.brands.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='type_product'
                  label='Tipo de Produto'
                  variant='outlined'
                  options={products.map((option) => ({
                    ...option,
                    disabled: props.values.type_product.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='process'
                  label='Processo'
                  variant='outlined'
                  options={process.map((option) => ({
                    ...option,
                    disabled: props.values.process.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='type_service'
                  label='Tipo de Atendimento'
                  variant='outlined'
                  options={service.map((option) => ({
                    ...option,
                    disabled: props.values.type_service.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='stages'
                  label='Etapas'
                  variant='outlined'
                  options={stages.map((option) => ({
                    ...option,
                    disabled: props.values.stages.includes(0) && option.value !== 0,
                  }))}
                  component={Select}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Field
                  size='small'
                  name='nickname'
                  label='Apelido'
                  variant='outlined'
                  component={Text}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button type='submit' fullWidth loading={loading}>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Button
                  color='secondary'
                  type='reset'
                  fullWidth
                  onClick={() => handleReset(resetForm)}
                  loading={loading}
                >
                  Limpar
                </Button>
              </Grid>
            </Grid>
            <Box mt={3}>
              <Typography variant='h1' style={{ fontSize: '25px', fontWeight: 'bold' }}>
                Filtros Cadastrados:
              </Typography>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {table
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{formatDate(item.dt_cadastro)}</TableCell>
                        <TableCell>{item.apelido}</TableCell>
                        <TableCell>{item.userGaia}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpenDialogEdit(item)}>
                            <EditIcon style={{ color: '#512DA8' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOpenDialogDelete(item)}>
                            <DeleteIcon style={{ color: '#F50057' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={table.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
