import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import { Button, Dialog, Table } from 'components';

const columns = [
  { label: 'Nota' },
  { label: 'Série' },
  { label: 'Qtd Total' },
  { label: 'Valor Total' },
];

export const DialogViewNfLote = ({ openDialogViewNfs, handleCloseDialogViewNfs, linhaInfos }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  if (!openDialogViewNfs) return null;

  const dataTest = [
    { nota: 123, serie: 25, qtdTotal: 5, vlrTotal: 9999 },
    { nota: 23, serie: 1, qtdTotal: 2, vlrTotal: 9999 },
    { nota: 12, serie: 2, qtdTotal: 14, vlrTotal: 9999 },
    { nota: 45, serie: 51, qtdTotal: 12, vlrTotal: 9999 },
    { nota: 31, serie: 3, qtdTotal: 1, vlrTotal: 9999 },
    { nota: 887, serie: 5, qtdTotal: 12, vlrTotal: 9999 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = dataTest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <Dialog open={openDialogViewNfs}>
        <Dialog.Title onClose={handleCloseDialogViewNfs}>
          Notas Fiscais do Lote {linhaInfos.Lote}
        </Dialog.Title>
        <Dialog.Content>
          <Table name='Table' headers={columns} disableNumeration>
            {paginatedData.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.nota}</TableCell>
                <TableCell>{item.serie}</TableCell>
                <TableCell>{item.qtdTotal}</TableCell>
                <TableCell>{item.vlrTotal}</TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={dataTest.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button fullWidth onClick={handleCloseDialogViewNfs}>
                Fechar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
