import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { downloadcsv } from '../../../../helpers/downloadcsv';
// import { formatDate } from '../../../../helpers/formateDate';
import { macroSector } from '../../../../helpers/macroSector';

const typeSelect = [
  { label: 'Resumido', value: '0' },
  { label: 'Detalhado', value: '1' },
];
const TypeSetorMacro = macroSector();

export const SerializedStock = () => {
  const [typeValue, setTypeValue] = useState('0');
  const [setorMacroValue, setSetorMacroValue] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();

  const handleDownloadSerializedStockReport = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setSubmitting(true);
    toast.info('Aguardando relatório');
    try {
      const { data } = await providersService.getSerializedStock(typeValue, setorMacroValue);
      await downloadcsv(`RelatorioEstoque$Serialzado${now}`, data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório de Estoque Serializado</PageTitle>

      <Formik
        initialValues={{
          data_type: typeValue,
          setor_type: setorMacroValue,
        }}
        onSubmit={handleDownloadSerializedStockReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='data_type'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='setor_type'
                  label='Setor Macro'
                  variant='outlined'
                  options={TypeSetorMacro}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button disabled={isLoading} type='submit' fullWidth loading={props.isSubmitting}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setTypeValue(props.values.data_type);
              setSetorMacroValue(props.values.setor_type);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
