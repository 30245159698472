import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { AuthProvider } from 'hooks/useAuth';
import Routes from 'routes';
import { browserHistory } from 'routes/browserHistory';
import { theme } from 'theme';

export function App() {
  const prod = 'dev';

  console.log('Não há nada para ver aqui.');

  if (prod == 'PROD') {
    console.log = console.warn = console.warn = () => {};
  } else {
    console.log('');
  }

  if (prod == 'PROD') {
    console.error = console.warn = console.warn = () => {};
  }

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
}
