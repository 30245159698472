import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const RequestReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(0);

  const handleExportRequestReport = async (valeus, { setSubmitting }) => {
    setSubmitting(true);
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const formattedInputDate = inputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const formattedOutputDate = outputData.toLocaleDateString().replace('/', '-').replace('/', '-');
    const now = new Date().toLocaleDateString();

    try {
      const result = await providersService.getRequestReport(
        formattedInputDate,
        formattedOutputDate,
      );

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          RequestReport: worksheet,
        },
        SheetNames: ['RequestReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Requisições${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
      setSubmitting(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório de Requisições</PageTitle>

      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleExportRequestReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={isLoading == 1}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='outlined'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={isLoading == 1}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={isLoading == 1}
                  loading={props.isSubmitting}
                  type='submit'
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
