import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  cep: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  street_number: yup.string().required(),
  zipcode: yup.string().required(),
  street: yup.string().required(),
});
