import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';
import { DialogRegister } from './Dialog/DialogRegister';

const columns = [
  { label: 'Produto' },
  { label: 'Tipo Produto' },
  { label: 'Marca' },
  { label: 'Modelo' },
  { label: 'Cor' },
  { label: 'Alterar' },
  { label: 'Excluir' },
];

export const RegisterProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogRegister, setOpenDialogRegister] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
  };

  const handleOpenDialogEdit = () => {
    setOpenDialogEdit(true);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };

  const handleOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };
  return (
    <>
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
      />
      <DialogRegister
        handleCloseDialogRegister={handleCloseDialogRegister}
        openDialogRegister={openDialogRegister}
      />
      <DialogEdit handleCloseDialogEdit={handleCloseDialogEdit} openDialogEdit={openDialogEdit} />
      <PageTitle>Cadastro de Produtos</PageTitle>
      <Formik initialValues={{ search: '' }}>
        {(props) => (
          <Form>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={3} md={3} lg={3}>
                <Button fullWidth loading={isLoading} onClick={handleOpenDialogRegister}>
                  Cadastrar
                </Button>
              </Grid>
              <Grid item xs={4} style={{ marginLeft: 'auto' }}>
                <Field
                  variant='outlined'
                  name='search'
                  label='Pesquisar'
                  size='small'
                  component={Text}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Table name='Table' headers={columns} maxHeight disableNumeration>
                <TableRow>
                  <TableCell>Produto 1</TableCell>
                  <TableCell>Tipo Produto</TableCell>
                  <TableCell>Marca</TableCell>
                  <TableCell>Modelo</TableCell>
                  <TableCell>Cor</TableCell>
                  <TableCell>
                    <EditIcon
                      style={{ color: '#512da8', cursor: 'pointer' }}
                      fullWidth
                      onClick={handleOpenDialogEdit}
                    />
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      style={{ color: '#f50057', cursor: 'pointer' }}
                      fullWidth
                      onClick={handleOpenDialogConfirm}
                    />
                  </TableCell>
                </TableRow>
              </Table>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
