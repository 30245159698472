import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { providersService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogEditFilter = ({ openDialogEdit, handleCloseDialogEdit, item }) => {
  if (!openDialogEdit) {
    return null;
  }

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [operation, setOperation] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categoryProd, setCategoryProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [process, setProcess] = useState([]);
  const [service, setService] = useState([]);
  const [stages, setStages] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const fetchData = async () => {
    const { data: operations } = await providersService.getOperacao();
    const { data: brands } = await providersService.getBrand();
    const { data: categotryProd } = await providersService.findCategotryProdByMagementFilters();
    const { data: products } = await providersService.findProductsByMagementFilters();
    const { data: process } = await providersService.findProcess();
    const { data: services } = await providersService.findTypeServiceByMagementFilters();
    const { data: stages } = await providersService.findStagesAging();

    const allOperations = [{ label: 'TODOS', value: 0 }, ...operations];
    const allCategories = [{ label: 'TODOS', value: 0 }, ...categotryProd];
    const allProducts = [{ label: 'TODOS', value: 0 }, ...products];
    const allServices = [{ label: 'TODOS', value: 0 }, ...services];
    const allProcess = [{ label: 'TODOS', value: 0 }, ...process];
    const allBrands = [{ label: 'TODOS', value: 0 }, ...brands];
    const allStages = [{ label: 'TODOS', value: 0 }, ...stages];

    setOperation(allOperations);
    setBrands(allBrands);
    setCategoryProducts(allCategories);
    setProducts(allProducts);
    setProcess(allProcess);
    setService(allServices);
    setStages(allStages);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateFilter = async (values) => {
    setIsLoading(true);
    toast.info('Atualizando filtro...');
    try {
      const { data } = await providersService.updateCustomFilter({
        values,
        user: user.id,
        idCadastro: item.id_cadastro,
      });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        handleCloseDialogEdit();
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar filtro');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog maxWidth={'lg'} open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Editar Filtro</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              operation: item.operacoes === 'todos' ? [0] : item.operacoes.split(',').map(Number),
              product_category:
                item.categ_produtos === 'todos' ? [0] : item.categ_produtos.split(',').map(Number),
              brands: item.marcas === 'todos' ? [0] : item.marcas.split(',').map(Number),
              type_product: item.produtos === 'todos' ? [0] : item.produtos.split(',').map(Number),
              type_process:
                item.processos === 'todos' ? [0] : item.processos.split(',').map(Number),
              type_service:
                item.tipo_atendimento === 'todos'
                  ? [0]
                  : item.tipo_atendimento.split(',').map(Number),
              stages: item.etapas === 'todos' ? [0] : item.etapas.split(',').map(Number),
              nickname: item.apelido,
            }}
            onSubmit={handleUpdateFilter}
          >
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='operation'
                      label='Operação'
                      variant='outlined'
                      options={operation.map((option) => ({
                        ...option,
                        disabled: props.values.operation.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='product_category'
                      label='Categoria Produto'
                      variant='outlined'
                      options={categoryProd.map((option) => ({
                        ...option,
                        disabled: props.values.product_category.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='brands'
                      label='Marca'
                      variant='outlined'
                      options={brands.map((option) => ({
                        ...option,
                        disabled: props.values.brands.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='type_product'
                      label='Tipo de Produto'
                      variant='outlined'
                      options={products.map((option) => ({
                        ...option,
                        disabled: props.values.type_product.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='type_process'
                      label='Processo'
                      variant='outlined'
                      options={process.map((option) => ({
                        ...option,
                        disabled: props.values.type_process.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='type_service'
                      label='Tipo de Serviço'
                      variant='outlined'
                      options={service.map((option) => ({
                        ...option,
                        disabled: props.values.type_service.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='stages'
                      label='Etapas'
                      variant='outlined'
                      options={stages.map((option) => ({
                        ...option,
                        disabled: props.values.stages.includes(0) && option.value !== 0,
                      }))}
                      component={Select}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Field
                      size='small'
                      name='nickname'
                      label='Apelido'
                      variant='outlined'
                      component={Text}
                      multiple
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={3}></Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Button onClick={handleCloseDialogEdit} color='secondary' fullWidth>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
