import { useRouteMatch } from 'react-router';

import { AgingFinalizedScrapReport } from 'pages/Private/Reports/AgingFinalizedScrapReport';
import { AgingReport } from 'pages/Private/Reports/AgingReport';
import { BillingByOsReport } from 'pages/Private/Reports/BillingByOsReport';
import { BranchBalance } from 'pages/Private/Reports/BranchBalance';
import { ClosedOsReport } from 'pages/Private/Reports/ClosedOsReport';
import { CompletedOrders } from 'pages/Private/Reports/CompletedOrders';
import { ComponentsRegisterReport } from 'pages/Private/Reports/ComponentsRegisterReport';
import { ControlSacMangementReport } from 'pages/Private/Reports/ControlSacMangementReport';
import { DefectXComponentReport } from 'pages/Private/Reports/DefectXComponentReport';
import { DetailedPartsConsumptionReport } from 'pages/Private/Reports/DetailedPartsConsumptionReport';
import { EmailsConstrolSac } from 'pages/Private/Reports/emailsControlsSac';
import { ExpressReport } from 'pages/Private/Reports/expressReport';
import { FutureBounce } from 'pages/Private/Reports/FutureBounce';
import { LotManagementReport } from 'pages/Private/Reports/LotManagementReport';
import { LotNfReceiptReport } from 'pages/Private/Reports/LotNfReceiptReport';
import { LotpendingConfirmationReport } from 'pages/Private/Reports/LotpendingConfirmationReport';
import { ManagementFilters } from 'pages/Private/Reports/managementFilters';
import { ModelXProductReport } from 'pages/Private/Reports/ModelsXProductReport';
import { NfEntryReport } from 'pages/Private/Reports/NfEntryReport';
import { NonComplianceReport } from 'pages/Private/Reports/NonComplianceReport';
import { OpenLot } from 'pages/Private/Reports/OpenLot';
import { OpenServiceOrderReport } from 'pages/Private/Reports/OpenServiceOrderReport';
import { OsCmpReport } from 'pages/Private/Reports/osCmpReport';
import { PartsXModelsReport } from 'pages/Private/Reports/PartsXModelsReport';
import { PhotoLoteReport } from 'pages/Private/Reports/photoLoteReport';
import { PnXModeloReport } from 'pages/Private/Reports/PnXModeloReport';
import { ProductionByTechnicianReport } from 'pages/Private/Reports/ProductionByTechnicianReport';
import { ProductionRegisterReport } from 'pages/Private/Reports/ProductionRegisterReport';
import { ProductionSectorReport } from 'pages/Private/Reports/ProductionSectorReport';
import { ProductsNoSkuReport } from 'pages/Private/Reports/ProductsNoSkuReport';
import { ProductSerilialized } from 'pages/Private/Reports/ProductsSerializedReport';
import { ProductsXAtribuctsReport } from 'pages/Private/Reports/ProductsXAtributsReport';
import { ReceiptReport } from 'pages/Private/Reports/ReceiptReport';
import { RegisterComplaints } from 'pages/Private/Reports/RegisterComplaints';
import { RegisterProductReport } from 'pages/Private/Reports/RegisterProductReport';
import { RequestReport } from 'pages/Private/Reports/RequestReport';
import { RetroactiveBounce } from 'pages/Private/Reports/RetroactiveBounce';
import { SacManagementReport } from 'pages/Private/Reports/SacManagementReport';
import { SaleByPeriodReport } from 'pages/Private/Reports/SaleByPeriodReport';
import { ScrapReport } from 'pages/Private/Reports/ScrapReport';
import { SerializedStock } from 'pages/Private/Reports/SerializedStock';
import { StockPositionEcommerceReport } from 'pages/Private/Reports/StockPositionEcommerceReport';
import { StockPositionReport } from 'pages/Private/Reports/StockPositionReport';
import { TotalAverageCalculationReport } from 'pages/Private/Reports/TotalAverageCalculationReport';
import { WarrantyReport } from 'pages/Private/Reports/WarrantyReport';
import { WarrantyValidation } from 'pages/Private/Reports/WarrantyValidationReport';

import { Route } from '../Route';

export const ReportRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/receipt`} component={ReceiptReport} />
      <Route isPrivate path={`${url}/aging`} component={AgingReport} />
      <Route isPrivate path={`${url}/finalized-scrap`} component={AgingFinalizedScrapReport} />
      <Route isPrivate path={`${url}/stock-position`} component={StockPositionReport} />
      <Route isPrivate path={`${url}/production-sector`} component={ProductionSectorReport} />
      <Route isPrivate path={`${url}/open-service-order`} component={OpenServiceOrderReport} />
      <Route isPrivate path={`${url}/serialized-stock`} component={SerializedStock} />
      <Route isPrivate path={`${url}/request-report`} component={RequestReport} />
      <Route isPrivate path={`${url}/register-product-report`} component={RegisterProductReport} />
      <Route isPrivate path={`${url}/models-product-report`} component={ModelXProductReport} />
      <Route isPrivate path={`${url}/defect-component-report`} component={DefectXComponentReport} />
      <Route
        isPrivate
        path={`${url}/produtos-atributos-report`}
        component={ProductsXAtribuctsReport}
      />
      <Route
        isPrivate
        path={`${url}/production-registers-report`}
        component={ProductionRegisterReport}
      />
      <Route
        isPrivate
        path={`${url}/component-register-report`}
        component={ComponentsRegisterReport}
      />
      <Route isPrivate path={`${url}/products-no-sku-report`} component={ProductsNoSkuReport} />
      <Route isPrivate path={`${url}/pn-modelo`} component={PnXModeloReport} />
      <Route isPrivate path={`${url}/warranty-validation`} component={WarrantyValidation} />
      <Route
        isPrivate
        path={`${url}/detailed-Parts-Consumption`}
        component={DetailedPartsConsumptionReport}
      />
      <Route isPrivate path={`${url}/nf-entry-report`} component={NfEntryReport} />
      <Route
        isPrivate
        path={`${url}/stock-position-ecommerce`}
        component={StockPositionEcommerceReport}
      />
      <Route isPrivate path={`${url}/lote-nf-receipt`} component={LotNfReceiptReport} />
      <Route isPrivate path={`${url}/os-cmp-report`} component={OsCmpReport} />
      <Route isPrivate path={`${url}/serialized-product`} component={ProductSerilialized} />
      <Route
        isPrivate
        path={`${url}/total-average-calculation`}
        component={TotalAverageCalculationReport}
      />
      <Route isPrivate path={`${url}/sac-management`} component={SacManagementReport} />
      <Route isPrivate path={`${url}/warranty-report`} component={WarrantyReport} />
      <Route isPrivate path={`${url}/open-lot`} component={OpenLot} />
      <Route isPrivate path={`${url}/lot-management-report`} component={LotManagementReport} />
      <Route isPrivate path={`${url}/future-bounce`} component={FutureBounce} />
      <Route isPrivate path={`${url}/retroactive-bounce`} component={RetroactiveBounce} />
      <Route isPrivate path={`${url}/parts-models`} component={PartsXModelsReport} />
      <Route isPrivate path={`${url}/completed-orders`} component={CompletedOrders} />
      <Route isPrivate path={`${url}/branch-balance`} component={BranchBalance} />
      <Route isPrivate path={`${url}/non-compliance`} component={NonComplianceReport} />
      <Route isPrivate path={`${url}/express-report`} component={ExpressReport} />
      <Route isPrivate path={`${url}/scrap-report`} component={ScrapReport} />
      <Route isPrivate path={`${url}/closed-os`} component={ClosedOsReport} />
      <Route isPrivate path={`${url}/register-complaints`} component={RegisterComplaints} />
      <Route isPrivate path={`${url}/billing-by-os`} component={BillingByOsReport} />
      <Route isPrivate path={`${url}/photo-lote`} component={PhotoLoteReport} />
      <Route isPrivate path={`${url}/management-filter`} component={ManagementFilters} />
      <Route isPrivate path={`${url}/emails-control`} component={EmailsConstrolSac} />
      <Route isPrivate path={`${url}/sale-by-period`} component={SaleByPeriodReport} />
      <Route
        isPrivate
        path={`${url}/lot-pending-confirmation`}
        component={LotpendingConfirmationReport}
      />
      <Route
        isPrivate
        path={`${url}/control-sac-Management`}
        component={ControlSacMangementReport}
      />
      <Route
        isPrivate
        path={`${url}/production-by-technician`}
        component={ProductionByTechnicianReport}
      />
    </>
  );
};
