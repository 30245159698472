import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

const typeSelect = [
  { label: 'Detalhado', value: 'detalhado' },
  { label: 'Resumido', value: 'resumido' },
];

const operationSelect = [
  { label: 'TODOS', value: 0 },
  { label: 'E-COMMERCE', value: 7 },
  { label: 'RFB CENTER CELL', value: 10 },
  { label: 'RFB SAMSUNG', value: 11 },
  { label: 'RFB TECH CENTER', value: 19 },
  { label: 'NÃO SERIALIZADO', value: 20 },
];

export const ProductionSectorReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [year, setYear] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [stageSelect, setStageSelect] = useState([]);
  const [typeValue, setTypeValue] = useState('detalhado');
  const [operationValue, setOperationValue] = useState(0);
  const [stageValue, setStageValue] = useState(0);

  const handleDownloadReport = async (buttonName, values, { setSubmitting }) => {
    const temp = { ...values, who: buttonName };
    setSubmitting(true);
    setIsLoading(true);
    toast.info('Gerando o relatório de produção por setor...');
    try {
      const { data } = await providersService.getProductionSectorReport(temp);
      await downloadcsv(`Relatório_Produção_Por_Setor${now}`, data);

      toast.success('Relatório de produção por setor gerado com sucesso');
    } catch (err) {
      console.log(err);
      toast.error(
        err?.response?.data?.message || 'Erro na geração do relatório de posicao do estoque',
      );
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const getDataStage = useCallback(async () => {
    try {
      const { data } = await providersService.getDataStage();
      setStageSelect(data);
    } catch (error) {
      setStageSelect([]);
    }
  }, []);

  useEffect(() => {
    getDataStage();
  }, [getDataStage]);

  return (
    <>
      <PageTitle>Relatório Produção por setor</PageTitle>

      <Formik
        initialValues={{
          year: year,
          input_data: inputData,
          output_data: outputData,
          range_data_type: typeValue,
          range_data_operation: operationValue,
          range_data_stage: stageValue,
        }}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data_type'
                  label='Tipo do relatório'
                  variant='outlined'
                  options={typeSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data_operation'
                  label='Etapa'
                  variant='outlined'
                  options={stageSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='range_data_stage'
                  label='Operação'
                  variant='outlined'
                  options={operationSelect}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='year'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Ano'
                  component={DatePicker}
                  views={['year']}
                  format='yyyy'
                  openTo='year'
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  disabled={isLoading}
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  disabled={isLoading}
                  type='submit'
                  fullWidth
                  loading={props.isSubmitting}
                  onClick={(e) => handleDownloadReport('data', props.values, props)}
                >
                  exportar relatório por data
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  disabled={isLoading}
                  type='submit'
                  fullWidth
                  loading={props.isSubmitting}
                  onClick={(e) => handleDownloadReport('ano', props.values, props)}
                >
                  exportar relatório por ano
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setYear(props.values.year);
              setTypeValue(props.values.range_data_type);
              setOperationValue(props.values.range_data_operation);
              setStageValue(props.values.range_data_stage);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
