import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { Button, DatePicker, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { DialogOpenServiceOrder } from './Dialog/DialogOpenServiceOrder';
import { DialogRegisterLot } from './Dialog/DialogRegisterLot';
import { DialogRegisterNf } from './Dialog/DialogRegisterNf';
import { DialogViewNfLote } from './Dialog/DialogViewNfLote';

const columns = [
  { label: 'Notas Lote' },
  { label: 'Lote' },
  { label: 'Data Criação' },
  { label: 'Status' },
  { label: 'Num Pedido' },
  { label: 'Fornecedor' },
  { label: 'Fornecedor Origem' },
  { label: 'Atendimento' },
  { label: 'Transportadora' },
  { label: 'Objeto' },
  { label: 'Operação' },
  { label: 'Ações' },
];

export const LoteManagement = () => {
  const typeStatus = [
    { label: 'Lote Criado', value: 0 },
    { label: 'Lote em Abertura de OS', value: 1 },
    { label: 'Lote encerrado', value: 2 },
  ];

  const [openModalServiceOrder, setOpenModalServiceOrder] = useState(false);
  const [openDialogViewNfs, setOpenDialogViewNfs] = useState(false);
  const [openModalRegisreLot, setOpenModalRegisreLot] = useState(false);
  const [openModalNF, setOpenModaNF] = useState(false);
  const [linhaInfos, setLinhaInfos] = useState();
  const [table, setDataTable] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dataTeste = [
    {
      idCad: 1,
      Fornecedor: 'SAMSUNG',
      FornecedorOri: 'TECH CENTER',
      Operacao: 'RFB PAN',
      Atendimento: 'GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 0,
      statusLote: 'Lote Criado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '456',
    },
    {
      idCad: 5,
      Fornecedor: 'APPLE',
      FornecedorOri: 'ASSURANT',
      Operacao: 'RFB ITU',
      Atendimento: 'ECOMMERCE',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 0,
      statusLote: 'Lote Criado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '126489',
    },
    {
      idCad: 2,
      Fornecedor: 'MOTOROLA',
      FornecedorOri: 'ASSURANT',
      Operacao: 'RFB PAN',
      Atendimento: 'GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 1,
      statusLote: 'Lote em Abertura de OS',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '123',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2023',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
    {
      idCad: 2,
      Fornecedor: 'ASSURANT',
      FornecedorOri: 'TESTE',
      Operacao: 'RFB PAN',
      Atendimento: 'FORA DE GARANTIA',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      status: 2,
      statusLote: 'Lote Encerrado',
      ativo: 1,
      dataCriacao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: '4789',
    },
  ];

  const getDados = (status) => {
    const table = dataTeste.filter((item) => item.status === status.type_status);
    setDataTable(table);
    setPage(0);
  };

  const handleOpenDialogViewNfs = (item) => {
    setOpenDialogViewNfs(true);
    setLinhaInfos(item);
  };
  const handleCloseDialogViewNfs = () => {
    setOpenDialogViewNfs(false);
  };

  const handleOpenModalNF = async () => {
    setOpenModaNF(true);
  };
  const handleCloseModalNF = () => {
    setOpenModaNF(false);
  };

  const handleOpenDialogOrderService = () => {
    setOpenModalServiceOrder(true);
  };

  const handleCloseDialogOrderService = () => {
    setOpenModalServiceOrder(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // const filteredData = dataTeste.filter((item) => {
  //   return (
  //     item.Lote.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     item.dataCriacao.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     item.statusLote.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     item.Fornecedor.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //     item.FornecedorOri.toString().includes(searchQuery) ||
  //     item.Atendimento.toString().includes(searchQuery) ||
  //     item.Transportadora.toString().includes(searchQuery) ||
  //     item.NumConhecimento.toString().includes(searchQuery) ||
  //     item.Operacao.toString().includes(searchQuery)
  //   );
  // });

  return (
    <>
      <DialogOpenServiceOrder
        openModalServiceOrder={openModalServiceOrder}
        handleCloseDialogOrderService={handleCloseDialogOrderService}
      />
      <DialogRegisterNf openModalNF={openModalNF} handleCloseModalNF={handleCloseModalNF} />
      <DialogViewNfLote
        openDialogViewNfs={openDialogViewNfs}
        handleCloseDialogViewNfs={handleCloseDialogViewNfs}
        linhaInfos={linhaInfos}
      />
      <DialogRegisterLot
        openModalRegisreLot={openModalRegisreLot}
        setOpenModalRegisreLot={setOpenModalRegisreLot}
      />
      <PageTitle>Gerenciamento de Lote</PageTitle>

      <Formik initialValues={{ type_status: 0 }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Field
                  size='small'
                  fullWidth
                  name='type_status'
                  variant='outlined'
                  label='Status'
                  options={typeStatus}
                  component={Select}
                  onChange={(e) => getDados({ type_status: e })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Fim'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button fullWidth startIcon={<SearchIcon />}>
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Button
                  onClick={() => {
                    setOpenModalRegisreLot(true);
                  }}
                  fullWidth
                >
                  Novo Lote
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <Field
                    size='small'
                    name='search'
                    label='Pesquisar'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={12}>
                  <Table name='Table' headers={columns} size='small' disableNumeration>
                    {table
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Tooltip title='Visualizar Notas'>
                              <IconButton
                                color='primary'
                                onClick={() => handleOpenDialogViewNfs(item)}
                              >
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{item.Lote}</TableCell>
                          <TableCell>{item.dataCriacao}</TableCell>
                          <TableCell>{item.statusLote}</TableCell>
                          <TableCell>Num Pedido</TableCell>
                          <TableCell>{item.Fornecedor}</TableCell>
                          <TableCell>{item.FornecedorOri}</TableCell>
                          <TableCell>{item.Atendimento}</TableCell>
                          <TableCell>{item.Transportadora}</TableCell>
                          <TableCell>{item.NumConhecimento}</TableCell>
                          <TableCell>{item.Operacao}</TableCell>
                          <TableCell>
                            <Tooltip title='Abertura de OS'>
                              <IconButton
                                color='secondary'
                                onClick={() => {
                                  handleOpenDialogOrderService();
                                }}
                              >
                                <AddCircleIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Nota Fiscal'>
                              <IconButton
                                color='secondary'
                                onClick={() => {
                                  handleOpenModalNF(item);
                                }}
                              >
                                <NoteAddIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </Table>
                  <TablePagination
                    component='div'
                    count={table.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
