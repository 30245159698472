import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { formatDate } from '../../../../helpers/formateDate';

export const BillingByOsReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();

  const handleExport = async (values) => {
    setIsLoading(true);
    toast.info('Aguarde relatório sendo gerado...');
    try {
      const { data } = await providersService.billingByOsReport({ values });
      const result = data.result;
      const date = await formatDate(now);

      if (data.status === 200) {
        toast.success(data.msg);
        await downloadcsv(`Relatório_Abertura_de_Lote${date}`, result);
        setIsLoading(false);
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao gerar relatório');
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Faturamento por OS</PageTitle>

      <Formik
        initialValues={{ input_data: new Date(), output_data: new Date() }}
        onSubmit={handleExport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
