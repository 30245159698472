import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { readCsv } from 'helpers/readcsv';
import { PageTitle } from 'pages/Layout/PageTitle';

import { downloadcsv } from '../../../../helpers/downloadcsv';
import { formatDate } from '../../../../helpers/formateDate';
import { stockService } from '../../../../services';

export const Alternate = () => {
  const [getData, setData] = useState([]);
  const [radioValue, setRadioValue] = useState('add');
  const [isLoading, setIsLoading] = useState(0);
  const colorButton = {
    color1: '#0288d1',
    color2: '#388e3c',
  };
  const now = new Date();

  const inputFileRef = useRef(null);

  const handleGetDataAlternate = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    try {
      const { data } = await stockService.getDataAlternate();
      const date = await formatDate(now);
      await downloadcsv(`Alternate_${date}`, data);
      toast.success('Alternate gerado com sucesso');
    } catch (error) {
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Erro ao gerar relatório');
    }
  };

  const handleReadCsv = async () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const hanldeReadDataCsv = async (e) => {
    toast.info('importando dados ...');
    try {
      const data_csv = await readCsv(e);
      setData(data_csv);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao ler CSV');
    } finally {
      toast.success('Dados importados com sucesso');
    }
  };

  const handleSubmite = async () => {
    try {
      setIsLoading(1);
      const login = localStorage.getItem('@gedy:user');
      if (getData.length > 0) {
        await stockService.addAlternateData({
          csv_data: getData,
          operation: radioValue,
          login: login,
        });
        toast.success('Alternate feito com sucesso!');
        setData([]);
      } else {
        setIsLoading(0);
        toast.error('Não há dados para processar');
      }
    } catch (error) {
      setIsLoading(0);
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Erro ao fazer o processo');
    } finally {
      setIsLoading(0);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(event) => hanldeReadDataCsv(event)}
        accept='text/csv'
        hidden
      />
      <PageTitle>Alternate</PageTitle>

      <Formik initialValues={{}} onSubmit={handleSubmite}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Button fullWidth onClick={handleGetDataAlternate} disabled={isLoading == 1}>
                  gerar csv
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth onClick={handleReadCsv} disabled={isLoading == 1}>
                  importar csv
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  color='secondary'
                  type='submit'
                  fullWidth
                  loading={props.isSubmitting}
                  disabled={isLoading == 1}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    onChange={(event) => setRadioValue(event.target.value)}
                    defaultValue='add'
                    row
                    aria-label='operation'
                    name='operation'
                  >
                    <FormControlLabel
                      value='add'
                      control={<Radio color='primary' />}
                      label='ADICIONAR'
                    />
                    <FormControlLabel
                      value='remove'
                      control={<Radio color='primary' />}
                      label='REMOVER'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
