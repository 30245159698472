import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

export const WarrantyReport = () => {
  const [importing, setImporting] = useState(false);

  const [getData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(0);
  const colorButton = {
    color1: '#0288d1',
    color2: '#f57c00',
  };
  const now = new Date();
  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const handleFileChange = async (e) => {
    setImporting(true);
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getDados = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              imei_serial: obj.split(';')[0],
            };

            return dados;
          }
        });

        const filterDados = getDados.filter((item) => item !== false);

        const { data } = await providersService.getGetDataWarrantys(filterDados);
        toast.info('Criando arquivo csv aguarde ...');

        await downloadcsv('relatório_garantia_' + now, data);
        toast.success('Arquivo criado com sucesso.');
        setImporting(false);
      };

      toast.success('Dados importados com sucesso');

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <PageTitle>Upload de Planilha para Relatório de Garantia</PageTitle>

      <Formik initialValues={{}}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Button fullWidth onClick={handleImportClick} loading={importing}>
                  Importar CSV
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
