import { api } from 'services/api';

export class ConferenceService {
  async getTableLotes(data) {
    return api.post(`/xpcell-multimarcas-conference/conference/getTableLotes`, data);
  }
  async getOperation() {
    const { data } = await api.get(`xpcell-multimarcas-conference/conference/getOperation`);
    return data;
  }
  async getProcessType() {
    const { data } = await api.get(`xpcell-multimarcas-conference/conference/getProcessType`);
    return data;
  }
  async getServiceType() {
    const { data } = await api.get(`xpcell-multimarcas-conference/conference/getServiceType`);
    return data;
  }
  async getCarrying() {
    const { data } = await api.get(`xpcell-multimarcas-conference/conference/getCarrying`);
    return data;
  }
  async getClient() {
    const { data } = await api.get(`xpcell-multimarcas-conference/conference/getClient`);
    return data;
  }
  async getDataTableInfoAdc(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getDataTableInfoAdc`, data);
  }
  // async createLot(data) {
  //   return api.post(`xpcell-multimarcas-conference/conference/createLot`, data);
  // }
  async getStatusLote(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getStatusLote`, data);
  }

  async getDataTableDialogRegisterNf(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getDataTableDialogRegisterNF`, data);
  }
  async getTableNItens(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getTableNItens`, data);
  }

  async getOsRelated(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getOsRelated`, data);
  }

  async addNfCabec(data) {
    return api.post(`xpcell-multimarcas-conference/conference/addNfCabec`, data);
  }

  async UpdateNfCabec(data) {
    return api.post(`xpcell-multimarcas-conference/conference/UpdateNfCabec`, data);
  }

  async InativateNf(data) {
    return api.post(`xpcell-multimarcas-conference/conference/InativateNf`, data);
  }

  async AddNewItem(data) {
    return api.post(`xpcell-multimarcas-conference/conference/AddNewItem`, data);
  }

  async GetInternProduct(data) {
    return api.post(`xpcell-multimarcas-conference/conference/GetInternProduct`, data);
  }

  async getCodOrigem(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getCodOrigem`, data);
  }

  async validateNcm(data) {
    return api.post(`xpcell-multimarcas-conference/conference/validateNcm`, data);
  }

  async editItemNf(data) {
    return api.post(`xpcell-multimarcas-conference/conference/editItemNf `, data);
  }

  async InativateItem(data) {
    return api.post(`xpcell-multimarcas-conference/conference/InativateItem`, data);
  }

  async recordLote(data) {
    return api.post(`xpcell-multimarcas-conference/conference/recordLote`, data);
  }

  async importXml(nfeProc) {
    console.log(nfeProc);
    return api.post(`xpcell-multimarcas-conference/conference/importXml`, nfeProc);
  }

  async getTableDialogOpenOS(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getTableDialogOpenOs`, data);
  }

  async getVinculedOs(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getVinculedOs`, data);
  }

  async getPartnumber(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getPartnumber`, data);
  }

  async getTableAcc(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getTableAcc`, data);
  }

  async getTypeService() {
    return api.post(`xpcell-multimarcas-conference/conference/getTypeService`);
  }

  async getComplaint(data) {
    return api.post(`xpcell-multimarcas-conference/conference/getComplaint`, data);
  }

  async generateOs(data) {
    return await api.post(`xpcell-multimarcas-conference/conference/generateOs`, data);
  }

  async printLabel(data) {
    return api.post(`xpcell-multimarcas-conference/conference/printLabel`, data, {
      responseType: 'blob',
    });
  }
}

export const conferenceService = new ConferenceService();
