import { useRouteMatch } from 'react-router';

import { RegisterCarrier } from '../../pages/Private/Register/RegisterCarrier';
import { RegisterClient } from '../../pages/Private/Register/RegisterClient';
import { RegisterCodRepair } from '../../pages/Private/Register/RegisterCodRepair';
import { RegisterMark } from '../../pages/Private/Register/RegisterMark';
import { RegisterModel } from '../../pages/Private/Register/RegisterModel';
import { RegisterOperations } from '../../pages/Private/Register/RegisterOperations';
import { RegisterParts } from '../../pages/Private/Register/RegisterParts';
import { RegisterPartsCategory } from '../../pages/Private/Register/RegisterPartsCategory';
import { RegisterProduct } from '../../pages/Private/Register/RegisterProduct';
import { RegisterSubPartsCategory } from '../../pages/Private/Register/RegisterSubPartsCategory';
import { RegisterSupplier } from '../../pages/Private/Register/RegisterSupplier';
import { RegisterTypeProducts } from '../../pages/Private/Register/RegisterTypeProducts';
import { Route } from '../Route';

export const RegisterRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/register-client`} component={RegisterClient} />
      <Route isPrivate path={`${url}/register-operations`} component={RegisterOperations} />
      <Route isPrivate path={`${url}/register-carrier`} component={RegisterCarrier} />
      <Route isPrivate path={`${url}/register-product`} component={RegisterProduct} />
      <Route isPrivate path={`${url}/register-type-product`} component={RegisterTypeProducts} />
      <Route isPrivate path={`${url}/register-mark`} component={RegisterMark} />
      <Route isPrivate path={`${url}/register-model`} component={RegisterModel} />
      <Route isPrivate path={`${url}/register-parts`} component={RegisterParts} />
      <Route isPrivate path={`${url}/register-repair-code`} component={RegisterCodRepair} />
      <Route
        isPrivate
        path={`${url}/register-sub-parts-category`}
        component={RegisterSubPartsCategory}
      />
      <Route isPrivate path={`${url}/register-parts-category`} component={RegisterPartsCategory} />
      <Route isPrivate path={`${url}/register-Fornecedores`} component={RegisterSupplier} />
    </>
  );
};
