import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import * as yup from 'yup';

import { downloadcsv } from '../../../../helpers/downloadcsv';
import { formatDate } from '../../../../helpers/formateDate';
import { Marca } from '../../../../helpers/tipoMarca';
import { Product } from '../../../../helpers/typeProd';
import { providersService } from '../../../../services';

const validationSchema = yup.object().shape({
  type_prod: yup.number().required().typeError('É necessário selecionar o tipo de produto.'),
  type_marca: yup.number().required(),
});

export const DefectXComponentReport = () => {
  const [allTypeProducts, setAllTypeProducts] = useState([]);
  const [typeMarca, setTypeMarca] = useState([{ label: '', value: '' }]);
  const [typeProd, setTypeProd] = useState([{ label: '', value: '' }]);
  const [selectTypeMarca, setSelectTypeMarca] = useState('');
  const [selectTypeProd, setSelectTypeProd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();

  const getDados = useCallback(async () => {
    const data = await Marca();
    const productData = await Product();
    setTypeMarca(data);
    setAllTypeProducts(productData);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const getProduct = (idMarca) => {
    const data = [...allTypeProducts];
    const filterData = data
      .filter((item) => item?.idMarca == idMarca)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeProd(filterData);
    formRef?.current?.setFieldValue('type_prod', null);
  };

  const handleDefectXComponentReport = async () => {
    setIsLoading(true);
    toast.info('Aguardando relatório');
    try {
      const { data } = await providersService.getDefectXComponentReport(
        selectTypeMarca,
        selectTypeProd,
      );
      if (data.length === 0) {
        toast.error('Não há dados para este relatório');
        return;
      }
      const date = await formatDate(now);

      await downloadcsv(`RelatorioEstoque${date}`, data);
      toast.success('Relatório Gerado com sucesso');
    } catch (error) {
      console.log(error);
      toast.error('Falha ao gerar relatório');
    } finally {
      setIsLoading(false);
    }
  };

  const formRef = useRef();

  return (
    <>
      <PageTitle>Relatório de Vínculo Defeito X Componente</PageTitle>

      <Formik
        initialValues={{
          type_marca: selectTypeMarca,
          type_prod: selectTypeProd,
        }}
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={handleDefectXComponentReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_marca'
                  label='Marca'
                  variant='outlined'
                  options={typeMarca}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_prod'
                  label='Tipo produto'
                  variant='outlined'
                  options={typeProd}
                  component={Select}
                  onChange={setSelectTypeProd}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                  type='submit'
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeMarca(props.values.type_marca);
              getProduct(props.values.type_marca);
            }, [props.values.type_marca])}
          </Form>
        )}
      </Formik>
    </>
  );
};
