import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { Box } from '@mui/material';
import Popover from '@mui/material/Popover';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { Button, Select, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';

import { DialogDeleteComplaint } from './Dialogs/DialogDeleteComplaint';
import { DialogUpdateComplaint } from './Dialogs/DialogUpdateComplaint';

const columns = [
  { label: 'Reclamação' },
  { label: 'Data Cadastro' },
  { label: 'Tipos de Produto' },
  { label: 'Alterar' },
  { label: 'Excluir' },
];

export const RegisterComplaints = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeProduct, setTypeProduct] = useState([{ label: '', value: '' }]);
  const [table, setDatatTable] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataLinha, setDataLinha] = useState(null);
  const [vincProdComplaint, setVincProdComplaint] = useState(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPopover = async (event, item) => {
    setAnchorEl(event.currentTarget);
    try {
      const { data } = await providersService.vincProdComplaints({ item });
      setVincProdComplaint(data.result);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      toast.error('Erro ao carregar dados.');
    }
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  const fetchData = async () => {
    try {
      const { data: Products } = await providersService.findTypeProduct();
      setTypeProduct(Products);

      const { data: dataTable } = await providersService.findDataTableComplaints();
      setDatatTable(dataTable);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      toast.error('Erro ao carregar dados.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredTable = table.filter((item) => {
    return Object.keys(item).some((key) => {
      const value = item[key];

      if (key === 'dt_cadastro' && typeof value === 'string') {
        const formattedDate = formatDate(value);
        return formattedDate.includes(searchTerm.toLowerCase());
      }

      return (
        value !== null &&
        value !== undefined &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  });

  const handleOpenDialogDelete = (item) => {
    setOpenDialogDelete(true);
    setDataLinha(item);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
    setDataLinha(null);
    fetchData();
  };

  const handleOpenDialogUpdate = (item) => {
    setOpenDialogUpdate(true);
    setDataLinha(item);
  };

  const handleCloseDialogUpdate = () => {
    setOpenDialogUpdate(false);
    setDataLinha(null);
    fetchData();
  };

  const handleRegisterComplaint = async (values, resetForm) => {
    toast.info('Aguarde, registrando nova reclamação...');
    setIsLoading(true);
    try {
      const { data } = await providersService.registerNewComplaint({ values });
      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        resetForm();
        fetchData();
      } else {
        toast.error(data.msg);
        setIsLoading(false);
        resetForm();
      }
    } catch (error) {
      console.error('Erro ao registrar nova reclamação:', error);
      toast.error('Erro ao registrar nova reclamação');
      setIsLoading(false);
      resetForm();
    }
  };

  return (
    <>
      <DialogDeleteComplaint
        openDialogDelete={openDialogDelete}
        handleCloseDialogDelete={handleCloseDialogDelete}
        dataLinha={dataLinha}
      />
      <DialogUpdateComplaint
        openDialogUpdate={openDialogUpdate}
        handleCloseDialogUpdate={handleCloseDialogUpdate}
        dataLinha={dataLinha}
      />

      <PageTitle>Cadastro de Reclamações</PageTitle>

      <Formik
        initialValues={{ complaint: '', type_product: [], search: '' }}
        onSubmit={(values, { resetForm }) => handleRegisterComplaint(values, resetForm)}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Field
                  size='small'
                  name='complaint'
                  label='Reclamação'
                  variant='outlined'
                  component={Text}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Field
                  size='small'
                  variant='outlined'
                  component={Select}
                  name='type_product'
                  label='Tipo do Produto'
                  options={typeProduct}
                  multiple
                  required
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3} lg={2}>
                <Button type='submit' fullWidth loading={isLoading}>
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Field
                  size='small'
                  name='search'
                  label='Pesquisar'
                  variant='outlined'
                  component={Text}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {filteredTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.descricao}</TableCell>
                        <TableCell>{formatDate(item.dt_cadastro)}</TableCell>
                        <TableCell>
                          <Button
                            aria-describedby={id}
                            variant='contained'
                            onClick={(e) => handleClickPopover(e, item)}
                          >
                            Visualizar
                          </Button>
                          <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Typography sx={{ p: 2 }}>
                              {Array.isArray(vincProdComplaint) && vincProdComplaint.length > 0 ? (
                                vincProdComplaint.map((complaint, index) => (
                                  <div key={index}>
                                    Produto: {complaint.Produto}, Reclamação: {complaint.reclamacao}
                                  </div>
                                ))
                              ) : (
                                <div>Nenhum produto vinculado encontrado.</div>
                              )}
                            </Typography>
                          </Popover>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => handleOpenDialogUpdate(item)} loading={isLoading}>
                            <SyncAltIcon />
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => handleOpenDialogDelete(item)}
                            color='secondary'
                            loading={isLoading}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component='div'
                  count={filteredTable.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
