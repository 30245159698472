import React from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@material-ui/icons/Delete';
import Box from '@mui/material/Box';
import { Button, DatePicker, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

const columns = [
  { label: 'Item' },
  { label: 'Produto' },
  { label: 'Descrição' },
  { label: 'Produto XML' },
  { label: 'Valor' },
  { label: 'QTD' },
  { label: 'Total' },
  { label: 'Excluir' },
];

export const RegisterNfEntryPart = () => {
  return (
    <>
      <Formik
        initialValues={{
          fornecedor: '',
          nota: '',
          serie: '',
          chave: '',
          processo_r: '',
          data_emissao: new Date(),
          data_recebimento: new Date(),
          item: '',
          partnumber: '',
          partnumber_xml: '',
          descricao: '',
          valor: '',
          qtd: '',
          total: '',
        }}
      >
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={4} lg={4}>
              <Field
                size='small'
                name='fornecedor'
                variant='outlined'
                fullWidth
                label='Fornecedor'
                component={Text}
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <Field
                size='small'
                name='nota'
                variant='outlined'
                fullWidth
                label='Nota'
                component={Text}
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <Field
                size='small'
                name='serie'
                variant='outlined'
                fullWidth
                label='Serie'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <Field
                size='small'
                name='chave'
                variant='outlined'
                fullWidth
                label='Chave'
                component={Text}
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <Field
                size='small'
                name='processo_r'
                variant='outlined'
                fullWidth
                label='Processo R'
                component={Text}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                name='data_emissao'
                variant='inline'
                inputVariant='outlined'
                label='Data Emissão'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Field
                size='small'
                name='data_recebimento'
                variant='inline'
                inputVariant='outlined'
                label='Data Recebimento'
                component={DatePicker}
              />
            </Grid>
            <Grid item xs={2} md={2} lg={2}>
              <Button type='submit' fullWidth>
                XML
              </Button>
            </Grid>
          </Grid>
          <Box mt={4}>
            <PageTitle>Inserir Item</PageTitle>
            <Grid container spacing={2}>
              <Grid item xs={1} lg={1}>
                <Field
                  size='small'
                  name='item'
                  variant='outlined'
                  fullWidth
                  label='Item'
                  component={Text}
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='partnumber'
                  variant='outlined'
                  fullWidth
                  label='PartNumber'
                  component={Text}
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='partnumber_xml'
                  variant='outlined'
                  fullWidth
                  label='PartNumber XML'
                  component={Text}
                />
              </Grid>
              <Grid item xs={4} lg={4}>
                <Field
                  size='small'
                  name='descricao'
                  variant='outlined'
                  fullWidth
                  label='Descrição'
                  component={Text}
                />
              </Grid>
              <Grid item xs={1} lg={1}>
                <Field
                  size='small'
                  name='valor'
                  variant='outlined'
                  fullWidth
                  label='Valor'
                  component={Text}
                />
              </Grid>
              <Grid item xs={1} lg={1}>
                <Field
                  size='small'
                  name='quantidade'
                  variant='outlined'
                  fullWidth
                  label='QTD'
                  component={Text}
                />
              </Grid>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='valor_total'
                  variant='outlined'
                  fullWidth
                  label='Valor Total'
                  component={Text}
                />
              </Grid>
              <Grid item xs={2} md={2} lg={2}>
                <Button type='submit' fullWidth>
                  Inserir Item
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </Formik>

      <Box mt={2}>
        <Grid container>
          <Grid xs={12} md={12} lg={12}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Produto</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Produto XML</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Qtd</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>
                  <DeleteIcon style={{ color: '#f50057', cursor: 'pointer' }} />
                </TableCell>
              </TableRow>
            </Table>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
