import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { readCsv } from 'helpers/readcsv';
import { stockSaleService } from 'services';

const modelo = [
  {
    valor_bloqueado: 'coloque aqui o IMEI/SERIAL',
    observacao: 'coloque aqui a observação',
  },
];

export const RegistreBlockServiceOrdem = () => {
  const [radioValue, setRadioValue] = useState('IMEI-SERIAL');
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const inputFileRef = useRef(null);

  const handleReadCsv = async () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const hanldeReadDataCsv = async (e) => {
    toast.info('importando dados ...');
    try {
      const data_csv = await readCsv(e);
      setData(data_csv);
      toast.success('Dados importados com sucesso');
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao ler CSV');
    }
  };

  const handleLabel = () => {
    switch (radioValue) {
      case 'NOTA-FISCAL':
        return 'Digite a NOTA FISCAL';
      default:
        return 'Digite o IMEI/SERIAL';
    }
  };

  const handleCheckedValueDataCsv = async () => {
    return radioValue === 'UPLOAD-SERIAIS' && data.length === 0;
  };

  const handleDownloadModel = async () => {
    await downloadcsv('modelo_bloqueio_os', modelo);
  };

  const handleSubmite = async (event, helpers) => {
    const temp = {
      ...event,
      operation: radioValue,
      data_csv: data,
      login: localStorage.getItem('@gedy:user'),
    };
    const validation = await handleCheckedValueDataCsv();
    console.log('validation', validation);
    if (validation) {
      toast.warning('Não foi importado o arquivo CSV');
      return;
    }
    try {
      toast.info('Processando dados aguarde ...');
      const { data } = await stockSaleService.block_order_service_registre(temp);
      console.log(data);
      if (radioValue !== 'UPLOAD-SERIAIS') {
        toast.success('Dados processados com sucesso');
        setIsLoading(false);
        helpers.setSubmitting(false);
        if (data.state === '400') {
          toast.warning(data.message);
        }
      } else {
        toast.success('Dados processados com sucesso');
        toast.info('Gerando csv aguarde ...');
        await downloadcsv('resultado_bloqueio', data);
        toast.success('Dados processados com sucesso');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ocorreu um erro ao processar a solicitação.');
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(event) => hanldeReadDataCsv(event)}
        accept='text/csv'
        hidden
      />
      <Formik
        initialValues={{
          textField: '',
          observation: '',
        }}
        onSubmit={handleSubmite}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    onChange={(event) => {
                      inputFileRef.current.value = '';
                      props.setFieldValue('textField', '');
                      setRadioValue(event.target.value);
                    }}
                    defaultValue='IMEI-SERIAL'
                    row
                    aria-label='operation'
                    name='operation'
                  >
                    <FormControlLabel
                      value='IMEI-SERIAL'
                      control={<Radio color='secondary' />}
                      label='IMEI/SERIAL'
                    />
                    <FormControlLabel
                      value='NOTA-FISCAL'
                      control={<Radio color='secondary' />}
                      label='NOTA FISCAL'
                    />
                    <FormControlLabel
                      value='UPLOAD-SERIAIS'
                      control={<Radio color='secondary' />}
                      label='UPLOAD SERIAIS'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {radioValue === 'UPLOAD-SERIAIS' ? (
                <Grid item xs={12} lg={2}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    onClick={handleReadCsv}
                    disabled={isLoading}
                  >
                    importar csv
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} lg={4}>
                  <Field
                    name='textField'
                    component={Text}
                    label={handleLabel()}
                    fullWidth
                    variant='outlined'
                    disabled={isLoading}
                    required={radioValue !== 'UPLOAD-SERIAIS'}
                  />
                </Grid>
              )}

              {radioValue === 'UPLOAD-SERIAIS' && (
                <Grid item xs={12} lg={2}>
                  <Button
                    fullWidth
                    color='secondary'
                    variant='contained'
                    onClick={handleDownloadModel}
                    disabled={isLoading}
                  >
                    Download Modelo
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                {radioValue !== 'UPLOAD-SERIAIS' && (
                  <Field
                    component={Text}
                    name='observation'
                    label='Digite uma observação'
                    fullWidth
                    variant='outlined'
                    disabled={isLoading}
                    required={radioValue !== 'UPLOAD-SERIAIS'}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={isLoading}
                >
                  enviar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
