import React from 'react';

import Grid from '@material-ui/core/Grid';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';

const columns = [
  { label: 'PN' },
  { label: 'Descrição' },
  { label: 'Local' },
  { label: 'Qtd Total' },
  { label: 'Conferir' },
];

export const DialogConference = ({ openDialogConference, handleCloseDialogConference }) => {
  return (
    <>
      <Dialog maxWidth={'lg'} open={openDialogConference}>
        <Dialog.Title onClose={handleCloseDialogConference}>Conferencia de Peças</Dialog.Title>
        <Dialog.Content>
          <Formik>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      size='small'
                      name='search'
                      variant='outlined'
                      fullWidth
                      label='Pesquisar'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table name='Table' headers={columns} disableNumeration>
                      <TableRow>
                        <TableCell>Peça</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Local</TableCell>
                        <TableCell>Qtd Total</TableCell>
                        <TableCell>{<Button>Confirmar</Button>}</TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
