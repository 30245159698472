import { api } from 'services/api';

export class StockSaleService {
  async block_order_service_consult(data) {
    return api.post(
      '/xpcell-multimarcas-stock-sales/stock-sales/block-order-service-consult',
      data,
    );
  }

  async block_order_service_registre(data) {
    return api.post(
      '/xpcell-multimarcas-stock-sales/stock-sales/block-order-service-registre',
      data,
    );
  }

  async unlock_order_service(data) {
    return api.post('/xpcell-multimarcas-stock-sales/stock-sales/unlock-order-service', data);
  }
}

export const stockSaleService = new StockSaleService();
