import React, { memo, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import DrawerMUI from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import StarIcon from '@mui/icons-material/Star';
import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { accountService } from 'services';
import { APP_VERSION } from 'settings';
import { useContextSelector } from 'use-context-selector';

import { useLayoutContext } from '../context';
import { iconMap, iconSwitch } from './iconMap';
import { menu } from './menu';
import { MenuItemRoute } from './MenuItemRoute';
import { MenuItemToggle } from './MenuItemToggle';
import useStyles from './styles';

const Drawer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('3000px'));
  const classes = useStyles();
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [newMenu, setNewMenu] = useState([]);
  const [favoriteRoles, setFavoriteRoles] = useState([]);
  const [openMenus, setOpenMenus] = useState([]);
  const { drawerOpen, handleDrawerToggle } = useLayoutContext();

  const toggleMenu = useCallback(
    (menuId) => {
      const isOpen = openMenus.includes(menuId);
      if (isOpen) {
        setOpenMenus((prevState) => prevState.filter((id) => menuId !== id));
        return;
      }
      setOpenMenus((prevState) => [...prevState, menuId]);
    },
    [openMenus],
  );

  const sendToPage = useCallback(
    (link) => {
      browserHistory.push(link);
      !isDesktop && handleDrawerToggle();
    },
    [isDesktop, handleDrawerToggle],
  );

  const handleSelectFav = async (roles) => {
    await accountService.setFavoriteMenu({ idUser: user.id, roles: roles });
    await menuTeste();
  };

  const menuTeste = useCallback(async () => {
    const { data } = await accountService.getFavoriteMenu(user?.id, menu);
    const updatedData = data.updatedMenu.map((item) => ({
      ...item,
      teste: item.icon,
      icon: iconMap[item.icon],
      items: item.items
        ? item.items.map((subItem) => ({ ...subItem, icon: iconMap[subItem.icon] }))
        : [],
    }));
    setNewMenu(updatedData);
    setFavoriteRoles(data.favoriteRoles);
  }, [user?.id]);

  useEffect(() => {
    menuTeste();
  }, [menuTeste]);

  return (
    <DrawerMUI
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={drawerOpen}
      onClose={handleDrawerToggle}
      className={classes.drawer}
      classes={{ paper: classes.paper }}
      ModalProps={{ keepMounted: true }}
    >
      <List className={classes.list}>
        <p className={classes.gaia}>
          Gaia<span>System</span>
        </p>

        {newMenu.map((menuItem) => {
          if (!menuItem.id) {
            console.error('Menu item without id:', menuItem);
            return null;
          }
          const hasSubMenu = Array.isArray(menuItem.items) && menuItem.items.length > 0;
          if (hasSubMenu) {
            const isOpen = openMenus.includes(menuItem.id);
            const { items, id, roles, ...props } = menuItem;
            return (
              <React.Fragment key={id}>
                <MenuItemToggle
                  id={id}
                  {...props}
                  isOpen={isOpen}
                  onClick={toggleMenu}
                  isNested
                  roles={roles}
                />
                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                  <List style={{ marginLeft: '20px' }}>
                    {items.map(({ path, label, icon, roles }) => (
                      <MenuItemRoute
                        key={path}
                        style={{ color: 'red' }}
                        fullPath={`/${id}${path}`}
                        label={label}
                        icon={iconSwitch(roles)}
                        icon2={
                          <IconButton aria-label='close' onClick={() => handleSelectFav(roles)}>
                            {favoriteRoles.includes(roles) ? (
                              <StarIcon style={{ fill: '#f5d742', zIndex: 1 }} />
                            ) : (
                              <StarIcon style={{ fill: '#fff', zIndex: 1 }} />
                            )}
                          </IconButton>
                        }
                        isNested
                        onClick={sendToPage}
                        roles={roles}
                      />
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          }
          const { path, label, icon, roles } = menuItem;
          return (
            <MenuItemRoute
              key={path}
              path={path}
              label={label}
              icon={icon}
              onClick={sendToPage}
              roles={roles}
            />
          );
        })}
      </List>
      <NavLink to='/changelog' className={classes.version}>
        Versão {APP_VERSION}
      </NavLink>
    </DrawerMUI>
  );
};

export default memo(Drawer);
