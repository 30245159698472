import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const StockPositionReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const handleDownloadReport = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    toast.warning('Gerando o relatório de posição do estoque...');
    try {
      const { data } = await providersService.getStockPositionReport();

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));

      const workbook = (XLSX.WorkBook = {
        Sheets: {
          Geral: worksheet,
        },
        SheetNames: ['Geral'],
      });
      XLSX.writeFile(workbook, `RelatorioPosicaoEstoque_${now}.xlsx`);

      toast.success('Relatório de posicao do estoque gerado com sucesso');
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Erro na geração do relatório de posicao do estoque',
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Posição de Estoque</PageTitle>

      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid> */}
              <Grid item xs={12} lg={4}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
