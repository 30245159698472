import React, { useState, useCallback, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Select, Button } from 'components';
import { Form, Formik, Field } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';
import * as yup from 'yup';

import { Model } from '../../../../helpers/getModel';
import { Marca } from '../../../../helpers/tipoMarca';
import { Product } from '../../../../helpers/typeProd';
import { providersService } from '../../../../services';

const validationSchema = yup.object().shape({
  type_prod: yup.number().required().typeError('É necessário selecionar o tipo de produto.'),
  type_model: yup.number().required().typeError('É necessário selecionar o tipo de produto.'),
});

export const PnXModeloReport = () => {
  const [allTypeModels, setAllTypeModels] = useState([]);
  const [allTypeProducts, setAllTypeProducts] = useState([]);
  const [typeMarca, setTypeMarca] = useState([{ label: '', value: '' }]);
  const [typeProd, setTypeProd] = useState([{ label: '', value: '' }]);
  const [selectTypeMarca, setSelectTypeMarca] = useState('');
  const [selectTypeProd, setSelectTypeProd] = useState('');
  const [typeModel, setTypeModel] = useState([{ label: '', value: '' }]);
  const [selectTypeModel, setSelectTypeModel] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const data = await Marca();
    const productData = await Product();
    const modelData = await Model();
    setTypeMarca(data);
    setAllTypeProducts(productData);
    setAllTypeModels(modelData);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const getProduct = (idMarca) => {
    const data = [...allTypeProducts];
    const filterData = data
      .filter((item) => item?.idMarca == idMarca)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeProd(filterData);
    formRef?.current?.setFieldValue('type_prod', null);
  };

  const getModel = (idTipoProduto, idMarca) => {
    const dataModel = [...allTypeModels];

    const filterModelProd = dataModel
      .filter((item) => item?.idTipoProduto === idTipoProduto && item?.idMarca === idMarca)
      .map((item) => ({ value: item.value, label: item.label }));

    setTypeModel(filterModelProd);
    formRef?.current?.setFieldValue('type_model', null);
  };

  const formRef = useRef();

  const handlePNXModelReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);

    const now = new Date().toLocaleDateString();

    try {
      const result = await providersService.getPNXModelReport(
        selectTypeMarca,
        selectTypeProd,
        selectTypeModel,
      );
      if (result && result.data && result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          PNXModelRport: worksheet,
        },
        SheetNames: ['PNXModelRport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_PN_X_Modelo${now}.xlsx`);

      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Relatório de PN X Modelo</PageTitle>

      <Formik
        initialValues={{
          type_marca: selectTypeMarca,
          type_prod: selectTypeProd,
          type_model: selectTypeModel,
        }}
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={handlePNXModelReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_marca'
                  label='Marca'
                  variant='outlined'
                  options={typeMarca}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_prod'
                  label='Tipo produto'
                  variant='outlined'
                  options={typeProd}
                  component={Select}
                  onChange={setSelectTypeProd}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_model'
                  label='Modelo'
                  variant='outlined'
                  options={typeModel}
                  component={Select}
                  onChange={setSelectTypeModel}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                  type='submit'
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeMarca(props.values.type_marca);
              getProduct(props.values.type_marca);
            }, [props.values.type_marca])}
            {useEffect(() => {
              setSelectTypeProd(props.values.type_prod);
              getModel(props.values.type_prod, props.values.type_marca);
            }, [props.values.type_prod, props.values.type_marca])}
          </Form>
        )}
      </Formik>
    </>
  );
};
