import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';

const columns = [{ label: 'Operação' }, { label: 'Alterar' }, { label: 'Excluir' }];

export const RegisterOperations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const handleOpenDialogConfirm = () => {
    setOpenDialogConfirm(true);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
  };

  const handleOpenDialogEdit = () => {
    setOpenDialogEdit(true);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
  };
  return (
    <>
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
      />
      <DialogEdit handleCloseDialogEdit={handleCloseDialogEdit} openDialogEdit={openDialogEdit} />
      <PageTitle>Cadastro de Operações</PageTitle>
      <Formik initialValues={{ name_operation: '' }}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_name'
                variant='outlined'
                fullWidth
                label='Nome da Operação'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button type='submit' fullWidth loading={isLoading}>
                Cadastrar
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              <TableRow>
                <TableCell>Operação 1</TableCell>
                <TableCell>
                  <EditIcon
                    style={{ color: '#512da8', cursor: 'pointer' }}
                    fullWidth
                    onClick={handleOpenDialogEdit}
                  ></EditIcon>
                </TableCell>
                <TableCell>
                  <DeleteIcon
                    style={{ color: '#f50057', cursor: 'pointer' }}
                    fullWidth
                    onClick={handleOpenDialogConfirm}
                  ></DeleteIcon>
                </TableCell>
              </TableRow>
            </Table>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
