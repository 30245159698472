import { useRouteMatch } from 'react-router';

import { Services } from 'pages/Private/InHome/Services';

import { Route } from '../Route';

export const InHomeRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/services`} component={Services} />
    </>
  );
};
