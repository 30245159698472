import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Select, Button, Text, DatePicker } from 'components';
import { Formik, Form, Field } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';

import { Status } from '../../../../helpers/getSacManagementStatus';
import { providersService } from '../../../../services';

export const SacManagementReport = () => {
  const [selectedType, setSelectedType] = useState(0);
  const [selectOption, setSelectOption] = useState('Dt. Pedido');
  const [status, setStatus] = useState([{ label: '', value: '' }]);
  const [selectStatus, setSelectStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  const selectType = [
    { label: 'Cadastro', value: 0 },
    { label: 'Log do sistema', value: 1 },
  ];

  const selectOptionsRegister = [
    { label: 'Dt. Pedido', value: 'Dt. Pedido' },
    { label: 'Status', value: 'Status' },
    { label: 'Pedido', value: 'Pedido' },
    { label: 'Serial Saida', value: 'Serial Saida' },
    { label: 'NF Remessa', value: 'NF Remessa' },
    { label: 'Serial Entrada', value: 'Serial Entrada' },
  ];

  const selectOptionsLog = [
    { label: 'ID. Atend', value: 'ID. Atend' },
    { label: 'OS Gerada', value: 'OS Gerada' },
  ];

  const getDados = useCallback(async () => {
    const dataStatus = await Status();
    //console.log(dataStatus);
    setStatus(dataStatus);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleSacManagementReport = async (values) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      values.type_report;
      values.select_option;
      values.startDate;
      values.endDate;
      values.log_input;
      values.status_option;
      values.serial_saida;
      values.nf_remessa;
      values.serial_entrada;
      console.log(inputData, outputData);
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getHandleSacManagementReport(
        values.type_report,
        values.select_option,
        formattedInputData,
        formattedOutputData,
        values.log_input || 0,
        values.status_option || 0,
        values.serial_saida || 0,
        values.nf_remessa || 0,
        values.serial_entrada || 0,
      );

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          HandleSacManagementReport: worksheet,
        },
        SheetNames: ['HandleSacManagementReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Sac_Gestao${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle>SAC - Gestão</PageTitle>

      <Formik
        initialValues={{
          type_report: selectType[0].value,
          //select_option: selectOption,
          startDate: inputData,
          endDate: outputData,
          log_input: 0,
          status_option: selectStatus,
          serial_saida: '',
        }}
        onSubmit={handleSacManagementReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo relatório'
                  variant='outlined'
                  options={selectType}
                  component={Select}
                  onChange={(value) => {
                    handleTypeChange(value);
                    setSelectedType(value);
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='select_option'
                  label='Selecione uma opção'
                  variant='outlined'
                  options={selectedType === 0 ? selectOptionsRegister : selectOptionsLog}
                  component={Select}
                  onChange={(value) => setSelectOption(value)}
                />
              </Grid>
              {selectedType === 0 && selectOption === 'Dt. Pedido' && (
                <>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      name='startDate'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Field
                      size='small'
                      name='endDate'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}
              {selectedType === 0 && selectOption === 'Pedido' && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='log_input'
                    label='Número'
                    type='text'
                    variant='outlined'
                    component={Text}
                    value={props.values.log_input}
                    min={0}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        props.setFieldValue('log_input', 0);
                      }
                    }}
                  />
                </Grid>
              )}
              {selectedType === 1 && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='log_input'
                    label='Número'
                    type='number'
                    variant='outlined'
                    component={Text}
                    value={props.values.log_input}
                    min={0}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        props.setFieldValue('log_input', 0);
                      }
                    }}
                  />
                </Grid>
              )}
              {selectedType === 0 && selectOption === 'Status' && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='status_option'
                    label='Selecione o status'
                    variant='outlined'
                    options={status}
                    component={Select}
                  />
                </Grid>
              )}
              {selectedType === 0 && selectOption === 'Serial Saida' && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='serial_saida'
                    label='Serial Saida'
                    type='text'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
              )}
              {selectedType === 0 && selectOption === 'NF Remessa' && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='nf_remessa'
                    label='NF Remessa'
                    type='number'
                    variant='outlined'
                    component={Text}
                    value={props.values.nf_remessa}
                    min={0}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        props.setFieldValue('nf_remessa', 0);
                      }
                    }}
                  />
                </Grid>
              )}
              {selectedType === 0 && selectOption === 'Serial Entrada' && (
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    name='serial_entrada'
                    label='Serial Entrada'
                    type='text'
                    variant='outlined'
                    component={Text}
                  />
                </Grid>
              )}
              <Grid item xs={12} lg={4}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.startDate);
              setOutputData(props.values.endDate);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
