import { React, useState } from 'react';

import Button from '@material-ui/core/Button';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { FormatMoney, Spacer } from 'components';

import { DialogMoreInformation } from './dialog';

export const CardTemplate = ({ image, title, description, className, allInformation }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <CardMedia sx={{ height: 140 }} component='img' src={image} title={title} />
      <CardContent>
        <Typography
          gutterBottom
          sx={{ fontSize: '12px', fontWeight: '600', textAlign: 'left' }}
          component='div'
        >
          {title}
        </Typography>
        <Spacer size={13} />
        <Typography
          gutterBottom
          sx={{ fontSize: '11px', fontWeight: '400', textAlign: 'left' }}
          component='div'
        >
          A partir de
        </Typography>
        <Typography
          gutterBottom
          sx={{ fontSize: '22px', fontWeight: '700', textAlign: 'left', color: '#311b92' }}
          component='div'
        >
          <FormatMoney>{allInformation.price}</FormatMoney>
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          fullWidth
          size='small'
          color='secondary'
          onClick={onOpenDialog}
          style={{ fontSize: '12px' }}
        >
          Mais informações
        </Button>
      </CardActions>
      <DialogMoreInformation
        open={openDialog}
        allInformation={allInformation}
        handleClose={handleCloseDialog}
      />
    </Card>
  );
};
