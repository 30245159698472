import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { Local } from '../../../../helpers/getLocalEcm';
import { Sector } from '../../../../helpers/getSectorECM';
import { SubLocal } from '../../../../helpers/getSubLocal';

export const ProductSerilialized = () => {
  const typeReport = [
    { label: 'Locais disponíveis para venda', value: 0 },
    { label: 'Todos', value: 1 },
  ];
  const [selectTypeReport, setSelectTypeReport] = useState(0);
  const typeSetorMacro = [
    { label: '01', value: '01' },
    { label: 'ARMUL', value: 'ARMUL' },
    { label: 'ECM', value: 'ECM' },
    { label: 'FIL', value: 'FIL' },
    { label: 'LOJA', value: 'LOJA' },
    { label: 'MLTECH', value: 'MLTECH' },
    { label: 'RMUL', value: 'RMUL' },
    { label: 'RSAM', value: 'RSAM' },
  ];
  const [selectTypeSetorMacro, setSelectTypeSetorMacro] = useState('');
  const [allTypeSetor, setAllTypeSetor] = useState([]);
  const [allTypeLocal, setAllTypeLocal] = useState([]);
  const [allTypeSubLocal, setAllTypeSubLocal] = useState([]);
  const [typeSetor, setTypeSetor] = useState([{ label: '', value: '' }]);
  const [typeLocal, setTypeLocal] = useState([{ label: '', value: '' }]);
  const [typeSubLocal, setTypeSubLocal] = useState([{ label: '', value: '' }]);
  const [selectTypeSubLocal, setSelectTypeSubLocal] = useState('');
  const today = new Date();
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());

  const handleClose = () => {
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const dataSector = await Sector();
    const dataLocal = await Local();
    const dataSubLocal = await SubLocal();

    setAllTypeSetor(dataSector);
    setAllTypeLocal(dataLocal);
    setAllTypeSubLocal(dataSubLocal);
  }, []);

  const getSetor = (dataSector) => {
    const data = [...allTypeSetor];
    const filterData = data
      .filter((item) => item?.setor_macro == dataSector)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeSetor(filterData);
    formRef?.current?.setFieldValue('type_sector', null);
    formRef?.current?.setFieldValue('type_local', null);
    formRef?.current?.setFieldValue('type_subLocal', null);
  };

  useEffect(() => {
    getDados();
  }, [getDados]);

  const getLocal = (idSetores) => {
    console.log('Setores', idSetores);
    const data = [...allTypeLocal];
    const filterData = data
      .filter((item) => item?.idSetores == idSetores)
      .map((item) => ({ value: item.value, label: item.label }));

    setTypeLocal(filterData);
    formRef?.current?.setFieldValue('type_local', null);
    console.log('all', allTypeLocal);
    console.log('Filter', filterData);
  };
  const getSubLocal = (idSetores, idLocal) => {
    const data = [...allTypeSubLocal];
    const filterData = data
      .filter((item) => item?.idSetores === idSetores && item?.idLocal === idLocal)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeSubLocal(filterData);
    formRef?.current?.setFieldValue('type_subLocal', null);
  };

  const formRef = useRef();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);

    const now = new Date().toLocaleDateString();

    try {
      values.type_report;
      values.type_macroSector;
      values.type_sector;
      values.type_local;
      values.type_subLocal;
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getProductsSerializedReport(
        values.type_report,
        values.type_macroSector,
        values.type_sector,
        values.type_local,
        values.type_subLocal,
        formattedInputData,
        formattedOutputData,
      );
      if (result && result.data && result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          ProductsSerializedReport: worksheet,
        },
        SheetNames: ['ProductsSerializedReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Estoque_Serializado-EstoqueVenda${now}.xlsx`);

      toast.success('Relatório gerado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível gerar o relatório.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Estoque Serializado - Estoque Venda</PageTitle>

      <Formik
        initialValues={{
          type_report: selectTypeReport,
          type_macroSector: selectTypeSetorMacro,
          type_sector: '',
          type_local: '',
          type_subLocal: selectTypeSubLocal,
          input_data: inputData,
          output_data: outputData,
        }}
        innerRef={formRef}
        //   validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo relatório'
                  variant='outlined'
                  options={typeReport}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                  disabled={props.values.type_report === 0}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                  disabled={props.values.type_report === 0}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_macroSector'
                  label='Setor macro'
                  variant='outlined'
                  options={typeSetorMacro}
                  component={Select}
                  onchange={(e) => getSetor(e)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_sector'
                  label='Setor'
                  variant='outlined'
                  options={typeSetor}
                  component={Select}
                  onChange={(e) => getLocal(e)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_local'
                  label='Local'
                  variant='outlined'
                  options={typeLocal}
                  component={Select}
                  onChange={(e) => getSubLocal(props.values.type_sector, e)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_subLocal'
                  label='Sub-local'
                  variant='outlined'
                  options={typeSubLocal}
                  component={Select}
                  onChange={(e) => setSelectTypeSubLocal(e)}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                  type='submit'
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeSetorMacro(props.values.type_macroSector);
              getSetor(props.values.type_macroSector);
            }, [props.values.type_macroSector])}
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
