import XLSX from 'xlsx';

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const downloadXlsx = async (namefile, data) => {
  const now = formatDate(new Date());

  const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));

  const workbook = (XLSX.WorkBook = {
    Sheets: {
      Geral: worksheet,
    },
    SheetNames: ['Geral'],
  });
  XLSX.writeFile(workbook, `${namefile}_${now}.xlsx`);
};
