import { createContext, useCallback, useState } from 'react';

import { Dialog, Spacer } from 'components';

import { StepperService } from './components/Stepper';
import { ClientAddressForm } from './steps/ClientAddressForm';
import { ClientForm } from './steps/ClientForm';

export const NewServiceContext = createContext();

export function NewServiceDialog({ isOpen = false, handleClose }) {
  if (!isOpen) {
    return <></>;
  }

  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = useCallback((activeStep) => {
    const steps = {
      0: <ClientForm />,
      1: <ClientAddressForm />,
      // 2: <CardProduct />,
      // 3: <Offer />,
      // 4: getProductTypeId !== 1 ? <SerialValidation /> : <ImeiValidation />,
      // 6: <TradeTerm />,
      // 7: <FinishedTrade />,
      // 8: <OfferNotAvailable />,
    };
    return steps[activeStep];
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <Dialog.Title onClose={handleClose}>Novo atendimento</Dialog.Title>
      <Dialog.Content>
        <StepperService activeStep={activeStep} />

        <Spacer size={64} />
        <NewServiceContext.Provider value={{ setActiveStep }}>
          {getStepContent(activeStep)}
        </NewServiceContext.Provider>
      </Dialog.Content>
    </Dialog>
  );
}
