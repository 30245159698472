import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  numero_nf: Yup.string().required('Campo NF é obrigatório'),
  product_nf: Yup.string().required('Campo Produto NF é obrigatório'),
  intern_product: Yup.string().required('Campo Produto Interno é obrigatório'),
  unit_value: Yup.number().required('Campo Valor Unitário é obrigatório'),
  qtd: Yup.number().required('Campo Qtd é obrigatório').positive('A quantidade deve ser positiva'),
  ncm: Yup.string().length(8, 'O NCM deve ter exatamente 8 dígitos'),
  cst: Yup.string().required('Campo CST é obrigatório'),
  ipi_value: Yup.number().required('Campo Valor IPI é obrigatório'),
  aliq_icms: Yup.number().required('Campo Aliq ICMS é obrigatório'),
  aliq_ipi: Yup.number().required('Campo Aliq IPI é obrigatório'),
  bc_icms: Yup.number().required('Campo BC ICMS é obrigatório'),
  icms_value: Yup.number().required('Campo Valor ICMS é obrigatório'),
  total_value: Yup.number().required('Campo Valor Total é obrigatório'),
  cmsst_value: Yup.number().required('Campo Valor CMSST é obrigatório'),
  cmsst_qtd: Yup.number().required('Campo Qtd CMSST é obrigatório'),
});

export const RegisterNewItem = ({ handleBackToNf }) => {
  return (
    <>
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Formik
          initialValues={{
            numero_nf: '',
            product_nf: '',
            intern_product: '',
            unit_value: '',
            qtd: '',
            total_item: '',
            ncm: '',
            cst: '',
            ipi_value: '',
            aliq_icms: '',
            aliq_ipi: '',
            bc_icms: '',
            icms_value: '',
            cmsst_value: '',
            cmsst_qtd: '',
            total_value: '',
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='NF'
                    type='text'
                    fullWidth
                    name='numero_nf'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Produto NF'
                    type='text'
                    fullWidth
                    name='product_nf'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Produto Interno'
                    type='text'
                    fullWidth
                    name='intern_product'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valou Unitário'
                    type='text'
                    fullWidth
                    name='unit_value'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Qtd'
                    type='text'
                    fullWidth
                    name='qtd'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Total do Item'
                    type='text'
                    fullWidth
                    name='total_item'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='NCM'
                    type='text'
                    fullWidth
                    name='ncm'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='CST'
                    type='text'
                    fullWidth
                    name='cst'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor IPI'
                    type='text'
                    fullWidth
                    name='ipi_value'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Aliq ICMS'
                    type='text'
                    fullWidth
                    name='aliq_icms'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Aliq IPI'
                    type='text'
                    fullWidth
                    name='aliq_ipi'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='BC ICMS'
                    type='text'
                    fullWidth
                    name='bc_icms'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor ICMS'
                    type='text'
                    fullWidth
                    name='icms_value'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor CMSST'
                    type='text'
                    fullWidth
                    name='cmsst_value'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Qtd CMSST'
                    type='text'
                    fullWidth
                    name='cmsst_qtd'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={12}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Valor Total'
                    type='text'
                    fullWidth
                    name='total_value'
                    component={Text}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}></Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Button onClick={() => handleBackToNf()} color='secondary' fullWidth>
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Button type='submit' fullWidth>
                    Salvar Item
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
