import { useRouteMatch } from 'react-router';

import { Alternate } from 'pages/Private/Stock/Alternate';
import { RegisterNfEntryParts } from 'pages/Private/Stock/RegisterNfEntryParts';

import { Route } from '../Route';

export const StockRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/alternate`} component={Alternate} />
      <Route isPrivate path={`${url}/nf-entry-parts`} component={RegisterNfEntryParts} />
    </>
  );
};
