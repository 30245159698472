import React, { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableMUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { isReactComponent, getReactChildrenComponent } from 'helpers/react';

import { RowsEmpty } from './Empty';
import { RowsLoader } from './Loader';
import { useStyles } from './styles';

const TableComponent = ({
  headers = [],
  size = 'medium',
  loading = false,
  rowsPerPage,
  page,
  actionsLabel = '*',
  emptyMessage,
  children,
  onChangePage,
  onChangeRowsPerPage,
  onActionsClick,
  disableNumeration,
  striped,
  maxHeight,
  stickyHeader,
  data = [],
  border,
}) => {
  const classes = useStyles({
    maxHeight,
  });

  const rows = React.useMemo(() => {
    return React.Children.map(children, (child) => {
      if (!isReactComponent(child, TableRow)) return;

      const cells = getReactChildrenComponent(child?.props?.children, TableCell).reduce(
        (acc, child) => {
          return [...acc, child.props];
        },
        [],
      );

      return { ...child.props, cells };
    });
  }, [children]);

  const rowCount = rows?.length;
  const columnsCount = onActionsClick ? headers.length + 2 : headers.length + 1;

  const pageRows =
    rowsPerPage > 0 ? rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows;

  return (
    <>
      <TableContainer className={classes.root} maxHeight>
        <TableMUI size={size} stickyHeader={!!maxHeight || stickyHeader}>
          <TableHead className={classes.head}>
            <React.Fragment>
              <TableRow>
                {!disableNumeration && (
                  <TableCell variant='head' align='center' width={48}>
                    #
                  </TableCell>
                )}
                {headers.map((header) => (
                  <TableCell
                    key={header.label}
                    variant='head'
                    width={header.width}
                    align={header.align || 'left'}
                  >
                    {header.label}
                  </TableCell>
                ))}
                {onActionsClick && (
                  <TableCell variant='head' align='center' width={50} size='small'>
                    {actionsLabel}
                  </TableCell>
                )}
              </TableRow>
            </React.Fragment>
          </TableHead>
          <TableBody>
            {!pageRows?.length && !loading && (
              <RowsEmpty emptyMessage={emptyMessage} columnsCount={columnsCount} />
            )}
            {loading && <RowsLoader columnsCount={columnsCount} />}
            {!loading &&
              pageRows?.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={`table-row-cell-${index}`}
                    hover
                    className={striped && classes.tableRowRoot}
                  >
                    {!disableNumeration && <TableCell align='center'>{index + 1}.</TableCell>}
                    {row.cells.map(({ width, align, children, ...rest }, index) => (
                      <TableCell
                        key={`table-row-cell-${index}`}
                        width={width}
                        align={align}
                        {...rest}
                      >
                        {children}
                      </TableCell>
                    ))}
                    {onActionsClick && (
                      <TableCell width={50}>
                        <IconButton onClick={(e) => onActionsClick(e, data[index])} size='small'>
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableMUI>
      </TableContainer>
      {!!rowsPerPage && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 75, 100, 200]}
          component='div'
          count={rowCount}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage='Linhas por página:'
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : ''}`
          }
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  );
};

export const Table = memo(TableComponent);
