import { api } from 'services/api';

export class CashierService {
  async getRealTimeProductSearch(search) {
    return await api.get(`/cashier/rapid/${search}`);
  }

  async getRealTimeProductSearchByFile(arrSearch) {
    return await api.post(`/cashier/rapid-file`, arrSearch);
  }
}

export const cashierService = new CashierService();
