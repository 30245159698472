import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

import { Operacao } from '../../../../helpers/getOperation';

export const LotNfReceiptReport = () => {
  const [typeOpr, setTypeOpr] = useState([{ label: '', value: '' }]);
  const [selectTypeOpr, setSelectTypeOpr] = useState('');
  const today = new Date();
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const data = await Operacao();
    const firstValue = [{ label: 'TODOS', value: '0' }, ...data];
    setTypeOpr(firstValue);
  }, []);
  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleLotNfReceiptReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getLotNfReceiptReport(
        selectTypeOpr,
        formattedInputData,
        formattedOutputData,
      );

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          LotNfReceiptReport: worksheet,
        },
        SheetNames: ['LotNfReceiptReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Lote_Nf_Recebimento${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };

  return (
    <>
      <PageTitle>Relatório Lote X NF Recebimento</PageTitle>

      <Formik
        initialValues={{
          type_operation: selectTypeOpr,
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleLotNfReceiptReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_operation'
                  label='Operação'
                  variant='outlined'
                  options={typeOpr}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setSelectTypeOpr(props.values.type_operation);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
