// import { version } from '../package.json';

export const ENV = (process.env.NODE_ENV || '').trim();

export const IS_PROD = ENV === 'production';
export const IS_DEV = ENV !== 'production';

export const API_URL = 'https://gaia-refurb.techcenter.net.br:3333';

export const EXTERNAL_API_URL = 'https://gaia-apple.techcenter.net.br:3333';

export const EXTERNAL_API_URL_XPCELL = 'https://gaia-mot.techcenter.net.br:3336';

export const MARK_NAME = 'MOTOROLA';
export const AWS_BUCKET_URL = 'https://s3.sa-east-1.amazonaws.com/xpcell.s3.centercell.com.br';

export const APP_VERSION = '1.0.0';
