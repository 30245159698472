import React from 'react';
import { useRouteMatch } from 'react-router';

import { MarketPrice } from 'pages/Private/Cashier/MarketPrice';

import { Route } from '../Route';

export const CashierRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/market-price`} component={MarketPrice} />
    </>
  );
};
