import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Select, Button } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';

import { Product } from '../../../../helpers/typeProd';
import { providersService } from '../../../../services';

export const ProductsXAtribuctsReport = () => {
  const [typeProd, setTypeProd] = useState([{ label: '', value: '' }]);
  const [selectTypeProd, setSelectTypeProd] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getProduct = useCallback(async () => {
    const data = await Product();
    const uniqueData = data.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.label === item.label && t.value === item.value),
    );
    const firstValue = [{ label: 'TODOS', value: '0' }, ...uniqueData];
    setTypeProd(firstValue);
  }, []);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const handleDownloadProductsXAtribuctsReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const result = await providersService.getProductsXAtribuctsReports(selectTypeProd);

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          ProductsXAtribucts: worksheet,
        },
        SheetNames: ['ProductsXAtribucts'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_ProdutosXAtributos${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };
  return (
    <>
      <PageTitle>Relatório de Produtos X Atributos</PageTitle>

      <Formik initialValues={{ type_prod: selectTypeProd }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_prod'
                  label='Tipo de Produto'
                  variant='outlined'
                  options={typeProd}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  onClick={handleDownloadProductsXAtribuctsReport}
                  disabled={isLoading == 1}
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeProd(props.values.type_prod);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
