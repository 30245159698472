import { api } from 'services/api';

export class RegisterService {
  async getCepInfo(cep) {
    try {
      const response = await api.get(`https://viacep.com.br/ws/${cep}/json/`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar CEP:', error);
      throw error;
    }
  }

  async createBrand(data) {
    return api.post('/register/createBrand', data);
  }
  async findBrands(data) {
    return api.get('/register/findBrands', data);
  }
  async updateActive(data) {
    return api.put('/register/updateActive', data);
  }
  async updateBrand(data) {
    return api.put('/register/updateBrand', data);
  }
  async createModel(data) {
    return api.post('/register/createModel', data);
  }
  async findModels() {
    return api.get('/register/findModels');
  }
  async updateModel(data) {
    return api.put('/register/updateModel', data);
  }
  async updateModelsActive(data) {
    return api.put('/register/updateModelsActive', data);
  }
  async createClient(data) {
    return api.post('/register/createClient', data);
  }
  async findClients() {
    return api.get('/register/findClients');
  }
  async createTypeProduct(data) {
    return api.post('/register/createTypeProduct', data);
  }
  async findTypeProduct() {
    return api.get('/register/findTypeProduct');
  }
  async updateTypeProduct(data) {
    return api.put('/register/updateTypeProduct', data);
  }
  async updateTypeProductActive(data) {
    return api.put('/register/updateTypeProductActive', data);
  }
}

export const registerService = new RegisterService();
