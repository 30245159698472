import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Select, Button } from 'components';
import { Form, Formik, Field } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import XLSX from 'xlsx';
import * as yup from 'yup';

import { Local } from '../../../../helpers/getLocalEcm';
import { Sector } from '../../../../helpers/getSectorECM';
import { SubLocal } from '../../../../helpers/getSubLocal';
import { providersService } from '../../../../services';

const validationSchema = yup.object().shape({
  type_local: yup.number().required().typeError('Campo obrigatório.'),
  type_subLocal: yup.number().required().typeError('Campo obrigatório'),
});

export const StockPositionEcommerceReport = () => {
  const [allTypeLocal, setAllTypeLocal] = useState([]);
  const [allTypeSubLocal, setAllTypeSubLocal] = useState([]);
  const [typeSetor, setTypeSetor] = useState([{ label: '', value: '' }]);
  const [selectTypeSetor, setSelectTypeSetor] = useState('');
  const [typeLocal, setTypeLocal] = useState([{ label: '', value: '' }]);
  const [selectTypeLocal, setSelectTypeLocal] = useState('');
  const [typeSubLocal, setTypeSubLocal] = useState([{ label: '', value: '' }]);
  const [selectTypeSubLocal, setSelectTypeSubLocal] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const dataSector = await Sector();
    const filteredDataSector = dataSector.filter((item) => item.setor_macro == 'ECM');

    setSelectTypeSetor(filteredDataSector.length > 0 ? filteredDataSector[0].value : '');

    const filteredTypeSetor = filteredDataSector.map((item) => ({
      value: item.value,
      label: item.label,
    }));
    setTypeSetor(filteredTypeSetor);
    const dataLocal = await Local();
    const dataSubLocal = await SubLocal();

    setAllTypeLocal(dataLocal);
    setAllTypeSubLocal(dataSubLocal);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const getLocal = (idSetores) => {
    const data = [...allTypeLocal];
    const filterData = data
      .filter((item) => item?.idSetores == idSetores)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeLocal(filterData);
    formRef?.current?.setFieldValue('type_local', null);
  };

  const getSubLocal = (idSetores, idLocal) => {
    const data = [...allTypeSubLocal];
    const filterData = data
      .filter((item) => item?.idSetores === idSetores && item?.idLocal === idLocal)
      .map((item) => ({ value: item.value, label: item.label }));
    setTypeSubLocal(filterData);
    formRef?.current?.setFieldValue('type_subLocal', null);
  };
  const formRef = useRef();

  const handleStockPositionEcommerceReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);

    const now = new Date().toLocaleDateString();

    try {
      const result = await providersService.getStockPositionEcommerceReport(
        selectTypeSetor,
        selectTypeLocal,
        selectTypeSubLocal,
      );
      if (result && result.data && result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          StockPositionEcommerce: worksheet,
        },
        SheetNames: ['StockPositionEcommerce'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Posição_Estoque_Ecommerce${now}.xlsx`);

      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Posição Estoque Ecommerce</PageTitle>

      <Formik
        initialValues={{
          type_sector: selectTypeSetor,
          type_local: selectTypeLocal,
          type_subLocal: selectTypeSubLocal,
        }}
        innerRef={formRef}
        validationSchema={validationSchema}
        onSubmit={handleStockPositionEcommerceReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_sector'
                  label='Setor'
                  variant='outlined'
                  options={typeSetor}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_local'
                  label='Local'
                  variant='outlined'
                  options={typeLocal}
                  component={Select}
                  onChange={setSelectTypeLocal}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_subLocal'
                  label='Sub-local'
                  variant='outlined'
                  options={typeSubLocal}
                  component={Select}
                  onChange={setSelectTypeSubLocal}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                  type='submit'
                >
                  Exportar Relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setSelectTypeSetor(props.values.type_sector);
              getLocal(props.values.type_sector);
            }, [props.values.type_sector])}
            {useEffect(() => {
              setSelectTypeLocal(props.values.type_local);
              getSubLocal(props.values.type_sector, props.values.type_local);
            }, [props.values.type_sector, props.values.type_local])}
          </Form>
        )}
      </Formik>
    </>
  );
};
