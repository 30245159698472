import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { readCsv } from 'helpers/readcsv';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { shoppingService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const ViewBase = () => {
  const [radioValue, setRadioValue] = useState('VLR_ATACADO');
  const [isLoading, setIsLoading] = useState(false);
  const inputFileRef = useRef(null);
  const now = new Date().toLocaleDateString('pt-BR');
  const user = useContextSelector(AuthContext, (context) => context.user);
  // const [data, setData] = useState([]);

  const handleReadCsv = async () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const hanldeReadDataCsv = async (e) => {
    setIsLoading(true);
    toast.info('importando dados ...');
    try {
      const data_csv = await readCsv(e);
      console.log('data_csv', data_csv);
      const { data } = await shoppingService.importBase({
        data_csv: data_csv,
        radioValue: radioValue,
        name: user.name,
      });
      await downloadXlsx(`Nova_Base_'${now}`, data.data_csv);
      setIsLoading(false);
      toast.success('Dados importados com sucesso');
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Erro ao ler CSV');
    }
  };

  const handleDownloadBase = async () => {
    const { data } = await shoppingService.DownloadBase();
    console.log(data);
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(event) => hanldeReadDataCsv(event)}
        accept='text/csv'
        hidden
      />
      <PageTitle>Cadastro / Visualização da Base de Compras</PageTitle>
      <Formik initialValues={{ file: null }}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControl component='fieldset'>
                  <RadioGroup
                    onChange={(event) => setRadioValue(event.target.value)}
                    defaultValue='VLR_ATACADO'
                    row
                    aria-label='operation'
                    name='operation'
                  >
                    <FormControlLabel
                      value='VAREJO'
                      control={<Radio color='primary' />}
                      label='VAREJO'
                      disabled={isLoading}
                    />
                    <FormControlLabel
                      value='VLR_ATACADO'
                      control={<Radio color='primary' />}
                      label='VALOR ATACADO (60% MERC)2'
                      disabled={isLoading}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  color='primary'
                  variant='contained'
                  onClick={handleReadCsv}
                  disabled={isLoading}
                >
                  importar csv
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
