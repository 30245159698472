import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';

export const DialogGenerateNf = ({ handleCloseDialogGenerateNf, openModal }) => {
  return (
    <>
      <Dialog open={openModal}>
        <Dialog.Title onClose={handleCloseDialogGenerateNf}>Gerando Nota Fiscal ...</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} align='center'>
              <Typography variant='subtitle1'>
                Uma Nota Fiscal (NF) será gerada para esse cadastro. Lembre-se de anotá-la para
                utilização no XPCell.
              </Typography>
            </Grid>
          </Grid>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} lg={4}>
              <Button onClick={handleCloseDialogGenerateNf} fullWidth>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
