import { api } from 'services/api';

export class ShoppingService {
  async importBase(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/import-base', data, {
      headers: {
        'X-Client-URL': window.location.href, // Adiciona a URL atual do front-end
      },
    });
  }

  async getDataTableApprove(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/data-table-approve', data);
  }
  async DownloadBase() {
    return api.get('/xpcell-multimarcas-shopping/shopping/download-base');
  }

  async upadateApproveBase(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/update-approve-base', data);
  }

  async reproveApproveBase(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/reprove-approve-base', data);
  }

  async exportBase(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/export-base', data);
  }
  async importItensForApprove(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/import-itens-for-Approve', data, {
      headers: {
        'X-Client-URL': window.location.href, // Adiciona a URL atual do front-end
      },
    });
  }
  async getBaseForApprove(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/get-base-for-approve', data);
  }
  async approveParcialBase(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/approve-parcial-base', data);
  }

  async approveWithCsv(data) {
    return api.post('/xpcell-multimarcas-shopping/shopping/approve-with-csv', data);
  }

  async justifyReproveCsv(data) {
    console.log(data);
    return api.post('/xpcell-multimarcas-shopping/shopping/justify-reprove-csv', data);
  }
}

export const shoppingService = new ShoppingService();
