export const Operation = () => {
  return [
    { label: 'TODOS', value: 0 },
    { label: 'E-COMMERCE', value: 7 },
    { label: 'RFB CENTER CELL', value: 10 },
    { label: 'RFB SAMSUNG', value: 11 },
    { label: 'RFB TECH CENTER', value: 19 },
    { label: 'NÃO SERIALIZADO', value: 20 },
  ];
};
