import { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { NewServiceDialog } from './components/NewServiceDialog';
import { ServiceTable } from './components/ServiceTable';

export function Services() {
  const [isOpenNewServiceDialog, setIsOpenNewServiceDialog] = useState(false);

  function handleOpenNewServiceDialog() {
    setIsOpenNewServiceDialog(true);
  }

  function handleCloseNewServiceDialog() {
    setIsOpenNewServiceDialog(false);
  }

  return (
    <>
      <PageTitle>Atendimentos</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Button
            fullWidth
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleOpenNewServiceDialog}
          >
            Novo atendimento
          </Button>
        </Grid>
      </Grid>

      <Spacer size={64} />
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ServiceTable />
        </Grid>
      </Grid>

      <NewServiceDialog isOpen={isOpenNewServiceDialog} handleClose={handleCloseNewServiceDialog} />
    </>
  );
}
