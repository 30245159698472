import React from 'react';
import { Helmet } from 'react-helmet';

import Grid from '@material-ui/core/Grid';
import './styles.css';
import CopyrightIcon from '@material-ui/icons/Copyright';

export const Footer = () => {
  return (
    <Grid className='root' container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <p className='paragraph'>
          Gaia System <CopyrightIcon className='icon' /> Todos os direitos reservados.
        </p>
      </Grid>
      <Grid item lg={3} />
      <Grid item xs={12} lg={2}>
        <span id='siteseal'>
          {
            <Helmet>
              <script
                async
                type='text/javascript'
                src='https://seal.godaddy.com/getSeal?sealID=bll3XfLiWMbrsJzoKZwLjHhh7eW2SEc2VAjnITfBb9VbvKBbj9S8NTSEb4i3'
              />
            </Helmet>
          }
        </span>
      </Grid>
    </Grid>
  );
};
