import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { registerService } from 'services/register';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { DialogConfirm } from './Dialog/DialogConfirm';
import { DialogEdit } from './Dialog/DialogEdit';

const columns = [{ label: 'Tipo Produto' }, { label: 'Alterar' }, { label: 'Excluir' }];

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('Preencha o campo tipo produto'),
});

export const RegisterTypeProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [table, setTable] = useState([]);
  const [line, setLine] = useState();
  const user = useContextSelector(AuthContext, (context) => context.user);
  const userName = user.id;

  const handleOpenDialogConfirm = (item) => {
    setOpenDialogConfirm(true);
    setLine(item);
  };

  const handleCloseDialogConfirm = () => {
    setOpenDialogConfirm(false);
    fetchData();
  };

  const handleOpenDialogEdit = (item) => {
    setOpenDialogEdit(true);
    setLine(item);
  };

  const handleCloseDialogEdit = () => {
    setOpenDialogEdit(false);
    fetchData();
  };

  const processTypeProduct = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await registerService.createTypeProduct({
        nome: values.input_name,
        user: userName,
      });
      fetchData();
      toast.success('Cadastrado com sucesso!');
    } catch (error) {
      console.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    const { data } = await registerService.findTypeProduct();
    setTable(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DialogConfirm
        handleCloseDialogConfirm={handleCloseDialogConfirm}
        openDialogConfirm={openDialogConfirm}
        line={line}
      />

      <DialogEdit
        handleCloseDialogEdit={handleCloseDialogEdit}
        openDialogEdit={openDialogEdit}
        line={line}
      />
      <PageTitle>Cadastro Tipo Produto</PageTitle>
      <Formik
        initialValues={{ input_name: '' }}
        validationSchema={validationSchema}
        onSubmit={processTypeProduct}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <Field
                size='small'
                loading={isLoading}
                name='input_name'
                variant='outlined'
                fullWidth
                label='Tipo Produto'
                component={Text}
              />
            </Grid>
            <Grid item xs={3} md={3} lg={3}>
              <Button type='submit' fullWidth loading={isLoading}>
                Gravar
              </Button>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              {table.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.descricao}</TableCell>
                  <TableCell>
                    <EditIcon
                      style={{ color: '#512da8', cursor: 'pointer' }}
                      fullWidth
                      onClick={() => handleOpenDialogEdit(item)}
                    ></EditIcon>
                  </TableCell>
                  <TableCell>
                    {(item.ativo === true && (
                      <Grid container>
                        <Grid item xs={12} md={3}>
                          <Button
                            color='secondary'
                            fullWidth
                            onClick={() => handleOpenDialogConfirm(item)}
                          >
                            Desativar
                          </Button>
                        </Grid>
                      </Grid>
                    )) ||
                      (item.ativo === false && (
                        <Grid container>
                          <Grid item xs={12} md={3}>
                            <Button fullWidth onClick={() => handleOpenDialogConfirm(item)}>
                              Ativar
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
