import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Button, DatePicker, Table } from 'components';
import { saveAs } from 'file-saver';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';

import { receiptService } from '../../../../services/receipt';
import { DialogChangeTypeSend } from './DialogChangeTypeSend';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export const GenerateMinuteDoca = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [dataTable, setDataTable] = useState([]);
  const [selectedNfs, setSelectedNfs] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [idCadastro, setIdCadastro] = useState('');
  const [input_data, setInputData] = useState(new Date());
  const [output_data, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const getDataTableMinuta = async (input_data, output_data) => {
    try {
      const { data } = await receiptService.getDataTableMinuta({
        input_data: input_data,
        output_data: output_data,
      });
      return data;
    } catch {
      toast.error('Erro ao buscar dados');
    }
  };

  const handleGetDataTable = async (values) => {
    try {
      setIsLoading(true);
      const data = await getDataTableMinuta(values.input_data, values.output_data);
      if (data[0].status === 500) {
        toast.error('Não há registros para essa data');
      } else {
        setDataTable(data);
        setSelectedNfs([]);
        setSelectAll(false);
        setInputData(values.input_data);
        setOutputData(values.output_data);
      }
      setIsLoading(false);
    } catch {
      toast.error('Erro ao procurar dados');
      setIsLoading(false);
    }
  };
  const handleCheckboxChange = (index) => {
    const updatedSelectedNfs = [...selectedNfs];
    const dataTableData = [...dataTable];
    const selectedRow = dataTableData[index];
    let lenSelected = 0;

    if (updatedSelectedNfs.includes(selectedRow)) {
      const filteredUpdatesSelectedNfs = updatedSelectedNfs.filter(
        (item) => item.idCadastro !== selectedRow.idCadastro,
      );
      lenSelected = filteredUpdatesSelectedNfs.length;
      setSelectedNfs(filteredUpdatesSelectedNfs);
    } else {
      updatedSelectedNfs.push(selectedRow);
      setSelectedNfs(updatedSelectedNfs);
      lenSelected = updatedSelectedNfs.length;
    }
    setSelectAll(lenSelected === dataTableData.length);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleCheckedAll = () => {
    if (selectAll) {
      setSelectedNfs([]);
    } else {
      setSelectedNfs(dataTable);
    }
    setSelectAll(!selectAll);
  };

  const handleGeneratePDF = async () => {
    try {
      setIsLoading(true);
      if (selectedNfs.some((item) => item.Tipo_Envio === 'Pendente')) {
        toast.error(
          'Não é possível enviar NFs com status de envio PENDENTE. Altere ou remova NF da geração',
        );
      } else {
        const { data } = await receiptService.generatePDF({ pdf: selectedNfs, user: user.name });
        const file = new Blob([data], { type: 'application/pdf' });
        saveAs(file, 'minuta.pdf');
        toast.success('PDF gerado com sucesso');
        setIsLoading(false);
        location.reload();
      }
    } catch {
      toast.error('Erro ao gerar PDF');
      setIsLoading(false);
    }
  };

  const filteredData = dataTable.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === 'string' && value.toLowerCase().includes(filterText.toLowerCase()),
    ),
  );

  const columns = [
    { label: <Checkbox onChange={handleCheckedAll} checked={selectAll} /> },
    { label: 'Recebimento NF' },
    { label: 'Operacao' },
    { label: 'CNPJ' },
    { label: 'Fornecedor' },
    { label: 'Chave' },
    { label: 'Num NF' },
    { label: 'User Cadastro' },
    { label: 'Data Envio' },
    { label: 'Tipo de Envio' },
  ];

  const HandleOpenDialog = (idCadastro) => {
    setIdCadastro(idCadastro);
    setOpenDialog(true);
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
    const data = await getDataTableMinuta(input_data, output_data);
    setDataTable(data);
    setSelectedNfs([]);
  };
  return (
    <>
      <DialogChangeTypeSend
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        idCadastro={idCadastro}
      ></DialogChangeTypeSend>
      <PageTitle>Geração de Minuta</PageTitle>

      <Formik
        initialValues={{
          input_data: new Date(),
          output_data: new Date(),
        }}
        onSubmit={handleGetDataTable}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Inicio'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data Final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button isLoading={isLoading} fullWidth type='submit'>
                  Filtrar
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  isLoading={isLoading}
                  onClick={handleGeneratePDF}
                  fullWidth
                  color={'secondary'}
                  disabled={selectedNfs.length === 0}
                >
                  Gerar PDF
                </Button>
              </Grid>
              <Grid item xs={12} lg={12}>
                <TextField
                  size='small'
                  fullWidth
                  variant='outlined'
                  label='Pesquisar'
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Grid xs={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {filteredData &&
                    filteredData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            {...label}
                            checked={selectedNfs.includes(item)}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </TableCell>
                        <TableCell>{formatDate(item.Recebimento_NF)}</TableCell>
                        <TableCell>{item.Operacao}</TableCell>
                        <TableCell>{item.CNPJ}</TableCell>
                        <TableCell>{item.Fornecedor}</TableCell>
                        <TableCell>{item.Chave}</TableCell>
                        <TableCell>{item.NumNF}</TableCell>
                        <TableCell>{item.User_Cadastro}</TableCell>
                        <TableCell>{formatDate(item.DataEnvio)}</TableCell>
                        <TableCell>
                          {(item.Tipo_Envio === 'Pendente' && (
                            <Button onClick={() => HandleOpenDialog(item.idCadastro)}>
                              {item.Tipo_Envio}
                            </Button>
                          )) ||
                            item.Tipo_Envio}
                        </TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};
