import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, CustomAutocomplete, DatePicker, Dialog, Select, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { receiptService } from '../../../../services/receipt';
import { DialogGenerateNf } from './DialogGenerateNf';

const columns = [
  { label: 'Chave' },
  { label: 'Cnpj' },
  { label: 'Fornecedor' },
  { label: 'Operação' },
  { label: 'Data Recebimento' },
  { label: 'Tipo de Envio' },
];

export const DialogRegisterNF = ({ handleCloseDialog, openDialog, numNfs }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const validationSchema = Yup.object().shape({
    num_nf: Yup.string()
      .required('Número da NF é obrigatório')
      .min(44, 'O número da NF deve ter 44 caracteres'),
    cnpj: Yup.string()
      .required('CNPJ é obrigatório')
      .matches(/^\d{14}$/, 'CNPJ deve ter 14 dígitos'),
    fornecedor: Yup.string().required('Fornecedor é obrigatório'),
    operacao: Yup.object().nullable().required('Operação é obrigatória'),
    input_data: Yup.date().required('Data de recebimento é obrigatória'),
    type_send: Yup.object().nullable().required('Tipo de envio é obrigatório'),
  });

  const operations = [
    { label: 'TECH CENTER', value: '0' },
    { label: 'CENTER CELL', value: '1' },
    { label: 'FURB TECH PAN', value: '3' },
  ];
  const typeSend = [
    { label: 'Pagar', value: '0' },
    { label: 'Não pagar', value: '1' },
    { label: 'Pendente', value: '2' },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [keepData, setKeepData] = useState(false);

  const [suppliers, setSuppliers] = useState([{ label: '', value: '' }]);
  const [registeredNfs, setRegisteredNfs] = useState([]);
  const maxSize = numNfs;

  const handleCloseDialogGenerateNf = () => {
    setOpenModal(false);
  };

  const handleCheckboxChange = async (values, setFieldValue) => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (newChecked) {
      let lastNf;
      for (let i = 0; i < registeredNfs.length; i++) {
        if (String(registeredNfs[i].num_nf).startsWith('00000000')) {
          lastNf = registeredNfs[i].num_nf;
        }
      }

      if (!!lastNf) {
        lastNf = String(parseInt(lastNf) + 1).padStart(44, '0');
      }

      const { data } = await receiptService.generateNf();
      setFieldValue('num_nf', lastNf ?? data.key);
      setFieldValue('cnpj', data.cnpj);
      setFieldValue('fornecedor', 'FURBTECH ELETROELETRONICOS LTDA');
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  };

  const handleclickreset = async (values, setFieldValue) => {
    if (!keepData) {
      inputFileRef.current.click();
    } else {
      setFieldValue.setFieldValue('num_nf', '');
    }
  };
  const inputFileRef = useRef(null);

  const handleGetSuppliers = async (cnpj) => {
    const { data } = await receiptService.getSupplier(cnpj);
    setSuppliers(data);
  };

  const handleValidateKeyNf = async (num_nf) => {
    try {
      const { data } = await receiptService.validateKeyNf({ num_nf: num_nf });
      if (data.msg == 'Chave válida') {
        toast.success('Chave pode ser cadastrada');
      } else {
        toast.error('Chave inválida: Já existe um registro com essa NF');
      }
    } catch {
      toast.error('Erro ao validar NF');
    }
  };

  const handleBack = async (setFieldValue) => {
    setRegisteredNfs([]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const resetState = () => {
    setRegisteredNfs([]);
    setIsChecked(false);
  };

  const handleRegisterNf = async (values, setFieldValue) => {
    if (registeredNfs.length < maxSize) {
      const temp = registeredNfs;
      temp.push(values);

      setRegisteredNfs(temp);
      await handleclickreset(values, setFieldValue);
      setSuppliers([{ label: '', value: '' }]);
      setIsChecked(false);
    }
  };

  const handleSendAllNfs = async () => {
    if (registeredNfs.length != maxSize) {
      toast.error('Você precisa registrar pelo menos ' + maxSize + ' Notas');
    } else {
      try {
        const { data } = await receiptService.registerNf({
          registeredNfs: registeredNfs,
          user: user.name,
        });
        if (data.status === 200) {
          toast.success('Sucesso ao registrar as Nfs');
        } else {
          toast.error('Erro ao registrar as Nfs');
        }
      } catch {
        return toast.error('Erro ao registrar Nfs');
      }
      handleCloseDialog();
      location.reload();
    }
  };

  const handleSetTypeSend = (props) => {
    console.log('Valor recebido:', props.values);
    if (
      props.values.fornecedor.toLowerCase().startsWith('samsung') ||
      props.values.fornecedor.toLowerCase().startsWith('lg') ||
      props.values.fornecedor.toLowerCase().startsWith('motorola')
    ) {
      props.setFieldValue('type_send', { label: 'Pagar', value: '0' });
    }
  };

  return (
    <>
      <DialogGenerateNf
        handleCloseDialogGenerateNf={handleCloseDialogGenerateNf}
        openModal={openModal}
      ></DialogGenerateNf>
      <Dialog maxWidth={'lg'} open={openDialog}>
        <Dialog.Title
          onClose={() => {
            handleCloseDialog();
            resetState();
          }}
        >
          Cadastro de NF
        </Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              num_nf: '',
              cnpj: '',
              fornecedor: { label: '', value: '' },
              operacao: '',
              input_data: new Date(),
              type_send: '',
            }}
            onClick={handleCheckboxChange}
            onReset={handleclickreset}
            validationSchema={validationSchema}
            onSubmit={handleRegisterNf}
          >
            {(props) => (
              <Form>
                <input ref={inputFileRef} type='reset' hidden />
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <FormGroup row>
                      <Grid container direction='row'>
                        <Grid item xs={6} lg={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                checked={keepData}
                                onChange={() => setKeepData(!keepData)}
                              />
                            }
                            label=' Manter Dados'
                          />
                        </Grid>
                        <Grid item xs={6} lg={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size='small'
                                checked={isChecked}
                                onChange={() =>
                                  handleCheckboxChange(props.values, props.setFieldValue)
                                }
                              />
                            }
                            label='Cadastro sem NF2'
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='num_nf'
                      label='Numero da Chave'
                      type='number'
                      variant='outlined'
                      value={props.values.num_nf}
                      component={Text}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue.length > 44) {
                          inputValue = inputValue.slice(0, 44);
                        }
                        props.setFieldValue('num_nf', inputValue);
                      }}
                      onBlur={() => handleValidateKeyNf(props.values.num_nf)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='cnpj'
                      label='Cnpj'
                      type='text'
                      variant='outlined'
                      component={Text}
                      onBlur={(e) => handleGetSuppliers(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name={'fornecedor'}
                      label={'Fornecedores'}
                      variant='outlined'
                      component={Select}
                      options={suppliers}
                      value={props.values.fornecedor}
                      onBlur={() => handleSetTypeSend(props)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomAutocomplete
                      label='Operação'
                      name='operacao'
                      filteredValues={operations || []}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.operacao ? props.values.operacao.label : ''}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data de recebimento'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomAutocomplete
                      name={'type_send'}
                      label={'Tipo de envio'}
                      filteredValues={typeSend || []}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      value={props.values.type_send ? props.values.type_send.label : ''}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button
                      onClick={() => handleBack(props.setFieldValue)}
                      color='secondary'
                      fullWidth
                      disabled={registeredNfs.length == 0}
                    >
                      Reiniciar coleta
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button fullWidth type='submit'>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid item xs={12}>
                    <Table name='Table' headers={columns} maxHeight>
                      {registeredNfs &&
                        registeredNfs.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.num_nf}</TableCell>
                            <TableCell>{item.cnpj}</TableCell>
                            <TableCell>{item.fornecedor}</TableCell>
                            <TableCell>{item.operacao.label}</TableCell>
                            <TableCell>{formatDate(item.input_data)}</TableCell>
                            <TableCell>{item.type_send.label}</TableCell>
                          </TableRow>
                        ))}
                    </Table>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}></Grid>
            <Grid item xs={12} lg={4}>
              <Button onClick={handleSendAllNfs} fullWidth>
                Enviar Coleta
              </Button>
            </Grid>
            <Grid item xs={12} lg={4}></Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
