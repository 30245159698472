import { useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import UploadIcon from '@mui/icons-material/Upload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Checkbox, DatePicker, Dialog, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { DialogConfirmDelete } from './DialogConfirmDelete';
import { DialogEditNfCabec } from './DialogEditNfCabec';
import { RegisterNewItem } from './DialogNewItem';
import { ViewItens } from './ViewItens';

const columns = [
  { label: 'Nota Fiscal' },
  { label: 'Serie' },
  { label: 'Chave' },
  { label: 'Data de Emissão' },
  { label: 'NF OK' },
  { label: 'Editar Nota' },
  { label: 'Excluir Nota' },
  { label: 'Add Itens' },
  { label: 'Visualizar Itens' },
];

export const DialogRegisterNf = ({ openModalNF, handleCloseModalNF }) => {
  if (!openModalNF) return null;

  const [tabIndex, setTabIndex] = useState(0);
  const [openModalEditNfCabec, setOpenModalEditNfCabec] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [nfCabec, setNfCabec] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const inputFileRef = useRef(null);

  const dataTeste = [
    {
      idCad: 1,
      NotaFiscal: '123',
      Serie: '1',
      Chave: '444444444444444444444444444',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
    {
      idCad: 7,
      NotaFiscal: '445',
      Serie: '25',
      Chave: '45454651235456316541651651',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 126489,
    },
    {
      idCad: 10,
      NotaFiscal: '147',
      Serie: '5',
      Chave: '22222222222222222222222',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
    {
      idCad: 6,
      NotaFiscal: '4126',
      Serie: '10',
      Chave: '11111111111111111111111',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
    {
      idCad: 6,
      NotaFiscal: '4126',
      Serie: '10',
      Chave: '11111111111111111111111',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
    {
      idCad: 6,
      NotaFiscal: '4126',
      Serie: '10',
      Chave: '11111111111111111111111',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
    {
      idCad: 6,
      NotaFiscal: '4126',
      Serie: '10',
      Chave: '11111111111111111111111',
      Transportadora: 'TESTE',
      NumConhecimento: '',
      ativo: 1,
      dataEmissao: '20/08/2024',
      LoginGaia: 'TI',
      LoginGaiaDtv: '',
      dataDtv: '',
      Lote: 456,
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeToRegisterItens = (item) => {
    setTabIndex(1);
  };

  const handleChangeToViewItens = (item) => {
    setTabIndex(2);
  };

  const handleBackToNf = () => {
    setTabIndex(0);
  };

  const handleOpenModalEditNfCabec = async (item) => {
    setNfCabec(item);
    setOpenModalEditNfCabec(true);
  };
  const handleCloseModalEditNfCabec = () => {
    setOpenModalEditNfCabec(false);
  };
  const handleOpenModalConfirmDelete = (item) => {
    setNfCabec(item);
    setOpenModalConfirmDelete(true);
  };
  const handleCloseModalConfirmDelete = () => {
    setOpenModalConfirmDelete(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const filteredData = dataTeste.filter((item) => {
    return (
      item.NotaFiscal.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.Serie.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.Chave.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.dataEmissao.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const handleFileChange = () => {
    // XML
  };

  const handleRegisterNf = (values) => {
    // Register Nf
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <DialogConfirmDelete
        openModalConfirmDelete={openModalConfirmDelete}
        handleCloseModalConfirmDelete={handleCloseModalConfirmDelete}
        nfCabec={nfCabec}
      />
      <DialogEditNfCabec
        openModalEditNfCabec={openModalEditNfCabec}
        handleCloseModalEditNfCabec={handleCloseModalEditNfCabec}
        nfCabec={nfCabec}
      />
      <Dialog maxWidth={'lg'} open={openModalNF}>
        <Dialog.Title onClose={handleCloseModalNF}>Cadastro de Notas Fiscais</Dialog.Title>
        <Dialog.Content>
          <Box component={Paper} boxShadow={3} p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2} md={2} lg={1} BO>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Lote: 23
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={4}>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Fornecedor: SAMSUNG ABCDEFG
                </Typography>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={3}>
                <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Status: Lote OK
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {tabIndex === 0 && (
            <Box mt={2} component={Paper} boxShadow={3} p={2}>
              <Formik
                initialValues={{
                  nf_view: '',
                  serie: '',
                  key: '',
                  input_data: new Date(),
                  search: '',
                }}
                onSubmit={handleRegisterNf}
              >
                {(props) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='NF'
                          type='text'
                          fullWidth
                          name='nf_view'
                          component={Text}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='Serie'
                          type='text'
                          fullWidth
                          name='serie'
                          component={Text}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={3}>
                        <Field
                          size='small'
                          variant='outlined'
                          label='Chave'
                          type='text'
                          fullWidth
                          name='key'
                          component={Text}
                          onChange={(e) => {
                            let inputValue = e.target.value;
                            if (inputValue.length > 44) {
                              inputValue = inputValue.slice(0, 44);
                            }
                            props.setFieldValue('key', inputValue);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Field
                          size='small'
                          variant='inline'
                          fullWidth
                          inputVariant='outlined'
                          label='Data Emissão'
                          name='input_data'
                          component={DatePicker}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <Button type='submit' fullWidth>
                          Registrar
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={2} lg={1}>
                        <Button onClick={handleImportClick} fullWidth startIcon={<UploadIcon />}>
                          XML
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          size='small'
                          name='search'
                          label='Pesquisar'
                          component={Text}
                          variant='outlined'
                          type='text'
                          fullWidth
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={12}>
                        <Table name='Table' size='small' headers={columns} disableNumeration>
                          {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.NotaFiscal}</TableCell>
                                <TableCell>{item.Serie}</TableCell>
                                <TableCell>{item.Chave}</TableCell>
                                <TableCell>{item.dataEmissao}</TableCell>
                                <TableCell>
                                  <Checkbox />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleOpenModalEditNfCabec(item)}
                                    color='primary'
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleOpenModalConfirmDelete(item)}
                                    color='secondary'
                                  >
                                    <HighlightOffIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleChangeToRegisterItens(item)}
                                    color='primary'
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleChangeToViewItens(item)}
                                    color='primary'
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component='div'
                          count={filteredData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          )}

          {tabIndex === 1 && <RegisterNewItem handleBackToNf={handleBackToNf} />}
          {tabIndex === 2 && (
            <ViewItens
              handleBackToNf={handleBackToNf}
              handleChangeToRegisterItens={handleChangeToRegisterItens}
            />
          )}
        </Dialog.Content>
      </Dialog>
    </>
  );
};
