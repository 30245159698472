import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

export const TotalAverageCalculationReport = () => {
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const selectType = [
    { label: 'Data OS', value: 0 },
    { label: 'Data Pedido', value: 1 },
  ];
  const [typeValue, setTypeValue] = useState(0);
  const handleTotalAverageCalculationReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(1);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getTotalAverageCalculationReport(
        typeValue,
        formattedInputData,
        formattedOutputData,
      );

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          TotalAverageCalculationReport: worksheet,
        },
        SheetNames: ['TotalAverageCalculationReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Calculo_Médio_Total${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(0);
    }
  };
  return (
    <>
      <PageTitle>Relatório Calculo Médio Total</PageTitle>

      <Formik
        initialValues={{
          type_report: typeValue,
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleTotalAverageCalculationReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo relatório'
                  variant='outlined'
                  options={selectType}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setTypeValue(props.values.type_report);
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
