import { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import Box from '@mui/material/Box';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  const handleCheckboxChange = (event) => {
    setSelectedType(event.target.name);
  };
  return (
    <>
      <Dialog maxWidth='lg' open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              checkbox_fisica: '',
              checkbox_juridica: '',
              input_name: '',
              input_cpf_cnpj: '',
              input_email: '',
              input_IE: '',
              input_celular: '',
              input_telefone: '',
              input_CEP: '',
              input_rua: '',
              input_numero: '',
              input_bairro: '',
              input_cidade: '',
              input_estado: '',
              input_complemento: '',
            }}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedType === 'Fisica'}
                          onChange={handleCheckboxChange}
                          name='Fisica'
                          color='primary'
                        />
                      }
                      label='Fisica'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedType === 'Juridica'}
                          onChange={handleCheckboxChange}
                          name='Juridica'
                          color='primary'
                        />
                      }
                      label='Juridica'
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_name'
                      variant='outlined'
                      fullWidth
                      label='Nome'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cpf_cnpj'
                      variant='outlined'
                      fullWidth
                      label='CPF/CNPJ'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_email'
                      variant='outlined'
                      fullWidth
                      label='Email'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_IE'
                      variant='outlined'
                      fullWidth
                      label='IE'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_celular'
                      variant='outlined'
                      fullWidth
                      label='Celular'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_telefone'
                      variant='outlined'
                      fullWidth
                      label='Telefone'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_CEP'
                      variant='outlined'
                      fullWidth
                      label='CEP'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_rua'
                      variant='outlined'
                      fullWidth
                      label='Rua'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_numero'
                      variant='outlined'
                      fullWidth
                      label='Número'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_bairro'
                      variant='outlined'
                      fullWidth
                      label='Bairro'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cidade'
                      variant='outlined'
                      fullWidth
                      label='Cidade'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_estado'
                      variant='outlined'
                      fullWidth
                      label='Estado'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_complemento'
                      variant='outlined'
                      fullWidth
                      label='Complemento'
                      component={Text}
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button fullWidth loading={isLoading}>
                      Gravar
                    </Button>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
