import { useState } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';

import { Button, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const OpenOs = ({ handleBack }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenLote = (values) => {
    // handleOpenLote
  };

  return (
    <>
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Button
              style={{
                backgroundColor: 'transparent',
                color: 'inherit',
                border: 'none',
              }}
              startIcon={<InfoIcon color='primary' />}
              onClick={handleExpandClick}
            >
              Info Adiocinal
            </Button>
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px', fontWeight: 'bold' }}>
                  Informações do modelo:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px' }}>
                  Tipo Produto:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px' }}>
                  Descrição:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px' }}>
                  Cor:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px' }}>
                  Banda:
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Typography variant='h3' style={{ fontSize: '18px' }}>
                  Operadora:
                </Typography>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
      <Box mt={2} component={Paper} boxShadow={3} p={2}>
        <Formik
          initialValues={{
            brand: '',
            serial_in: '',
            imei: '',
            model: '',
            parnumber: '',
            complaint: '',
            observation: '',
          }}
          onSubmit={handleOpenLote}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} label='Partnumber sem IMEI' />
                  </FormGroup>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='brand'
                    label='Marca'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='serial_in'
                    label='Serian IN'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='imei'
                    label='IMEI'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='model'
                    label='Modelo'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='parnumber'
                    label='Partnumber'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                  <Field
                    size='small'
                    name='complaint'
                    label='Reclamação Cliente'
                    component={Select}
                    variant='outlined'
                    type='text'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Field
                    size='small'
                    name='observation'
                    label='Observação'
                    component={Text}
                    variant='outlined'
                    type='text'
                    fullWidth
                    multiline
                    minRows={5}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Button onClick={() => handleBack()} color='secondary' fullWidth>
                    Voltar
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Button type='submit' fullWidth>
                    Gerar OS
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};
