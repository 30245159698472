import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Formik, Form, Field } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

export const NfEntryReport = () => {
  const selectReport = [
    { label: 'Resumido', value: 0 },
    { label: 'Detalhado', value: 1 },
  ];
  const selectData = [
    { label: 'Data de Recebimento', value: 0 },
    { label: 'Data de Cadastro de NF', value: 1 },
  ];

  const [typeReport, setTypeReport] = useState(0);
  const [typeData, setTypeData] = useState(0);
  const today = new Date();
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleNfEntryReport = async () => {
    toast.info('Aguarde, relatório sendo gerado.');
    setIsLoading(true);
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const formattedInputData = inputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');
      const formattedOutputData = outputData
        .toLocaleDateString('pt-BR')
        .replace('/', '-')
        .replace('/', '-');

      const result = await providersService.getNfEntryReport(
        typeReport,
        typeData,
        formattedInputData,
        formattedOutputData,
      );

      if (result.data.length === 0) {
        toast.error('Não há dados para esse relatório');
        return;
      }

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          NfEntryReport: worksheet,
        },
        SheetNames: ['NfEntryReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Entrada_de_NFs${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório Entrada de NFs Peças</PageTitle>

      <Formik
        initialValues={{
          type_report: typeReport,
          type_date: typeData,
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleNfEntryReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_report'
                  label='Tipo relatório'
                  variant='outlined'
                  options={selectReport}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='type_date'
                  label='Tipo data'
                  variant='outlined'
                  options={selectData}
                  component={Select}
                  disabled={props.values.type_report === 1}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data inicial'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data final'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
              setTypeReport(props.values.type_report);
              setTypeData(props.values.type_date);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
