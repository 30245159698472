import React from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog } from 'components';
import { Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { stockSaleService } from 'services';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  textField: Yup.string().min(15, 'No mínimo de 15 caracteres').required('Campo obrigatório'),
});

export const UnlockDialog = ({ open, handleClose, dataButton, handleLoadTable, getInfo }) => {
  const handleSubmite = async (event) => {
    const { idBloqueio } = dataButton;
    toast.info('Desbloquando OS aguarde ...');
    const new_data = await {
      ...event,
      idBloqueio: idBloqueio,
      login: localStorage.getItem('@gedy:user'),
    };

    try {
      await stockSaleService.unlock_order_service(new_data);
      toast.success('Desbloqueio feito com sucesso.');
      handleLoadTable(getInfo);
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Ocorreu um erro ao processar a solicitação.');
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Desbloquear OS serial: {dataButton.valorBloqueado} idBloqueio: {dataButton.idBloqueio}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            textField: '',
          }}
          onSubmit={handleSubmite}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Field
                    name='textField'
                    component={Text}
                    label='Motivo da desbloqueio: '
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='primary' variant='contained' onClick={handleClose}>
                    cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button fullWidth color='secondary' variant='contained' type='submit'>
                    enviar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
