import { useRouteMatch } from 'react-router';

import { ApproveBase } from 'pages/Private/Shopping/ApproveBase';
import { ViewBase } from 'pages/Private/Shopping/ViewBase';

import { Route } from '../Route';

export const ShoppingRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/view-base`} component={ViewBase} />
      <Route isPrivate path={`${url}/base-approve`} component={ApproveBase} />
    </>
  );
};
