import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { registerService } from '../../../../../services/register';

export const DialogConfirm = ({ handleCloseDialogConfirm, openDialogConfirm, line }) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useContextSelector(AuthContext, (context) => context.user);

  const activeOrDesactive = () => {
    if (line && line.ativo === true) {
      return 'desativar';
    } else {
      return 'ativar';
    }
  };

  const handleUpdateActiveTypeProduct = async () => {
    setIsLoading(true);
    toast.info('Atualizando ativo da modelo');
    try {
      const { data } = await registerService.updateTypeProductActive({
        active: line.ativo,
        id: line.id,
        user: user.id,
      });

      toast.success('Ativo do tipo produto atualizado com sucesso');
      handleCloseDialogConfirm();
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao atualizar ativo do tipe produto');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogConfirm}>
        <Dialog.Title onClose={handleCloseDialogConfirm}>Confirmação</Dialog.Title>
        <Typography align='center' variant='h6' style={{ paddingBottom: '2rem' }}>
          Você deseja realmente {activeOrDesactive()} esse tipo de produto?
        </Typography>
        <Dialog.Content>
          <Formik initialValues={{}} onSubmit={handleUpdateActiveTypeProduct}>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Button
                    fullWidth
                    color='secondary'
                    onClick={handleCloseDialogConfirm}
                    loading={isLoading}
                  >
                    Não
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button type='submit' fullWidth color='primary' loading={isLoading}>
                    Sim
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
