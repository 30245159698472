import React from 'react';
import { useRouteMatch } from 'react-router';

import { LoteManagement } from 'pages/Private/Conference/LoteManagement';

import { Route } from '../Route';

export const ConferenceRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/lote-management`} component={LoteManagement} />
    </>
  );
};
