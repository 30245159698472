export const macroSector = () => {
  return [
    { label: 'TODOS', value: '0' },
    { label: '01', value: '01' },
    { label: 'A1', value: 'A1' },
    { label: 'ARMUL', value: 'ARMUL' },
    { label: 'ARMULCC', value: 'ARMULCC' },
    { label: 'AVNET', value: 'AVNET' },
    { label: 'BLGMEZ', value: 'BLGMEZ' },
    { label: 'CCELL', value: 'CCELL' },
    { label: 'CX', value: 'CX' },
    { label: 'ECM', value: 'ECM' },
    { label: 'ELEC', value: 'ELEC' },
    { label: 'ESTMULTI', value: 'ESTMULTI' },
    { label: 'ESTREPN3', value: 'ESTREPN3' },
    { label: 'FG', value: 'FG' },
    { label: 'FIL', value: 'FIL' },
    { label: 'LG', value: 'LG' },
    { label: 'LG/003/BIN', value: 'LG/003/BIN' },
    { label: 'LOJA', value: 'LOJA' },
    { label: 'MLCENTER', value: 'MLCENTER' },
    { label: 'MLTECH', value: 'MLTECH' },
    { label: 'P03', value: 'P03' },
    { label: 'PC VNDS', value: 'PC VNDS' },
    { label: 'PROV', value: 'PROV' },
    { label: 'RMLB', value: 'RMLB' },
    { label: 'RMUL', value: 'RMUL' },
    { label: 'RSAM', value: 'RSAM' },
    { label: 'SEG', value: 'SEG' },
    { label: 'SL CMP', value: 'SL CMP' },
  ];
};
