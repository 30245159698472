import { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import * as Yup from 'yup';
import { registerService } from '../../../../../services/register';

const validationSchema = Yup.object().shape({
  input_name: Yup.string().required('Nome é obrigatório'),
  input_cpf_cnpj: Yup.string()
    .required('CPF/CNPJ é obrigatório')
    .matches(/^\d{11}|\d{14}$/, 'CPF deve ter 11 dígitos ou CNPJ 14 dígitos'),
  input_email: Yup.string().email('Email inválido').required('Email é obrigatório'),
  input_IE: Yup.string()
    .matches(/^\d{0,14}$/, 'IE deve ter no máximo 14 dígitos')
    .required('Inscrição Estadual é obrigatória'),
  input_celular: Yup.string()
    .matches(/^\d{10,11}$/, 'Celular deve ter 10 ou 11 dígitos')
    .required('Celular é obrigatório'),
  input_telefone: Yup.string()
    .matches(/^\d{10,11}$/, 'Telefone deve ter 10 ou 11 dígitos')
    .required('Telefone é obrigatório'),
  input_CEP: Yup.string()
    .matches(/^\d{8}$/, 'CEP deve ter 8 dígitos')
    .required('CEP é obrigatório'),
  input_rua: Yup.string().required('Rua é obrigatória'),
  input_numero: Yup.string().required('Número é obrigatório'),
  input_bairro: Yup.string().required('Bairro é obrigatório'),
  input_cidade: Yup.string().required('Cidade é obrigatória'),
  input_estado: Yup.string().required('Estado é obrigatório'),
});

export const DialogRegister = ({ openDialogRegister, handleCloseDialogRegister }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const user = useContextSelector(AuthContext, (context) => context.user);
  const handleCheckboxChange = (event) => {
    setSelectedType(event.target.name);
  };

  const handleCepSearch = async (props) => {
    setIsLoading(true);
    try {
      const data = await registerService.getCepInfo(props.values.input_CEP);
      if (data) {
        props.setFieldValue('input_rua', data.logradouro || '');
        props.setFieldValue('input_bairro', data.bairro || '');
        props.setFieldValue('input_cidade', data.localidade || '');
        props.setFieldValue('input_estado', data.uf || '');
      }
    } catch (error) {
      toast.error('Erro ao buscar CEP:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterClient = async (values) => {
    setIsLoading(true);
    toast.info('Registrando cliente...');
    try {
      const { data } = await registerService.createClient({
        input_name: values.input_name,
        input_cpf_cnpj: values.input_cpf_cnpj,
        input_email: values.input_email,
        input_IE: values.input_IE,
        input_celular: values.input_celular,
        input_telefone: values.input_telefone,
        input_CEP: values.input_CEP,
        input_rua: values.input_rua,
        input_numero: values.input_numero,
        input_bairro: values.input_bairro,
        input_cidade: values.input_cidade,
        input_estado: values.input_estado,
        input_complemento: values.input_complemento ?? '',
        isFisicJuridic: selectedType,
        user: user.id,
      });
      toast.success('Cliente registrado com sucesso!');
      setIsLoading(false);
      handleCloseDialogRegister();
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
      console.error(error?.response?.data?.message || error.message);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog maxWidth='lg' open={openDialogRegister}>
        <Dialog.Title onClose={handleCloseDialogRegister}>Cadastro</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_name: '',
              input_cpf_cnpj: '',
              input_email: '',
              input_IE: '',
              input_celular: '',
              input_telefone: '',
              input_CEP: '',
              input_rua: '',
              input_numero: '',
              input_bairro: '',
              input_cidade: '',
              input_estado: '',
              input_complemento: '',
            }}
            onSubmit={handleRegisterClient}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedType === 'Fisica'}
                          onChange={handleCheckboxChange}
                          name='Fisica'
                          color='primary'
                        />
                      }
                      label='Fisica'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedType === 'Juridica'}
                          onChange={handleCheckboxChange}
                          name='Juridica'
                          color='primary'
                        />
                      }
                      label='Juridica'
                    />
                  </Grid>
                  <Grid item xs={12} lg={8}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_name'
                      variant='outlined'
                      fullWidth
                      label='Nome'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cpf_cnpj'
                      variant='outlined'
                      fullWidth
                      label='CPF/CNPJ'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_email'
                      variant='outlined'
                      fullWidth
                      label='Email'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_IE'
                      variant='outlined'
                      fullWidth
                      label='IE'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_celular'
                      variant='outlined'
                      fullWidth
                      label='Celular'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_telefone'
                      variant='outlined'
                      fullWidth
                      label='Telefone'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_CEP'
                      variant='outlined'
                      fullWidth
                      label='CEP'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button fullWidth loading={isLoading} onClick={() => handleCepSearch(props)}>
                      Pesquisar
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_rua'
                      variant='outlined'
                      fullWidth
                      label='Rua'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_numero'
                      variant='outlined'
                      fullWidth
                      label='Número'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_bairro'
                      variant='outlined'
                      fullWidth
                      label='Bairro'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_cidade'
                      variant='outlined'
                      fullWidth
                      label='Cidade'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_estado'
                      variant='outlined'
                      fullWidth
                      label='Estado'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_complemento'
                      variant='outlined'
                      fullWidth
                      label='Complemento'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
