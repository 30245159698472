import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { DialogRegister } from './Dialog/DialogRegister';

const columns = [
  { label: 'Nome Fornecedor' },
  { label: 'CNPJ/CPF' },
  { label: 'Telefone' },
  { label: 'Alterar' },
  { label: 'Ativar/Desativar' },
];

export const RegisterSupplier = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialogRegister, setOpenDialogRegister] = useState(false);

  const handleOpenDialogRegister = () => {
    setOpenDialogRegister(true);
  };

  const handleCloseDialogRegister = () => {
    setOpenDialogRegister(false);
  };
  return (
    <>
      <DialogRegister
        handleCloseDialogRegister={handleCloseDialogRegister}
        openDialogRegister={openDialogRegister}
      />
      <PageTitle>Cadastro Fornecedores</PageTitle>

      <Formik initialValues={{ search: '' }}>
        <Form>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={3} md={3} lg={3}>
              <Button fullWidth loading={isLoading} onClick={handleOpenDialogRegister}>
                Cadastrar
              </Button>
            </Grid>
            <Grid item xs={4} style={{ marginLeft: 'auto' }}>
              <Field
                variant='outlined'
                name='search'
                label='Pesquisar'
                size='small'
                component={Text}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Table name='Table' headers={columns} maxHeight disableNumeration>
              <TableRow>
                <TableCell>nome</TableCell>
                <TableCell>cnpj/cpf</TableCell>
                <TableCell>telefone</TableCell>
                <TableCell>
                  <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Button color='secondary' fullWidth>
                        Desativar
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nome</TableCell>
                <TableCell>cnpj/cpf</TableCell>
                <TableCell>telefone</TableCell>
                <TableCell>
                  <EditIcon style={{ color: '#512da8', cursor: 'pointer' }} />
                </TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Button color='primary' fullWidth>
                        Ativar
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </Table>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
