import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ClearIcon from '@material-ui/icons/Clear';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadXlsx } from 'helpers/downloadXlsx';
import { readCsv } from 'helpers/readcsv';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { shoppingService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogApprove } from './DialogApprove';

const columns = [
  { label: 'id Registro' },
  { label: 'Data de registro' },
  { label: 'Status' },
  { label: 'Usuario Registro' },
  { label: 'Justificativa' },
  {},
  {},
];

export const ApproveBase = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const typeStatus = [
    { label: 'Pendente', value: '1' },
    { label: 'Aprovado', value: '2' },
    { label: 'Reprovado', value: '3' },
    { label: 'Parcial', value: '4' },
  ];

  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openDialogApprove, setOpenDialogApprove] = useState(false);
  const [id_registre, setIdRegistre] = useState('');

  const handleOpenDialogApprove = (id_registre) => {
    setOpenDialogApprove(true);
    setIdRegistre(id_registre);
  };

  const handleCloseDialogApprove = () => {
    setOpenDialogApprove(false);
    getDados();
  };

  const getDados = useCallback(async (props) => {
    const { data } = await shoppingService.getDataTableApprove({
      status: props,
    });
    setDataTable(data);
  }, []);

  useEffect(() => {
    getDados();
    // eslint-disable-next-line
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleExportBase = async (id_registre) => {
    const now = formatDate(new Date());
    try {
      toast.info('Gerando base');
      setIsLoading(true);
      const { data } = await shoppingService.exportBase({ idCadastro: id_registre });

      await downloadXlsx(`Itens_Aprovação`, data);
      toast.success('Sucesso ao gerar base!');
      setIsLoading(false);
    } catch {
      toast.error('Erro ao gerar base!');
    }
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.value = '';
    inputFileRef.current.click();
  };

  const hanldeReadDataCsv = async (e) => {
    setIsLoading(true);
    toast.info('importando dados ...');
    try {
      const data_csv = await readCsv(e);

      if (data_csv.length === 0) {
        toast.error('Arquivo vazio! Por Favor importe um arquivo que contenha dados');
      }

      const { data } = await shoppingService.importItensForApprove({
        data_csv: data_csv,
        name: user.name,
      });
      setIsLoading(false);
      toast.success('Dados importados com sucesso');
      getDados();
    } catch (error) {
      setIsLoading(false);
      console.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message || 'Erro ao ler CSV');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleReset = async (values) => {
    values.status = '';
    getDados();
  };

  const handleStatusChange = async (e) => {
    setPage(0);
    getDados(e);
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={hanldeReadDataCsv}
        accept='text/csv'
        hidden
      />
      <DialogApprove
        openDialogApprove={openDialogApprove}
        handleCloseDialogApprove={handleCloseDialogApprove}
        idRegistro={id_registre}
        user={user}
      />

      <PageTitle>Aprovação Base</PageTitle>

      <Formik
        initialValues={{
          status: '',
        }}
        onReset={handleReset}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='status'
                  label='Status'
                  variant='outlined'
                  options={typeStatus}
                  component={Select}
                  onChange={(e) => handleStatusChange(e)}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Button type='reset' color='secondary'>
                  <ClearIcon />
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  onClick={() => handleImportClick()}
                  fullWidth
                  startIcon={<FileUploadIcon />}
                  loading={isLoading}
                >
                  Importar Base
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table name='Table' headers={columns} disableNumeration>
                  {dataTable &&
                    dataTable
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.id_registre}</TableCell>
                          <TableCell>{formatDate(item.dt_registre)}</TableCell>
                          <TableCell>{item.descricao_status}</TableCell>
                          <TableCell>{item.name_user}</TableCell>
                          <TableCell>{item.justify}</TableCell>
                          <TableCell>
                            {item?.descricao_status === 'Aprovado' && (
                              <Button fullWidth>Aprovado</Button>
                            )}
                            {item?.descricao_status === 'Reprovado' && (
                              <Button fullWidth color='secondary'>
                                Reprovado
                              </Button>
                            )}
                            {item?.descricao_status === 'Parcial' && (
                              <Button fullWidth color='secondary'>
                                Parcial
                              </Button>
                            )}
                            {item?.descricao_status === 'Pendente' && (
                              <Button
                                onClick={() => handleOpenDialogApprove(item.id_registre)}
                                fullWidth
                              >
                                Aprovar / Reprovar
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              isLoading={isLoading}
                              onClick={() => handleExportBase(item.id_registre)}
                            >
                              <DownloadIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                </Table>
                <TablePagination
                  component='div'
                  count={dataTable.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
