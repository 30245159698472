import React from 'react';
import { Bubble } from 'react-chartjs-2';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Welcome from 'assets/images/welcome.svg';
import { AuthContext } from 'hooks/useAuth';
// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const Home = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const classes = useStyles();

  return (
    <>
      <Grid className={classes.containerBoxs} container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper elevation={2}>
            <Typography className={classes.titleWelcome} gutterbottom variant='h5'>
              Bem-vindo(a) ao <strong style={{ color: '#6F50F5' }}>Gaia-System</strong> {user.name}.
            </Typography>
            <div className={classes.contextWelcome}>
              <Typography variant='body2' gutterBottom>
                O sistema de gerenciamento e gestão <strong>Gaia-System</strong>, foi desenvolvido
                para auxiliar os postos de reparos. Através dele é possível acompanhar e automatizar
                o processo de reparo das ordens de serviço!
              </Typography>

              <img className={classes.imgWelcome} src={Welcome} />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.containerBoxs} container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Paper elevation={5} className={classes.cardServicesOpen}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}></p>
                <p></p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={3}>
          <Paper elevation={5} className={classes.cardOscompleted}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}></p>
                <p></p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Paper elevation={5} className={classes.cardApprovedBudget}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}></p>
                <p></p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Paper elevation={5} className={classes.cardTotalSold}>
            <section className={classes.sectionCards}>
              <div className={classes.divCards}>
                <p className={classes.titleCards}> </p>
                <p></p>
              </div>
              <div>
                <img src={Bubble} className={classes.imgBubbleCards} />
              </div>
            </section>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
