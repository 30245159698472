import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, CustomAutocomplete, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { conferenceService } from '../../../../../services/conference';

export const DialogRegisterLot = ({ openModalRegisreLot, setOpenModalRegisreLot }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);
  const [dataOperation, setDataOperation] = useState([]);
  const [dataService, setDataService] = useState([]);
  const [dataCarrying, setDataCarrying] = useState([]);
  const [dataClient, setDataClient] = useState([]);

  const getData = useCallback(async () => {
    try {
      const client = await conferenceService.getClient();
      const operation = await conferenceService.getOperation();
      const service = await conferenceService.getServiceType();
      const carrying = await conferenceService.getCarrying();
      setDataOperation(operation);
      setDataProcess(preocess);
      setDataService(service);
      setDataCarrying(carrying);
      setDataClient(client);
    } catch (error) {
      console.log('erro: ', error.message);
    }
  }, []);

  const handleCreateLote = async (values) => {
    setIsLoading(true);
    try {
      const data = {
        idUser: user.name,
        idCliente: values.input_cliente.value,
        numPedido: values.input_numPedido,
        idOperacao: values.select_operacao,
        idTipoProcesso: values.select_processo,
        idAtendimento: values.select_atendimento,
        idTransportadora: values.select_transportadora.value,
        NumConhecimento: values.input_numero_conhecimento,
        idClienteOri: values.input_fornecedor.value,
      };
      const response = await conferenceService.createLot(data);

      if (response.data.status === 400 || response.data.status === 409) {
        toast.error(response.data.msg);
      } else {
        toast.success('Lote criado com Sucesso!');
        setOpenModalRegisreLot(false);
      }
    } catch (error) {
      toast.error('Erro ao criar lote: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [getData, openModalRegisreLot]);

  const handleCloseModal = () => {
    setOpenModalRegisreLot(false);
  };

  const validationSchema = Yup.object({
    input_cliente: Yup.object().nullable().required('Cliente é obrigatório'),
    input_fornecedor: Yup.object().nullable().required('Fornecedor é obrigatório'),
    input_numPedido: Yup.string().required('Número do Pedido é obrigatório'),
    select_operacao: Yup.string().required('Operação é obrigatória'),
    select_atendimento: Yup.string().required('Tipo de Atendimento é obrigatório'),
    select_transportadora: Yup.object().nullable().required('Transportadora é obrigatória'),
    input_numero_conhecimento: Yup.string().when('select_transportadora', {
      is: (value) => value?.value === 2, // Verifica se o valor selecionado na transportadora é 2
      then: Yup.string().required('N° Conhecimento é obrigatório'), // Campo obrigatório se a transportadora for 2
      otherwise: Yup.string(), // Campo não obrigatório para qualquer outro valor da transportadora
    }),
  });

  return (
    <>
      <Dialog
        open={openModalRegisreLot}
        maxWidth={'lg'}
        PaperProps={{
          style: {
            maxHeight: '80vh',
            minHeight: '40vh',
            width: '100%',
          },
        }}
      >
        <Dialog.Title onClose={handleCloseModal}>Novo Lote</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{
              input_cliente: '',
              input_fornecedor: '',
              input_numPedido: '',
              select_operacao: '',
              select_atendimento: '',
              select_transportadora: '',
              input_numero_conhecimento: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleCreateLote}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid>
                      <CustomAutocomplete
                        name={'input_cliente'}
                        label={'Fornecedor'}
                        value={values.input_cliente}
                        filteredValues={dataClient || []}
                        disabled={isLoading}
                        textFieldProps={{
                          fullWidth: true,
                          variant: 'outlined',
                        }}
                        error={touched.input_cliente && !!errors.input_cliente}
                        helperText={touched.input_cliente && errors.input_cliente}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid>
                      <CustomAutocomplete
                        name={'input_fornecedor'}
                        label={'Fornecedor Origem'}
                        value={values.input_fornecedor}
                        filteredValues={dataClient || []}
                        disabled={isLoading}
                        textFieldProps={{
                          fullWidth: true,
                          variant: 'outlined',
                        }}
                        error={touched.input_fornecedor && !!errors.input_fornecedor}
                        helperText={touched.input_fornecedor && errors.input_fornecedor}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='input_numPedido'
                      label='Número do Pedido '
                      variant='outlined'
                      fullWidth
                      disabled={isLoading}
                      component={Text}
                      error={touched.input_numPedido && !!errors.input_numPedido}
                      helperText={touched.input_numPedido && errors.input_numPedido}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='select_operacao'
                      label='Operação'
                      variant='outlined'
                      fullWidth
                      options={dataOperation}
                      disabled={isLoading}
                      component={Select}
                      error={touched.select_operacao && !!errors.select_operacao}
                      helperText={touched.select_operacao && errors.select_operacao}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Field
                      size='small'
                      name='select_atendimento'
                      label='Tipo de Atendimento'
                      variant='outlined'
                      fullWidth
                      options={dataService}
                      disabled={isLoading}
                      component={Select}
                      error={touched.select_atendimento && !!errors.select_atendimento}
                      helperText={touched.select_atendimento && errors.select_atendimento}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CustomAutocomplete
                      name={'select_transportadora'}
                      label={'Transportadora'}
                      value={values.select_transportadora}
                      filteredValues={dataCarrying || []}
                      disabled={isLoading}
                      textFieldProps={{
                        fullWidth: true,
                        variant: 'outlined',
                      }}
                      error={touched.select_transportadora && !!errors.select_transportadora}
                      helperText={touched.select_transportadora && errors.select_transportadora}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      size='small'
                      name='input_numero_conhecimento'
                      label='N° Conhecimento'
                      variant='outlined'
                      disabled={isLoading}
                      fullWidth
                      component={Text}
                      error={
                        touched.input_numero_conhecimento && !!errors.input_numero_conhecimento
                      }
                      helperText={
                        touched.input_numero_conhecimento && errors.input_numero_conhecimento
                      }
                    />
                  </Grid>
                  <Grid container style={{ paddingTop: '2rem' }}>
                    <Grid item xs={12} lg={8}></Grid>
                    <Grid item xs={4} lg={4}>
                      <Button fullWidth type='submit' disabled={isLoading} loading={isLoading}>
                        Criar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
