import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { registerService } from '../../../../../services/register';

const validationSchema = Yup.object().shape({
  input_new_mark: Yup.string().required('Marca é obrigatória '),
  // observation: Yup.string().required('Observação é obrigatória'),
});

export const DialogEdit = ({ openDialogEdit, handleCloseDialogEdit, line }) => {
  if (!openDialogEdit) return null;

  const user = useContextSelector(AuthContext, (context) => context.user);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateBrand = async (values) => {
    toast.info('Atualizando marca');
    setIsLoading(true);
    try {
      const { data } = await registerService.updateBrand({
        description: values.input_new_mark,
        id: line.id,
      });
      toast.success('Marca atualizada com sucesso');
      handleCloseDialogEdit();
      setIsLoading(false);
    } catch (error) {
      console.error(error.message);
      toast.error('Erro ao atualizar marca');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialogEdit}>
        <Dialog.Title onClose={handleCloseDialogEdit}>Atualizar</Dialog.Title>
        <Dialog.Content>
          <Formik
            initialValues={{ input_new_mark: '', observation: '' }}
            onSubmit={handleUpdateBrand}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      loading={isLoading}
                      name='input_new_mark'
                      variant='outlined'
                      fullWidth
                      label='Marca'
                      component={Text}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      name='observation'
                      label='Observação'
                      variant='outlined'
                      multiline
                      minRows={5}
                      component={Text}
                    />
                  </Grid> */}
                  <Grid item xs={3} md={4} lg={4}>
                    <Button type='submit' fullWidth loading={isLoading}>
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
