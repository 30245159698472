import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Select } from 'components';
import { Field, Form, Formik } from 'formik';

import { receiptService } from '../../../../services/receipt';

export const DialogChangeTypeSend = ({ handleCloseDialog, openDialog, idCadastro }) => {
  const typeSend = [
    { label: 'Pagar', value: 'Pagar' },
    { label: 'Não Pagar', value: 'Não Pagar' },
  ];
  const [isLoading, setIsLoading] = useState(false);

  const HandleUpdtadeSend = async (values) => {
    setIsLoading(true);
    try {
      const { data } = await receiptService.UpdateSend({
        idCadastro: idCadastro,
        type_send: values.type_send,
      });
      if (data.status === 200) {
        toast.success('Tipo de envio alterado com sucesso!');
        handleCloseDialog();
        setIsLoading(false);
      } else {
        toast.error('Erro ao alterar tipo de envio!');
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro ao atualizar o tipo de envio');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog open={openDialog}>
        <Dialog.Title onClose={handleCloseDialog}>Atualizar tipo de envio</Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ type_send: '' }} onSubmit={HandleUpdtadeSend}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='type_send'
                      label='Tipo de Envio'
                      variant='outlined'
                      options={typeSend}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Button isLoading={isLoading} fullWidth type='submit'>
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
