import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';

export const DialogJustify = ({
  handleCloseDialog,
  openDialog,
  text,
  setText,
  idRegistro,
  user,
}) => {
  const handleJustifyReprove = async (values) => {
    setText(values.message);
    handleCloseDialog();
  };
  return (
    <>
      <Dialog maxWidth={'sm'} open={openDialog}>
        <Dialog.Title onClose={handleCloseDialog}>
          Justificar Reprova - Parcial ou Completa
        </Dialog.Title>
        <Dialog.Content>
          <Formik initialValues={{ message: '' }} onSubmit={handleJustifyReprove}>
            {(props) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      name='message'
                      label='Justificativa'
                      component={Text}
                      type='text'
                      variant='outlined'
                      value={props.values.message}
                      multiline
                      minRows={5}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Button fullWidth type='submit' disabled={props.values.message === ''}>
                      Enviar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
