import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Checkbox from '@mui/material/Checkbox';
import { Button, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';

import { receiptService } from '../../../../services/receipt';
import { DialogRegisterNF } from './dialogRegisterNF';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const RegisterNfDoca = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [openDialog, setOpenDialog] = useState(false);

  const [numNfs, setNumNfs] = useState(0);
  const [dataTable, setDataTable] = useState(0);
  console.log(dataTable);

  const [selectedNfs, setSelectedNfs] = useState([]);
  const [sendNfs, setSendNfs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getDados = useCallback(async () => {
    const { data } = await receiptService.getDataTable();
    setDataTable(data);
    setSelectAll(false);
  }, []);

  useEffect(() => {
    getDados();
  }, [getDados]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleCheckboxChange = (index) => {
    setSelectedNfs((prevState) => {
      const newState = { ...prevState, [index]: !prevState[index] };
      setSendNfs(dataTable.filter((item, idx) => newState[idx]));

      const allSelected =
        dataTable.length > 0 &&
        Object.values(newState).length === dataTable.length &&
        Object.values(newState).every((isChecked) => isChecked);
      setSelectAll(allSelected);

      return newState;
    });
  };

  const handleCheckedAll = () => {
    if (selectAll) {
      setSelectedNfs([]);
      setSendNfs([]);
    } else {
      const allSelected = dataTable.reduce((acc, _, index) => {
        acc[index] = true;
        return acc;
      }, {});
      setSelectedNfs(allSelected);
      setSendNfs(dataTable);
    }
    setSelectAll(!selectAll);
  };

  const handleSendNf = async () => {
    try {
      setIsLoading(true);
      const { data } = await receiptService.sendNf({ nfs: sendNfs, user: user.name });
      if (data === 200) {
        toast.success('Nfs enviadas com sucesso');
        getDados();
        setSelectedNfs({});
        setIsLoading(false);
        location.reload();
      } else {
        toast.error('Falha ao enviar Nfs');
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro ao enviar NFs');
      setIsLoading(false);
    }
  };

  const handleRemoveNf = async () => {
    try {
      setIsLoading(true);
      const { data } = await receiptService.removeNf({ nfs: sendNfs, user: user.name });
      if (data === 200) {
        toast.success('Nfs excluidas com sucesso');
        getDados();
        setSelectedNfs({});
        setIsLoading(false);
        location.reload();
      } else {
        toast.error('Falha ao excluir Nfs');
        setIsLoading(false);
      }
    } catch {
      toast.error('Erro ao excluir NFs');
      setIsLoading(false);
    }
  };

  const columns = [
    { label: <Checkbox onChange={handleCheckedAll} checked={selectAll} /> },
    { label: 'Recebimento NF' },
    { label: 'Operacao' },
    { label: 'CNPJ' },
    { label: 'Fornecedor' },
    { label: 'Chave' },
    { label: 'Num NF' },
    { label: 'User Cadastro' },
  ];

  return (
    <>
      <DialogRegisterNF
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        numNfs={numNfs}
      />

      <PageTitle>Cadastro de NF Doca</PageTitle>
      <Formik
        initialValues={{
          qtd_nf: 0,
        }}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  size='small'
                  name='qtd_nf'
                  label='Qtd NF'
                  type='number'
                  variant='outlined'
                  value={numNfs}
                  component={Text}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if (!isNaN(value) && value >= 0) {
                      setNumNfs(value);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  isLoading={isLoading}
                  fullWidth
                  onClick={handleOpenDialog}
                  disabled={props.values.qtd_nf <= 0}
                >
                  Cadastrar NF
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  isLoading={isLoading}
                  onClick={handleSendNf}
                  fullWidth
                  disabled={sendNfs.length === 0}
                >
                  Enviar NF
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  isLoading={isLoading}
                  onClick={handleRemoveNf}
                  color='secondary'
                  fullWidth
                  disabled={sendNfs.length === 0}
                >
                  Excluir NF
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table name='Table' headers={columns} maxHeight disableNumeration>
                  {dataTable &&
                    dataTable.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            {...label}
                            checked={selectedNfs[index] || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </TableCell>
                        <TableCell>{formatDate(item.Recebimento_NF)}</TableCell>
                        <TableCell>{item.Operacao}</TableCell>
                        <TableCell>{item.CNPJ}</TableCell>
                        <TableCell>{item.Fornecedor}</TableCell>
                        <TableCell>{item.Chave}</TableCell>
                        <TableCell>{item.NumNF}</TableCell>
                        <TableCell>{item.User_Cadastro}</TableCell>
                      </TableRow>
                    ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
