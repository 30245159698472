import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
import XLSX from 'xlsx';

export const RegisterProductReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExportRegisterProductReport = async () => {
    setIsLoading(true);
    toast.info('Aguarde, relatório sendo gerado.');
    const now = new Date().toLocaleDateString('pt-BR');

    try {
      const result = await providersService.getRegisterProductReport();

      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          RegisterProductReport: worksheet,
        },
        SheetNames: ['RegisterProductReport'],
      });

      XLSX.writeFile(workbook, `Relatorio_de_Cadastro_de_Produtos${now}.xlsx`);
      toast.success('Relatório gerado com sucesso');
    } catch (error) {
      console.log(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de Cadastro de Produtos</PageTitle>
      <Formik>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Button
                  fullWidth
                  disabled={isLoading}
                  onClick={handleExportRegisterProductReport}
                  loading={isLoading}
                >
                  Exportar relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
