import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paperWelcome: {
    padding: '10px',
    height: '200px',
    background: '#00',
  },
  gaia: {
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: '64px',
    '& > span': {
      color: theme.palette.primary.light,
    },
  },
  containerBoxs: {
    marginBottom: '10px',
  },
  sectionOne: {
    display: 'flex',
  },
  graphicLine: {
    padding: '10px',
    height: '200px',
  },
  cardTotalSold: {
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to right, #865fc0, #7d5abe, #7355bd, #6850bb, #5d4bba)',
    borderRadius: '25px',
  },
  sectionCards: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'relative',
  },
  divCards: {
    color: '#ffff',
    fontSize: '16px',
    padding: '10px',
  },
  titleCards: {
    fontWeight: 'bold',
  },

  imgBubbleCards: {
    position: 'absolute',
    height: '160%',
    right: '-2rem',
  },
  cardApprovedBudget: {
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to right, #e84989, #de4b91, #d34e99, #c7519f, #ba54a4)',
    borderRadius: '25px',
  },
  cardOscompleted: {
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to right, #48c0ee, #49b5ec, #50aae7, #5a9ee2, #6592da)',
    borderRadius: '25px',
  },
  cardServicesOpen: {
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to right, #fdb42e, #fea837, #fe9d40, #fc9248, #f88850)',
    borderRadius: '25px',
  },
  paperRecentTransaction: {
    padding: '10px',
    height: '200px',
  },
  paperTrafficUsers: {
    padding: '10px',
    height: '200px',
  },
  sectionTrafficUsers: {
    display: 'flex',
  },
  divGraphicTrafficUsers: {
    width: '70%',
  },
  userActive: {
    color: '#875EC0',
  },
  userOff: {
    color: '#EB4886',
  },
  userAbsent: {
    color: '#EB4886',
  },
  paperContainer: {
    padding: '10px',
    height: '200px',
  },

  contextWelcome: {
    display: 'flex',
    [theme.breakpoints.down(780)]: {
      flexDirection: 'column',
    },
    padding: 10,
  },
  imgWelcome: {
    height: 130,
  },
  titleWelcome: {
    padding: '10px',
  },
}));
