import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import cep from 'cep-promise';
import { Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { validationSchema } from './validationSchema';

export function ClientAddressForm() {
  const [mapCenter, setMapCenter] = useState({ lat: -23.55052, lng: -46.633308 }); // São Paulo como exemplo

  const containerStyle = {
    width: '100%',
    height: '400px',
  };

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  async function onBlurCep({ setSubmitting, setFieldValue, values }) {
    try {
      setSubmitting(true);
      const { state, city, neighborhood, street } = await cep(values.cep);

      setFieldValue('state', state);
      setFieldValue('city', city);
      setFieldValue('district', neighborhood);
      setFieldValue('street', street);

      // Atualize o centro do mapa com base nas coordenadas do CEP (exemplo)
      setMapCenter({ lat: -23.55052, lng: -46.633308 }); // Substitua pelas coordenadas reais
    } catch (err) {
      console.log(err);
      toast.error('Erro ao consultar o CEP');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageTitle>Endereço do cliente</PageTitle>

      <Formik
        initialValues={{
          cep: '',
          city: '',
          state: '',
          district: '',
          street: '',
          complement: '',
          street_number: '',
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <Field
                  label='CEP'
                  name='cep'
                  size='small'
                  variant='outlined'
                  mask='zipcode'
                  fullWidth
                  component={Text}
                  onBlur={() => onBlurCep(props)}
                  required
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  size='small'
                  variant='outlined'
                  fullWidth
                  label='Cidade'
                  name='city'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  size='small'
                  variant='outlined'
                  fullWidth
                  label='Estado'
                  name='state'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  size='small'
                  variant='outlined'
                  fullWidth
                  label='Bairro'
                  name='district'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Field
                  size='small'
                  variant='outlined'
                  fullWidth
                  label='Rua'
                  name='street'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  label='Complemento'
                  name='complement'
                  size='small'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  label='Número'
                  name='street_number'
                  size='small'
                  component={Text}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                  <GoogleMap mapContainerStyle={containerStyle} center={mapCenter} zoom={15}>
                    <Marker position={mapCenter} />
                  </GoogleMap>
                </LoadScript> */}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
