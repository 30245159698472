import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { providersService } from 'services';

export const DialogDeleteFilter = ({ openDialogDelete, handleCloseDialogDelete, item }) => {
  if (!openDialogDelete) {
    return null;
  }
  const [isLoading, setIsLoading] = useState();
  const handleDeleteCustomFilter = async () => {
    setIsLoading(true);
    toast.info('Deletando Filtro...');
    try {
      const { data } = await providersService.deleteCustomFilter({ item });

      if (data.status === 200) {
        toast.success(data.msg);
        setIsLoading(false);
        handleCloseDialogDelete();
      } else {
        toast.error(data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error('Erro ao deletar filtro');
      setIsLoading(false);
    }
  };
  return (
    <>
      <Dialog maxWidth={'sm'} open={openDialogDelete}>
        <Dialog.Title onClose={handleCloseDialogDelete}>Remover Filtro</Dialog.Title>
        <Dialog.Content>
          <Typography variant='h1' style={{ fontSize: '25px', fontWeight: 'bold' }}>
            Deseja realmente excluir o filtro {item.apelido}?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={3}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Button
                color='secondary'
                fullWidth
                onClick={handleCloseDialogDelete}
                loading={isLoading}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Button fullWidth onClick={() => handleDeleteCustomFilter()} loading={isLoading}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
