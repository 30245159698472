import { api } from 'services/api';

export class ProvidersService {
  //REPORTS
  async getAgingReport(data) {
    return api.post('/xpcell-multimarcas/reports/aging', data);
  }
  async getAgingReportFinishAndScrap(input_date, output_date) {
    return api.get(
      `/xpcell-multimarcas/reports/aging/finalized-scrap/${input_date}/${output_date}`,
    );
  }
  async getReceiptReport(input_date, output_date) {
    return api.get(`/xpcell-multimarcas/reports/receipt/${input_date}/${output_date}`);
  }

  async getStockPositionReport() {
    return api.get(`/xpcell-multimarcas/reports/stock-position`);
  }

  async getDataStage() {
    return api.get(`/xpcell-multimarcas/reports/get-data-stage`);
  }

  async getProductionSectorReport(data) {
    return api.post(`/xpcell-multimarcas/reports/production-sector`, data);
  }

  async getOpenServiceOrderReport(data) {
    return api.post(`/xpcell-multimarcas/reports/open-service-orders`, data);
  }
  async getSerializedStock(data_type, setor_type) {
    return api.get(`/xpcell-multimarcas/reports/serialized-stock/${data_type}/${setor_type}`);
  }
  async getRequestReport(input_data, output_data) {
    return api.get(`/xpcell-multimarcas/reports/request-report/${input_data}/${output_data}`);
  }
  async getRegisterProductReport() {
    return api.get(`/xpcell-multimarcas/reports/register-product-report`);
  }
  async getModelXProductReport() {
    return api.get(`/xpcell-multimarcas/reports/models-product-report`);
  }
  async getDefectXComponentReport(type_marca, type_prod) {
    return api.get(
      `/xpcell-multimarcas/reports/defect-component-report/${type_marca}/${type_prod}`,
    );
  }
  async getProductsXAtribuctsReports(type_prod) {
    return api.get(`/xpcell-multimarcas/reports/produtos-atributos-report/${type_prod}`);
  }
  async getProductionRegisterReport(type_report, type_screen, input_data, output_data) {
    return api.post(`/xpcell-multimarcas/reports/production-registers-report`, {
      type_report,
      type_screen,
      input_data,
      output_data,
    });
  }
  async getComponentsRegisterReport() {
    return api.get(`/xpcell-multimarcas/reports/component-register-report`);
  }
  async getProductsNoSkuReport(type_report, type_marca, type_stage) {
    return api.get(
      `/xpcell-multimarcas/reports/product-no-sku-report/${type_report}/${type_marca}/${type_stage}`,
    );
  }
  async getPNXModelReport(type_marca, type_prod, type_model) {
    return api.get(`/xpcell-multimarcas/reports/pn-model/${type_marca}/${type_prod}/${type_model}`);
  }
  async getWarrantyValidationReport(type_report, input_data, output_data) {
    return api.get(
      `/xpcell-multimarcas/reports/warranty-alidation/${type_report}/${input_data}/${output_data}`,
    );
  }
  async getDetailedPartsConsumptionReport(type_report, input_data, output_data) {
    return api.get(
      `/xpcell-multimarcas/reports/detailed-Parts-Consumption/${type_report}/${input_data}/${output_data}`,
    );
  }
  async getNfEntryReport(type_report, type_data, input_data, output_data) {
    return api.get(
      `/xpcell-multimarcas/reports/nf-entry-report/${type_report}/${type_data}/${input_data}/${output_data}`,
    );
  }
  async getStockPositionEcommerceReport(type_sector, type_local, type_subLocal) {
    return api.get(
      `/xpcell-multimarcas/reports/stock-position-ecommerce/${type_sector}/${type_local}/${type_subLocal}`,
    );
  }
  async getLotNfReceiptReport(type_operation, input_data, output_data) {
    return api.get(
      `/xpcell-multimarcas/reports/lote-nf-receipt/${type_operation}/${input_data}/${output_data}`,
    );
  }
  async getOsCmpReport(
    type_report,
    type_opr,
    type_stage,
    type_period,
    type_data,
    input_data,
    output_data,
  ) {
    return api.get(
      `/xpcell-multimarcas/reports/os-cmp-report/${type_report}/${type_opr}/${type_stage}/${type_period}/${type_data}/${input_data}/${output_data}`,
    );
  }
  async getProductsSerializedReport(
    type_report,
    type_setorMacro,
    type_setor,
    type_local,
    type_subLocal,
    input_data,
    output_data,
  ) {
    return api.get(
      `/xpcell-multimarcas/reports/products-serialized/${type_report}/${type_setorMacro}/${type_setor}
      /${type_local}/${type_subLocal}/${input_data}/${output_data}`,
    );
  }
  async getTotalAverageCalculationReport(type_report, input_data, output_data) {
    return api.get(
      `/xpcell-multimarcas/reports/total-average-calculation/${type_report}/${input_data}/${output_data}`,
    );
  }
  async getGetDataWarrantys(csv_data) {
    return api.post(`/xpcell-multimarcas/reports/warranty-report`, csv_data);
  }

  async getHandleSacManagementReport(
    type_report,
    select_option,
    startDate,
    endDate,
    log_input,
    status_option,
    serial_saida,
    nf_remessa,
    serial_entrada,
  ) {
    return api.get(
      `/xpcell-multimarcas/reports/sac-management/${type_report}/${select_option}/${startDate}/${endDate}
      /${log_input}/${status_option}/${serial_saida}/${nf_remessa}/${serial_entrada}`,
    );
  }

  async openLoteReport(data) {
    return api.post('/xpcell-multimarcas/reports/lote-open', data);
  }

  async lotManagementReport(data) {
    return api.post('/xpcell-multimarcas/reports/lot-management-report', data);
  }

  async futureBounceReport(data) {
    return api.post('/xpcell-multimarcas/reports/future-bounce-report', data);
  }

  async retroactiveBounceReport(data) {
    return api.post('/xpcell-multimarcas/reports/retroactive-bounce-report', data);
  }

  async findPartnumbers() {
    return api.get('/xpcell-multimarcas/helpers/findPartnumbers');
  }

  async partsXModelsReport(data) {
    return api.post('/xpcell-multimarcas/reports/parts-x-models-report', data);
  }

  async completedOrdersReport(data) {
    return api.post('/xpcell-multimarcas/reports/completed-orders-report', data);
  }

  async branchBalanceReport(data) {
    return api.post('/xpcell-multimarcas/reports/branch-balance-report', data);
  }

  async nonComplianceReport(data) {
    return api.post('/xpcell-multimarcas/reports/non-compliance-report', data);
  }

  async productionByTechnicianReport(data) {
    return api.post('/xpcell-multimarcas/reports/production-by-technician', data);
  }

  async expressReport(data) {
    return api.post('/xpcell-multimarcas/reports/express-report', data);
  }

  async findProcess() {
    return api.get('/xpcell-multimarcas/reports/findProcess');
  }

  async scrapReport(data) {
    return api.post('/xpcell-multimarcas/reports/scrap-report', data);
  }

  async controlSacMangementReport(data) {
    return api.post('/xpcell-multimarcas/reports/control-sac-management-report', data);
  }

  async lotpendingConfirmationReport() {
    return api.get('/xpcell-multimarcas/reports/lot-pending-confirmation');
  }

  async closedOsReport(data) {
    return api.post('/xpcell-multimarcas/reports/closed-os-report', data);
  }

  async findTypeProduct() {
    return api.get('/xpcell-multimarcas/reports/findTypeProduct');
  }

  async registerNewComplaint(data) {
    return api.post('/xpcell-multimarcas/reports/register-new-complaint', data);
  }

  async findDataTableComplaints() {
    return api.get('/xpcell-multimarcas/reports/findDataTableComplaints');
  }

  async deleteComplaint(data) {
    return api.post('/xpcell-multimarcas/reports/delete-complaint', data);
  }

  async updateComplaint(data) {
    return api.post('/xpcell-multimarcas/reports/update-complaint', data);
  }

  async vincProdComplaints(data) {
    return api.post('/xpcell-multimarcas/reports/vinc-prod-complaint', data);
  }

  async billingByOsReport(data) {
    return api.post('/xpcell-multimarcas/reports/billing-by-os-report', data);
  }

  async photoLoteReport(data) {
    return api.post('/xpcell-multimarcas/reports/photo-lote-report', data);
  }

  async findSelectedStagesAging(data) {
    return api.post('/xpcell-multimarcas/reports/findSelectedStagesAging', data);
  }
  async findTypeService() {
    return api.get('/xpcell-multimarcas/reports/find-type-service');
  }

  async findCategory() {
    return api.get('/xpcell-multimarcas/reports/find-category');
  }

  async findCustomFilters() {
    return api.get('/xpcell-multimarcas/reports/findCustomFilters');
  }

  async findStagesAging() {
    return api.get('/xpcell-multimarcas/reports/findStagesAging');
  }

  async findProductsByMagementFilters() {
    return api.get('/xpcell-multimarcas/reports/findProductsByMagementFilters');
  }
  async findCategotryProdByMagementFilters() {
    return api.get('/xpcell-multimarcas/reports/findCategotryProd');
  }
  async findTypeServiceByMagementFilters() {
    return api.get('/xpcell-multimarcas/reports/findTypeService');
  }
  async registerNewCustomFilter(data) {
    return api.post('/xpcell-multimarcas/reports/register-new-custom-filter', data);
  }

  async findTableCustomFilters() {
    return api.get('/xpcell-multimarcas/reports/findTableCustomFilters');
  }

  async deleteCustomFilter(data) {
    return api.post('/xpcell-multimarcas/reports/deleteCustomFilter', data);
  }

  async findInfoCustomFiltersById(data) {
    return api.post('/xpcell-multimarcas/reports/findInfoCustomFiltersById', data);
  }

  async updateCustomFilter(data) {
    return api.post('/xpcell-multimarcas/reports/updateCustomFilter', data);
  }

  async findOperationsEmail() {
    return api.get('/xpcell-multimarcas/reports/findOperationsEmail');
  }

  async searchEmailsControls() {
    return api.get('/xpcell-multimarcas/reports/searchEmailsControls');
  }

  async findTableEmails(data) {
    return api.post('/xpcell-multimarcas/reports/findTableEmails', data);
  }
  async updateEmailsSac(data) {
    return api.post('/xpcell-multimarcas/reports/updateEmailsSac', data);
  }
  async saleByPeriodReport(data) {
    return api.post('/xpcell-multimarcas/reports/saleByPeriodReport', data);
  }

  //HELPERS
  async getBrand() {
    return api.get(`/xpcell-multimarcas/helpers/getBrands`);
  }
  async getProduct() {
    return api.get(`/xpcell-multimarcas/helpers/getProduct`);
  }
  async getScreen() {
    return api.get(`xpcell-multimarcas/helpers/getScreen`);
  }
  async getStageAging() {
    return api.get(`xpcell-multimarcas/helpers/getStageAging`);
  }
  async getModel() {
    return api.get(`xpcell-multimarcas/helpers/getModel`);
  }
  async getOperacao() {
    return api.get(`xpcell-multimarcas/helpers/getOperacao`);
  }
  async getStageCmp() {
    return api.get(`xpcell-multimarcas/helpers/getStageCmp`);
  }
  async getSectorEcm() {
    return api.get(`xpcell-multimarcas/helpers/getSectorEcm`);
  }
  async getLocalEcm() {
    return api.get(`xpcell-multimarcas/helpers/getLocalEcm`);
  }
  async getSubLocal() {
    return api.get(`xpcell-multimarcas/helpers/getSubLocal`);
  }
  async getSacManagementStatus() {
    return api.get(`xpcell-multimarcas/helpers/getSacManagementStatus`);
  }
}
export const providersService = new ProvidersService();
